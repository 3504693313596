import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { debounce } from "lodash";
import { Button, Checkbox, Notification, Tag } from "pepsico-ds";
import { useCallback, useContext, useRef, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { getLeaderboardsList } from "../../../graphql/queries/getLeaderboardsList";
import CreateLeaderboardsModal from "../../../pages/leaderboards/CreateLeaderboardsModal";
import { getDateWithFormat } from "../../../utils/commons";
import FilterLeaderboards from "../../leaderboards/filterLeaderboards/FilterLeaderboards";
import "./LeaderboardSelect.scss";

const LeaderboardSelect = (props) => {
  const { handleChange, path } = props;
  const { programTimezone } = useContext(GlobalConfigContext);
  const [showNotification, setShowNotification] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedLeaderboards, setSelectedLeaderboards] = useState([]);
  const [showCreateLeaderboardModal, setShowCreateLeaderboardModal] =
    useState(false);
  const filters = useRef({ title: "", startsAt: "", endsAt: "" });
  const { data: listData, refetch } = useQuery(getLeaderboardsList);
  const leaderboardList = listData?.listLeaderboards?.items || [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeFilters = useCallback(
    debounce(() => {
      const payload = {
        title: "",
        startsAt: "",
        endsAt: "",
      };

      if (filters.current.title) {
        payload.title = filters.current.title;
      }
      if (filters.current.startsAt) {
        payload.startsAt = filters.current.startsAt;
      }
      if (filters.current.endsAt) {
        payload.endsAt = filters.current.endsAt;
      }
      refetch({ ...payload });
    }, 500),
    []
  );

  const onSelected = (leaderboard) => {
    const isExists = selectedIds.includes(leaderboard.id);
    let finalIds = [];
    let finalLeaderboards = [];
    if (isExists) {
      finalIds = selectedIds.filter((item) => item !== leaderboard.id);
      finalLeaderboards = selectedLeaderboards.filter(
        (item) => item.id !== leaderboard.id
      );
    } else {
      finalIds = [...selectedIds, leaderboard.id];
      finalLeaderboards = [...selectedLeaderboards, leaderboard];
    }
    setSelectedIds(finalIds);
    setSelectedLeaderboards(finalLeaderboards);
    handleChange(path, {
      leaderboardIds: finalIds,
      leaderboards: finalLeaderboards,
    });
  };

  const onNewLeaderboardCreated = () => {
    refetch();
    setShowNotification(true);
  };

  return (
    <div className="add-leaderboard-select-wrap">
      <div className="display-flex align-center mb-3">
        <h4 className="font-semibold mr-3">Add Leaderboard</h4>
        <h4 className="font-light">Campaign Duration: 12/22/2024-01/11/2025</h4>
      </div>
      {showNotification && (
        <Notification
          type="success"
          title="New Leaderboard Created!"
          text=""
          // primaryActionLabel={"Close"}
          // primaryActionOnClick={handleClose}
        />
      )}
      <div className="my-3 select-count-box">
        <p className="font-xsmall-bold">
          {selectedLeaderboards.length} Leaderboard(s) Selected
        </p>
        {selectedLeaderboards.length > 0 && (
          <div className="leaderboards-tags mt-3">
            {selectedLeaderboards.map((leaderboard) => (
              <Tag
                key={leaderboard.id}
                text={leaderboard.title}
                isRemovable={true}
                isCopyable={false}
                color="Auxiliary-600"
                onRemove={() => onSelected(leaderboard)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="mt-3 display-flex">
        <FilterLeaderboards
          filterRef={filters}
          onChangeFilters={onChangeFilters}
          showFields={{
            search: true,
            dates: true,
          }}
        />
        <Button
          text="Create New Leaderboard"
          iconLeading="add"
          variant="tertiary"
          size="small"
          onClick={() => {
            setShowCreateLeaderboardModal(true);
          }}
        />
      </div>
      <div>
        <table className="leaderboards-list-table">
          <thead>
            <tr>
              <th></th>
              <th>TITLE</th>
              <th>DATE RANGE</th>
              <th>APPROVAL METHOD</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardList.map((leaderboard) => (
              <tr key={leaderboard.id}>
                <td>
                  <Checkbox
                    label=""
                    size="small"
                    text=""
                    checked={selectedIds.includes(leaderboard.id)}
                    onUpdate={() => onSelected(leaderboard)}
                  />
                </td>
                <td>{leaderboard.title}</td>
                <td>
                  {getDateWithFormat(
                    leaderboard.startsAt,
                    programTimezone,
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {getDateWithFormat(
                    leaderboard.endsAt,
                    programTimezone,
                    "MM/DD/YYYY"
                  )}
                </td>
                <td>{leaderboard.awardApproval}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CreateLeaderboardsModal
        showModal={showCreateLeaderboardModal}
        toggleCreateLeaderboardModal={() => setShowCreateLeaderboardModal(false)}
        refetch={onNewLeaderboardCreated}
        selectedLeaderboard={null}
        setSelectedLeaderboard={() => null}
        createShowToast={false}
      />
    </div>
  );
};

export const LeaderboardSelectControl = withJsonFormsControlProps(LeaderboardSelect);
