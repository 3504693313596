import { Sidepanel } from "pepsico-ds";
import PerformanceDetailsContainer from "./PerformanceDetailsContainer";
import "./performanceDetails.scss";

const PerformanceDetailsDrawer = ({
  showDrawer,
  engagementItem,
  onCloseDrawer = () => {},
  onDetailViewClick = () => {},
  onApprove = () => {},
  onReject = () => {},
}) => {
  return (
    <Sidepanel
      showSidePanel={showDrawer}
      onCloseSidePanel={() => onCloseDrawer(false)}
      title="Performance details"
      type="lateral"
      anchor="right"
      verticalSidepanelHeight="100%"
      isDark={false}
      data-testid="performance-details-drawer"
      primaryButtonProps={{
        onClick: onApprove,
        size: "medium",
        text: "Approve",
        variant: "primary",
      }}
      secondaryButtonProps={{
        onClick: onReject,
        size: "medium",
        text: "Reject",
        variant: "tertiary",
      }}
      showLink={false}
      showSecondaryButton={true}
      showFooter={engagementItem?.status?.toLowerCase() == "pending" ? true : false}
      showSlot={true}
      showTertiaryButton={false}
    >
      <div className="performance-details-drawer">
        <PerformanceDetailsContainer
          title="Details"
          showFooter={false}
          engagementItem={engagementItem}
          showDetailViewOption={true}
          onDetailViewClick={() => onDetailViewClick()}
        />
        {engagementItem?.status?.toLowerCase() == "pending" && (
          <div className="performance-details-drawer-footer">
            <div className="performance-details-drawer-footer-title">
              Performance is pending
            </div>
            <div className="performance-details-drawer-footer-description">
              Do you want to approve it?
            </div>
          </div>
        )}
      </div>
    </Sidepanel>
  );
};

export default PerformanceDetailsDrawer;
