import { useQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, LoadingSpinner, ToastContainer, Toggle } from "pepsico-ds";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { getCampaignsTaggedToCodeGroup } from "../../graphql/queries/getCampaignListing";
import { applyEditableFieldRules } from "../../utils/commons";
import {
  CODE_GROUP_ADMINISTRATION_STATUS,
  CODE_GROUP_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { jsonSchema, uiSchema } from "./codeGroupUtils";
import "./createCodeGroupModal.scss";
import { useCodeGroupModal } from "./useCodeGroupModal";

/**
 * CodeGroupSetupModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @params {function} props.refetchListing - Function to refetch the listing.
 * @params {boolean} props.loadCodeGroupLoader - Flag indicating whether the code group data is loading.
 * @params {Object} props.codeGroupData - The code group data.
 * @params {function} props.setCodeGroupData - Function to set the code group data
 * @returns {JSX.Element} The CodeGroupSetupModal component.
 */
export default function CodeGroupSetupModal({
  showModal,
  setShowModal,
  refetchListing,
  loadCodeGroupLoader,
  codeGroupData,
  setParentToastData,
  setCodeGroupData,
}) {
  const [alreadyCreatedData, setAlreadyCreatedData] = useState({});
  const { performDraftCodeGroup, performPublishCodeGroup, validateCodeGroup } =
    useCodeGroupModal(codeGroupData);
  const { programMarketConfig } = useContext(GlobalConfigContext);
  const isAssociatedCodeGroupEditable =
    programMarketConfig?.isAssociatedCodeGroupEditable || false;

  const [modalToast, setModalToast] = useState([]);
  const { data: attachedCampaigns } = useQuery(getCampaignsTaggedToCodeGroup, {
    variables: { filter: { codeGroups: [codeGroupData?.codeGroupId] } },
  });

  useEffect(() => {
    if (!alreadyCreatedData.codeGroupId && codeGroupData?.codeGroupId) {
      setAlreadyCreatedData(codeGroupData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeGroupData]);
  const onCloseModal = () => {
    if (codeGroupData?.codeGroupId) {
      setShowModal((prev) => ({ ...prev, setupModal: false }));
      setCodeGroupData({});
    } else
      setShowModal({ ...showModal, setupModal: false, closeConfirmModal: true });
  };
  const handleActionButtons = async (enableDraftFlow, lifecycleStatus) => {
    if (enableDraftFlow) {
      const isCodeGroupValid = validateCodeGroup(CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT);
      if (!isCodeGroupValid?.isValid) {
        setModalToast([
          {
            text: isCodeGroupValid.message,
            type: "error",
            id: 3,
          },
        ]);
        return;
      }
      const response = await performDraftCodeGroup();
      if (response.isSuccess) {
        setModalToast([
          {
            text: "Code Group saved as draft",
            type: "success",
            id: 1,
          },
        ]);
        setCodeGroupData((prev) => ({
          ...prev,
          codeGroupId: codeGroupData.codeGroupId || response.codeGroupId,
          lifecycleStatus: CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT,
        }));
        return;
      }
      setModalToast([
        {
          text: response.message,
          type: "error",
          id: 3,
        },
      ]);
    } else {
      const isBeingInActive =
        alreadyCreatedData?.administrationStatus === "ENABLED" &&
        codeGroupData?.administrationStatus === "DISABLED";
      if (
        attachedCampaigns?.listCampaigns?.length > 0 &&
        codeGroupData?.codeGroupId &&
        isBeingInActive
      ) {
        const campaignNames = attachedCampaigns?.listCampaigns
          .map((campaign) => campaign.title)
          .join(", ");
        setModalToast([
          {
            text: `This code group is associated with campaigns ${campaignNames} & cannot be made inactive.`,
            type: "error",
            id: 3,
          },
        ]);
        return;
      }
      const isComingFromDraft =
        lifecycleStatus === CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT;
      const updatedLifeCycleStatus = isComingFromDraft
        ? CODE_GROUP_LIFE_CYCLE_STATUS.QUEUED
        : lifecycleStatus;
      const isCodeGroupValid = validateCodeGroup(updatedLifeCycleStatus);
      if (!isCodeGroupValid.isValid) {
        setModalToast([
          {
            text: isCodeGroupValid.message,
            type: "error",
            id: 3,
          },
        ]);
        return;
      }
      const response = await performPublishCodeGroup(updatedLifeCycleStatus);
      if (response.isSuccess) {
        setShowModal({ showModal, setupModal: false });
        setParentToastData([
          {
            text: response.message,
            type: "success",
            id: 2,
          },
        ]);
        setCodeGroupData({});
        refetchListing();
        return;
      }
      setModalToast([
        {
          text: response.message,
          type: "error",
          id: 2,
        },
      ]);
    }
  };

  const checkIfAllFieldsAreUnchanged =
    new Date(codeGroupData.codeActiveStartDate).getTime() ===
      new Date(alreadyCreatedData.codeActiveStartDate).getTime() &&
    new Date(codeGroupData.codeActiveEndDate).getTime() ===
      new Date(alreadyCreatedData.codeActiveEndDate).getTime() &&
    codeGroupData.codeGroupName === alreadyCreatedData.codeGroupName;

  const editableMap = useMemo(() => {
    return {
      ACTIVE: ["codeActiveEndDate", "codeGroupName"],
      INACTIVE: ["codeActiveStartDate", "codeActiveEndDate", "codeGroupName"],
    };
  }, []);

  const getUISchema = useCallback(() => {
    const isEditMode = codeGroupData?.codeGroupId ? true : false;
    const canEdit = isEditMode && isAssociatedCodeGroupEditable;

    const editableFields = editableMap[codeGroupData?.compositeStatus] || [];

    return canEdit
      ? applyEditableFieldRules(uiSchema, editableFields)
      : applyEditableFieldRules(uiSchema, []); // Pass empty array to ensure no disabled/enabled props
  }, [isAssociatedCodeGroupEditable, codeGroupData, editableMap]);

  return (
    <Dialog
      className="createProduct-modal"
      open={showModal.setupModal}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2
              data-testid="create-code-group-modal"
              style={{ marginLeft: "18%", textAlign: "center" }}
            >
              {codeGroupData?.codeGroupId ? "Update" : "Create"} Code Group
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={onCloseModal}
              className="cursor-pointer"
              data-testid="closeIcon"
            />
          </Grid>
        </Grid>
        <ToastContainer
          data={modalToast}
          removeToast={() => setModalToast([])}
          showActionIcon
        />
      </DialogTitle>
      {!loadCodeGroupLoader ? (
        <DialogContent
          sx={{
            overflowX: "hidden",
          }}
        >
          <div className="my-5 create-modal-wrapper">
            <JsonFormsWrapper
              jsonschema={jsonSchema}
              // DONT REMOVE IT, MIGHT BE HELP IN FUTURE
              // readonly={
              //   alreadyCreatedData.codeGroupId &&
              //   ((alreadyCreatedData.compositeStatus === "INACTIVE" &&
              //     checkIfAllFieldsAreUnchanged &&
              //     codeGroupData.administrationStatus === "ENABLED") ||
              //     !["DRAFT", "INACTIVE"].includes(
              //       alreadyCreatedData.compositeStatus
              //     ))
              // }
              uischema={getUISchema()}
              data={codeGroupData}
              onChange={(val) => setCodeGroupData(val)}
            />
          </div>
        </DialogContent>
      ) : (
        <DialogContent className="display-flex align-items-center justify-content-center">
          <LoadingSpinner
            data-testid="loading-spinner"
            size={{ height: "50px", width: "50px" }}
          />
        </DialogContent>
      )}
      <DialogActions className="mt-5 display-flex justify-content-end align-items-center">
        {[
          CODE_GROUP_LIFE_CYCLE_STATUS.ACTIVE,
          CODE_GROUP_LIFE_CYCLE_STATUS.INACTIVE,
        ].includes(codeGroupData?.compositeStatus) && (
          <Toggle
            className="toggleStyles mr-3"
            label={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                ? "Active"
                : "In Active"
            }
            disabled={
              alreadyCreatedData.compositeStatus === "INACTIVE" &&
              !checkIfAllFieldsAreUnchanged
            }
            onUpdate={(val) =>
              setCodeGroupData({
                ...codeGroupData,
                administrationStatus: val
                  ? CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                  : CODE_GROUP_ADMINISTRATION_STATUS.DISABLED,
              })
            }
            size="medium"
            checked={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
            }
          />
        )}
        {(!codeGroupData.codeGroupId ||
          (codeGroupData.codeGroupId &&
            codeGroupData.lifecycleStatus ===
              CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT)) && (
          <Button
            variant="secondary"
            className="justify-content-space-around"
            onClick={() =>
              handleActionButtons(true, CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT)
            }
          >
            Save as Draft
          </Button>
        )}
        <Button
          variant="primary"
          className="jufstify-content-space-around"
          onClick={() => handleActionButtons(false, codeGroupData.lifecycleStatus)}
        >
          {codeGroupData?.codeGroupId ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
