import { Tag } from "pepsico-ds";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import "./jsonViewer.scss";

const formatJson = (json) => {
  try {
    // Convert object to JSON string with indentation if not already a string
    if (typeof json !== "string") {
      json = JSON.stringify(json, null, 2);
    }

    // Escape HTML characters except for the ones we are testing for
    const escapeHtml = (str) =>
      str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;");

    json = escapeHtml(json);

    // Syntax highlighting
    return json
      .replace(/(&quot;(.*?)&quot;)(?=\s*:)/g, '<span class="json-key">$1</span>') // Keys
      .replace(/\b(-?\d+(\.\d+)?)\b/g, '<span class="json-number">$1</span>') // Numbers
      .replace(/\b(true|false)\b/g, '<span class="json-boolean">$1</span>'); // Booleans
  } catch (error) {
    return `<span class="json-error">Invalid JSON ${error}</span>`;
  }
};

const JsonViewer = ({ data }) => {
  const codeRef = useRef(null);
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    setToast({
      open: true,
      type: "success",
      title: "Copied to clipboard!",
      description: "",
    });
  }, [data]);

  const formattedJson = useMemo(() => formatJson(data), [data]);

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.innerHTML = formattedJson;
    }
  }, [formattedJson]);

  return (
    <div className="json-viewer-container">
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />

      <Tag
        className="copy-button"
        text="Copy code"
        icon="copy"
        onClick={copyToClipboard}
      ></Tag>

      <pre className="preformatted-text">
        <code ref={codeRef}></code>
      </pre>
    </div>
  );
};

export default JsonViewer;
