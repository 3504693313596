import { defaultTo } from "lodash";
import { Icon, TextInput, Tooltip } from "pepsico-ds";
import PropTypes from "prop-types";
import * as React from "react";
import "./text-input.scss";

const DecimalInputField = ({
  value,
  updateValue,
  label,
  errors,
  uiSchema,
  required,
  disabled,
  visible,
}) => {
  const maxDecimalPlaces = uiSchema.maxDecimalPlaces || 2;
  const [error, setError] = React.useState("");

  const inputRef = React.useRef();

  const parseValue = (val) => {
    let parsedVal = parseFloat(val);
    if (parsedVal < 0 || Number.isNaN(parsedVal)) {
      parsedVal = "";
    }
    return parsedVal;
  };

  const handleDecimalInput = (e) => {
    const valueStr = String(value || "");
    const decimalCount = (valueStr.match(/\./g) || []).length;

    // Prevent additional decimal point if one already exists
    if (decimalCount >= 1 && e.key === ".") {
      e.preventDefault();
      return;
    }

    const currentDecimalPlaces = (valueStr.split(".")[1] || "").length;

    // Check if maximum decimal places exceeded
    if (currentDecimalPlaces >= maxDecimalPlaces && /[0-9]/.test(e.key)) {
      setError(`Maximum of ${maxDecimalPlaces} decimal places allowed`);
      return;
    } else if (/[0-9]/.test(e.key)) {
      setError(null);
    }
  };

  const onChange = (val) => {
    const updatedValue = parseValue(val);
    updateValue(updatedValue);
    validateDecimalPlaces(updatedValue);
  };

  const onBlur = () => {
    let trimmedValue = value ?? "";
    if (trimmedValue === "") {
      trimmedValue = undefined;
    }
    updateValue(trimmedValue);
  };

  const validateDecimalPlaces = (value) => {
    const valueStr = String(value || "");
    const decimalCount = (valueStr.match(/\./g) || []).length;

    const currentDecimalPlaces = (valueStr.split(".")[1] || "").length;
    if (decimalCount === 1 && currentDecimalPlaces > maxDecimalPlaces) {
      setError(`Maximum of ${maxDecimalPlaces} decimal places allowed`);
    } else {
      setError(null);
    }
  };

  return visible ? (
    <>
      <div className="input-text-wrapper">
        <TextInput
          required={required}
          className={`${uiSchema?.className ?? ""}`}
          disabled={disabled || uiSchema.readOnly || uiSchema.disabled}
          maxLength={uiSchema.maxLength ?? ""}
          onKeyDown={handleDecimalInput}
          type="number"
          error={error || errors}
          label={label}
          info={uiSchema?.info || ""}
          showLabelIcon={!!uiSchema?.info}
          ref={inputRef}
          onUpdate={onChange}
          onBlur={onBlur}
          value={value === 0 ? "0" : defaultTo(value, undefined)}
          placeholderText={uiSchema.placeholder ?? ""}
          readOnly={uiSchema.readonly}
          helperText={uiSchema.helperText}
          min={uiSchema.min ?? undefined}
          step={uiSchema.step ?? 0.01}
        />
        {uiSchema?.tooltipText && uiSchema?.tooltipText?.length > 0 && (
          <div className="tooltip-text">
            <Tooltip text={uiSchema.tooltipText}>
              <Icon alt="" icon="info" size="small" />
            </Tooltip>
          </div>
        )}
      </div>
      {uiSchema.helpText && (
        <div className="input-help-text">
          <Tooltip text={uiSchema.helpText} className="help-tooltip">
            <Icon icon="info" size="small" />
          </Tooltip>
          Help
        </div>
      )}
    </>
  ) : null;
};

DecimalInputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  schema: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  uiSchema: PropTypes.shape({
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    info: PropTypes.string,
    placeholder: PropTypes.string,
    maxDecimalPlaces: PropTypes.number,
    step: PropTypes.number,
  }),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

DecimalInputField.defaultProps = {
  value: "",
  errors: "",
  required: false,
  disabled: false,
  uiSchema: {},
};

export default DecimalInputField;
