import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useState } from "react";
import { matchKeywordGroupsList } from "../../../graphql/queries/listKeywordGroups";

const ProductKeywordSelectComponent = (props) => {
  const { handleChange, label, path, required, visible, enabled, data } = props;

  const { data: keywordGroupsData } = useQuery(matchKeywordGroupsList, {
    variables: {
      type: "PRODUCT",
    },
  });
  const formatOptions = (options) =>
    options.map((option) => ({
      id: option.keywords?.[0],
      displayText: option.name,
    }));

  const [selected, setSelected] = useState(
    data
      ? formatOptions(
          data.map(
            (item) =>
              keywordGroupsData.listKeywordGroups.find(
                (option) => option.keywords?.[0] === item
              ) || {}
          )
        )
      : []
  );

  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const handleSelectValue = (val) => {
    setSelected(val);
    handleChange(path, formatData(val));
  };

  return visible ? (
    <div className="multiple-select-wrap peacock-dropdown-show-label">
      <Dropdown
        childList={formatOptions(keywordGroupsData?.listKeywordGroups || [])}
        selection="multiple"
        isRequired={required}
        label={label}
        disabled={!enabled}
        selectedValue={selected}
        setSelectedValue={(val) => handleSelectValue(val)}
      />
    </div>
  ) : null;
};

export const ProductKeywordSelectControl = withJsonFormsControlProps(
  ProductKeywordSelectComponent
);
