import moment from "moment-timezone";
import { useContext } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { findControlInUISchema } from "../../../utils/commons";
import { customComponents } from "./allComponents";

const ViewComponentsRenderer = ({
  customComponentName = "",
  data,
  inputSchema,
  uiSchema,
}) => {
  const recieptTitle = {
    end_timestamp: "Receipt End Date",
    start_timestamp: "Receipt Start Date",
  };
  const {
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);
  const currentProgramTimezone = programConfig?.localization?.programTimezone;

  const formatData = (schema, uischema, data) => {
    if (schema?.type === "boolean") {
      return data ? "Yes" : "No";
    }
    if (
      schema?.type === "array" &&
      schema.items?.type === "string" &&
      Array.isArray(data)
    ) {
      return data.join(", ");
    }
    if (schema?.date_format || schema.is_timestamp) {
      return moment.tz(data, currentProgramTimezone).format("Do MMMM YYYY hh:mm A");
    }
    if (uischema?.options?.labelMapping) {
      return uischema.options.labelMapping[data] || data;
    }
    return data === undefined || data === null || data === "" ? "-" : data;
  };

  const renderData = (schema, uischema, data) => {
    if (
      !schema ||
      Object.keys(schema).length === 0 ||
      data === undefined ||
      data === null
    ) {
      return <></>;
    }
    if (schema.type === "object") {
      return (
        <>
          {Object.entries(schema.properties).map(([key, propertySchema]) => {
            const title =
              customComponentName?.length > 0 &&
              customComponentName === "receipt-in-date-range"
                ? recieptTitle[key]
                : propertySchema.title || key;
            const value = data[key];
            const controlSchema = findControlInUISchema(
              uischema,
              `#/properties/${key}`
            );
            if (propertySchema.hidden === true) {
              return <></>;
            }
            return (
              <div key={key} className="additional-fields-container">
                <div className="field-pair">
                  <span className="field-label">{title}:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    {renderData(propertySchema, controlSchema, value)}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      );
    } else if (
      schema.type === "array" &&
      Array.isArray(data) &&
      schema.items?.type !== "string"
    ) {
      return (
        <>
          {data.map((item) =>
            schema.items ? (
              renderData(schema.items, uischema, item)
            ) : (
              <p key={item} className="field-value">
                {item}
              </p>
            )
          )}
        </>
      );
    } else {
      return <>{formatData(schema, uischema, data)}</>;
    }
  };

  const CustomComponent = customComponents[customComponentName];

  return (
    <>
      {CustomComponent ? (
        <CustomComponent data={data} inputSchema={inputSchema} />
      ) : (
        renderData(inputSchema, uiSchema, data)
      )}
    </>
  );
};

export default ViewComponentsRenderer;
