import Snackbar from "@mui/material/Snackbar";
import { Notification } from "pepsico-ds";
import "./_toast-notification.scss";

const ToastNotification = ({
  open,
  handleClose,
  type,
  title,
  description = "",
  primaryActionLabel = "Close",
}) => {
  return (
    <Snackbar
      className="toast-notification"
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ width: "97%", top: 0 }}
      message={
        <Notification
          type={type}
          title={title}
          text={description}
          primaryActionLabel={primaryActionLabel || "Close"}
          primaryActionOnClick={handleClose}
        />
      }
    ></Snackbar>
  );
};

export default ToastNotification;
