import { gql } from "@apollo/client";

export const LoadProgram = gql`
  query LoadProgram($programId: String!) {
    loadProgram(programId: $programId) {
      id
      name
      description
      slug
      logo
      defaultValues
      configurations {
        key
        value {
          title
          description
          data
        }
      }
      tags {
        campaign
        content
      }
      localization {
        programTimezone
        countries {
          key
          value {
            languages
            currencies
            addressSchema
          }
        }
      }
      campaignsGroups {
        name
        imageURL
        key
        campaigns
      }
      campaigns {
        key
        value {
          enabled
          label
          image
          categories
          requirement {
            requiredOutcomes
            requiredActivities
            requiredRules
            possibleOutcomes {
              name
              description
              inputSchema
              uiSchema
              requiredRules
              slug
              rules {
                id
                name
                hidden
                inputSchema
                ruleFunction
                maxAllowed
                slug
                uiSchema
              }
            }
            possibleActivities {
              id
              name
              description
              inputSchema
              uiSchema
              requiredRules
              requiredOutcomes
              slug
              maxAllowed
              rules {
                id
                name
                hidden
                inputSchema
                ruleFunction
                maxAllowed
                slug
                uiSchema
              }
              rulesConfiguration {
                settings {
                  maxAllowed
                  minRequired
                }
                rules {
                  rule {
                    id
                    name
                    hidden
                    inputSchema
                    ruleFunction
                    maxAllowed
                    slug
                    uiSchema
                  }
                  minRequired
                  maxAllowed
                  category
                }
              }
              outcomes {
                id
                name
                slug
                description
                inputSchema
                uiSchema
                requiredRules
                rules {
                  id
                  name
                  hidden
                  inputSchema
                  ruleFunction
                  maxAllowed
                  slug
                  uiSchema
                }
              }
            }
            possibleRules {
              id
              name
              hidden
              inputSchema
              ruleFunction
              maxAllowed
              slug
              uiSchema
            }
          }
          campaignSetup {
            uiSchema
            inputSchema
          }
          contentSteps {
            id
            name
            slug
            version
            description
            active
            inputSchema
            uiSchema
          }
          steps {
            label
            description
            shortTile
            slug
            values
          }
        }
      }
      widgets {
        id
        embedCode
        displayInfo
        widgetDefinitionSlug
        title
        isActive
        widgetData
        selectedCampaignsSize
        createdAt
        updatedAt
      }
      widgetDefinitions {
        id
        title
        version
        slug
        inputSchema
        uiSchema
      }
      configurations {
        key
        value {
          title
          description
          data
        }
      }
    }
  }
`;

export const LoadPrograms = gql`
  query LoadPrograms {
    loadPrograms {
      id
      name
      description
      slug
      logo
      defaultValues
    }
  }
`;
