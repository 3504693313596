import { withJsonFormsControlProps } from "@jsonforms/react";
import { useEffect, useState } from "react";
import {
  dateFormatDataItemsList,
  winnerWidgetColumnDataItems,
} from "./winner-widget-data-items";

import WidgetContent from "../WidgetContentComponent";
const WinnerWidgetComponent = ({
  handleChange,
  label,
  data,
  path,
  required,
  schema,
}) => {
  const [winnerColumnDataItems, setWinnerColumnDataItems] = useState([]);
  const [dateFormatDataItems, setDateFormatDataItems] = useState([]);
  const [defaultDateFormat, setDefaultDateFormat] = useState("DD/MM/YYYY");

  useEffect(() => {
    // winnerWidgetColumnDataItems is the default data items if schema?.dataItems is not available
    setWinnerColumnDataItems(
      schema?.dataItems?.length > 0 ? schema?.dataItems : winnerWidgetColumnDataItems
    );
  }, [schema?.dataItems]);

  useEffect(() => {
    if (winnerColumnDataItems) {
      var dateOfWinning = winnerColumnDataItems?.find(
        (addedColumn) => addedColumn?.slug === "date-of-winning"
      );
      // dateFormatDataItemsList is the default data items if dateOfWinning?.dateFormatDataItems is not available
      setDateFormatDataItems(
        dateOfWinning?.dateFormatDataItems?.length > 0
          ? dateOfWinning?.dateFormatDataItems
          : dateFormatDataItemsList
      );
      // "DD/MM/YYYY" is the default date format if dateOfWinning?.defaultDateFormat is not available
      if (dateOfWinning?.defaultDateFormat) {
        setDefaultDateFormat(dateOfWinning?.defaultDateFormat ?? "DD/MM/YYYY");
      }
    }
  }, [winnerColumnDataItems]);
  const handleOnSave = (updated) => {
    handleChange(path, updated);
  };

  const handleDelete = () => {
    handleChange(path, []);
  };

  const title = label ?? "Winners Widget";
  return (
    <WidgetContent
      title={title}
      data={data}
      required={required}
      widgetColumnDataItems={winnerColumnDataItems}
      handleDelete={handleDelete}
      handleOnSave={handleOnSave}
      columnHeaderText="Winners"
      dateFormatList={dateFormatDataItems}
      defaultDateFormat={defaultDateFormat}
      isWinnerWidget={true}
    />
  );
};
export const WinnerWidgetControl = withJsonFormsControlProps(WinnerWidgetComponent);
