import { useContext } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { getDateWithFormat } from "../../../utils/commons";

const PackageDateViewComponent = ({ data }) => {
  const { programTimezone } = useContext(GlobalConfigContext);
  const dataText = data
    ? Object.values(data)
        .map((value) => {
          return value
            ? getDateWithFormat(value, programTimezone, "DD/MM/YYYY")
            : value;
        })
        .join(", ")
    : "";
  return (
    <div className="rule-labels">
      <span className="font-xsmall-bold">Fresh by date</span>
      <span className="font-xsmall-regular">{dataText}</span>
    </div>
  );
};

export default PackageDateViewComponent;
