import { useEffect, useState } from "react";
import ButtonGroupDynamic from "../../../common/ButtonGroupDynamic";

import "../../../campaign/CampaignLanguageContent/_campaign-language-content.scss";

import UserParticipationHistoryWidgetModal from "./WidgetContentSetupModal";

const WidgetContentComponent = ({
  data,
  title,
  widgetColumnDataItems,
  required,
  handleDelete,
  handleOnSave,
  columnHeaderText,
  dateFormatList,
  defaultDateFormat,
  isWinnerWidget,
}) => {
  const [savedData, setSavedData] = useState(data ?? []);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (data) {
      setSavedData(data);
    }
  }, [data]);

  const handleToggleModal = () => {
    setOpenModal(!openModal);
  };

  const isSaved = Object.keys(savedData).length > 0;
  return (
    <>
      <div className="display-flex flex-direction-row  justify-content-space-between">
        <div className="font-xs $color-text-secondary mb-2 pt-3">{title}</div>
        {required && (
          <div
            className="font-xxs font-bold $color-text-secondary font-secondary mb-2 pt-3"
            style={{ textAlign: "right" }}
          >
            *Required
          </div>
        )}
      </div>

      <div className="multilanguage-content">
        <div
          className={`language-item display-flex flex-direction-row font-xs font-bold ${isSaved ? "language-added" : ""}`}
        >
          {isSaved ? (
            <div className="added-language display-flex flex-direction-row align-items-center justify-content-space-between">
              <div className="language-content display-flex flex-direction-row align-items-center">
                {columnHeaderText} Widget Configuration
              </div>
              <ButtonGroupDynamic
                size="small"
                spacing="1px"
                items={[
                  {
                    icon: "edit",
                    onClick: () => handleToggleModal(),
                  },
                  {
                    icon: "delete",
                    onClick: () => handleDelete(),
                  },
                ]}
              />
            </div>
          ) : (
            <>
              <ButtonGroupDynamic
                size="small"
                spacing="1px"
                items={[
                  {
                    icon: "add",
                    onClick: handleToggleModal,
                  },
                ]}
              />
              Configure {columnHeaderText} Widget
            </>
          )}
        </div>
      </div>
      {openModal && (
        <UserParticipationHistoryWidgetModal
          widgetColumnDataItems={widgetColumnDataItems}
          data={savedData}
          onChange={handleOnSave}
          showModal={openModal}
          title={title}
          isEditMode={isSaved}
          columnHeaderText={columnHeaderText}
          toggleCreateBannerModal={handleToggleModal}
          dateFormatList={dateFormatList}
          defaultDateFormat={defaultDateFormat}
          isWinnerWidget={isWinnerWidget}
        />
      )}
    </>
  );
};

export default WidgetContentComponent;
