import { Pagination, ToastContainer } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  ListingComponentContext,
  ListingComponentProvider,
} from "../../components/common/listingComponents/ListingComponentContext";
import ListingComponentWrapper from "../../components/common/listingComponents/ListingComponentWrapper";
import ConfirmationModal from "../../components/performances/ConfirmationModal";
import PerformanceDetailsDrawer from "../../components/performances/PerformanceDetailsDrawer";
import PerformancesTable from "../../components/performances/PerformancesTable";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { listEngagements } from "../../graphql/queries/listEngagements";
import { paths } from "../../routes/paths";
import {
  engagementAllFiltersSchemas,
  engagementFilterSchemas,
  performancesFilterAllJsonSchema,
  performancesFilterAllUiSchema,
  performancesFilterJsonSchema,
  performancesFilterUiSchema,
} from "./mocks";
import "./performances.scss";
const PerformancesListPage = ({ engagementType }) => {
  const ROWS_PER_PAGE = 10;
  return (
    <ListingComponentProvider
      query={listEngagements}
      fetchPolicy="cache-and-network"
      queryKey="listEngagements"
      initialFilterData={{
        engagementStatus: [],
        engagementType: engagementType ? [engagementType] : [],
        limit: ROWS_PER_PAGE,
        offset: 0,
      }}
      noPagination={true}
    >
      <PerformancesListContent
        engagementType={engagementType}
        rowsPerPage={ROWS_PER_PAGE}
      />
    </ListingComponentProvider>
  );
};

const PerformancesListContent = ({ engagementType, rowsPerPage }) => {
  const navigate = useNavigate();

  const { filterData, setFilterData, viewableRecords } = useContext(
    ListingComponentContext
  );

  const { programTimezone } = useContext(GlobalConfigContext) || {};

  const [toastData, setToastData] = useState([]);

  const [showDrawer, setShowDrawer] = useState(false);

  const [performanceID, setPerformanceID] = useState(null);
  const [engagementItem, setEngagementItem] = useState(null);

  const [modalType, setModalType] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (filterData) {
      setCurrentPage(Math.ceil(filterData.offset / rowsPerPage) + 1);
    }
  }, [filterData, rowsPerPage]);

  const handleModalOpen = (type) => setModalType(type);
  const handleModalClose = () => setModalType(null);

  const handleOnTap = (item) => {
    setPerformanceID(item.id);
    setEngagementItem(item);
    setShowDrawer(true);
  };

  const handleOnDetailViewTap = (performanceID) => {
    navigate(
      `${engagementType === "all" ? "../" : ""}${paths.performance_details}/${performanceID}`
    );
  };

  const handleChangePage = (page) => {
    setFilterData((prev) => ({
      ...prev,
      offset: (page - 1) * rowsPerPage, // Reset offset based on the page
    }));
    setCurrentPage(page);
  };

  const pageTitles = {
    "package-scan": "Package Scans",
  };

  const engagementTypeFilterSchemas = engagementFilterSchemas[engagementType];
  const engagementTypeAllFiltersSchemas =
    engagementAllFiltersSchemas[engagementType];

  const totalPages = Math.ceil(viewableRecords?.total / rowsPerPage);

  return (
    <div className="px-3 pt-3">
      {toastData && (
        <ToastContainer
          className="performances-listing-toast"
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      <div className="container-card">
        <div className="performances-page-content">
          <div className="performances-header">
            <h2>{pageTitles[engagementType] || "Performances"}</h2>
          </div>
          <ListingComponentWrapper
            customStyles={{ flex: 1 }}
            filterClasses="performances-filter-styles"
            filterConfig={{
              withoutDebounce: {
                jsonSchema:
                  engagementTypeFilterSchemas?.jsonSchema ||
                  performancesFilterJsonSchema,
                uiSchema:
                  engagementTypeFilterSchemas?.uiSchema ||
                  performancesFilterUiSchema,
              },
              allFilters: {
                jsonSchema:
                  engagementTypeAllFiltersSchemas?.jsonSchema ||
                  performancesFilterAllJsonSchema,
                uiSchema:
                  engagementTypeAllFiltersSchemas?.uiSchema ||
                  performancesFilterAllUiSchema,
              },
            }}
            toggleViewType={false}
            withAllFilters={true}
            exportOptions={true}
            offsetBasedPagination={true}
          >
            <PerformancesTable
              currentProgramTimezone={programTimezone}
              performancesData={viewableRecords?.items || []}
              engagementType={engagementType}
              onTap={(data) => handleOnTap(data)}
            />
            {totalPages > 1 && (
              <div className="pagination-wrap mb-4">
                <Pagination
                  currentPage={currentPage}
                  onUpdate={handleChangePage}
                  pageCount={totalPages}
                  size="medium"
                  variant="number"
                />
              </div>
            )}
          </ListingComponentWrapper>
        </div>
      </div>
      <PerformanceDetailsDrawer
        showDrawer={showDrawer}
        engagementItem={engagementItem}
        onCloseDrawer={() => setShowDrawer(false)}
        onDetailViewClick={() => handleOnDetailViewTap(performanceID)}
        onApprove={() => {
          setShowDrawer(false);
          handleModalOpen("approve");
        }}
        onReject={() => {
          setShowDrawer(false);
          handleModalOpen("reject");
        }}
      />
      <ConfirmationModal
        showModal={modalType === "approve"}
        onClose={handleModalClose}
        title="Approve"
        text=""
        onConfirm={() => console.log("approve")}
      />

      <ConfirmationModal
        showModal={modalType === "reject"}
        onClose={handleModalClose}
        title="Reject"
        text="Please explain the reason of the rejectionage."
        onConfirm={() => console.log("reject")}
      />
    </div>
  );
};

export default PerformancesListPage;
