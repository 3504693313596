import { gql } from "@apollo/client";

const createRewardGroup = gql`
  mutation ($RewardGroupInput: RewardGroupInput!) {
    createRewardGroup(rewardGroup: $RewardGroupInput) {
      id
      campaignId
      name
      type
      numberOfCodeScansRequiredToWin
      image
      position
      description
      maxParticipationPerInterval
      numberOfWinnersLimit
      completed
      isSpinWheelEnabled
      rewardGroupRewards {
        id
        rewardId
        rewardGroupId
        walletWithdrawalDate
        startDate
        endDate
        position
        quantity
        isExtra
        chanceToWinPercentage
        totalUserWinLimit
      }
    }
  }
`;

export default createRewardGroup;
