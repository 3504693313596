import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ButtonGroupDynamic from "../ButtonGroupDynamic";
import "./ImagePreview.css";

const ImagePreview = ({ imageSrc }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imageSize, setImageSize] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const fetchImageDetails = async () => {
      try {
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        setImageSize(`${(blob.size / (1024 * 1024)).toFixed(2)} MB`);
        setImageName(imageSrc.split("/").pop());
      } catch (error) {
        console.error("Error fetching image details:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchImageDetails();
  }, [imageSrc]);

  const zoomIn = () => setZoomLevel(zoomLevel + 0.1);
  const zoomOut = () => setZoomLevel(zoomLevel > 1 ? zoomLevel - 0.1 : zoomLevel);
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = imageSrc;
    link.download = imageName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className={isFullScreen ? "image-preview-container" : ""}
      style={{ "--zoom": zoomLevel }}
    >
      <div className={`${isFullScreen ? "controls-full-screen" : "controls"}`}>
        <ButtonGroupDynamic
          className="icon-button-group"
          items={[
            { icon: "zoom_in", onClick: zoomIn },
            { icon: "zoom_out", onClick: zoomOut },
            {
              icon: isFullScreen ? "zoom_in_map" : "zoom_out_map",
              onClick: toggleFullScreen,
            },
          ]}
          size="small"
        />

        <ButtonGroupDynamic
          className="icon-button-group"
          items={[{ icon: "download", onClick: handleDownload }]}
          size="small"
        />
      </div>

      <div className={`image-preview ${isFullScreen ? "full-screen" : ""}`}>
        <div
          className="image-container"
          style={{
            transform: `scale(${zoomLevel})`,
            overflow: zoomLevel > 1 ? "auto" : "hidden",
          }}
        >
          {isLoading ? (
            <div className="spinner">Loading...</div>
          ) : (
            <img src={imageSrc} alt={imageName} />
          )}
        </div>
      </div>

      <div className={isFullScreen ? "info-full-screen" : "info"}>
        <span className="file-name">{imageName}</span>
        <span className="file-size">
          File size: <span className="file-value"> {imageSize} </span>
        </span>
      </div>
    </div>
  );
};

ImagePreview.propTypes = {
  imageSrc: PropTypes.string.isRequired,
};

export default ImagePreview;
