import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import "./DatePickerMaterialUI.scss";

moment.locale("en");

export default function DatePickerMaterialUI({
  label,
  placeholder,
  dateValue,
  setDate,
  timezone,
}) {
  const handleDateChange = (date) => {
    const formattedDate = date?.format("YYYY-MM-DD");
    setDate(formattedDate ?? null);
  };

  return (
    <div className="date-picker-material-ui-wrap">
      {label && <label htmlFor={`datePicker-${label}`}>{label}</label>}
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
        adapterLocale="en"
      >
        <DatePicker
          className="customDatePickerStyles"
          value={dateValue ? moment.tz(dateValue, timezone) : null}
          onChange={handleDateChange}
          // minDate={moment.tz(new Date(), timezone)}
          label={placeholder}
        />
      </LocalizationProvider>
    </div>
  );
}
