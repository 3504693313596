import { Breadcrumb } from "pepsico-ds";
import { useParams } from "react-router-dom";
import { paths } from "../../routes/paths";
import "./performances.scss";

import PerformanceDetailsContainer from "../../components/performances/PerformanceDetailsContainer";

const PerformanceDetailsPage = () => {
  const { performanceID } = useParams();
  return (
    <>
      <div className="container">
        <div className="wrap">
          <Breadcrumb
            breadcrumbs={[
              {
                label: "Performances",
                href: `../../${paths.performances_list}`,
              },
              {
                label: "Performances",
                href: `../../${paths.performances_list}`,
              },
              {
                label: "Performance detail",
                href: `../../${paths.performances_list}/${paths.performance_details}/${performanceID}`,
              },
            ]}
            activeIndex={1}
          />
          <PerformanceDetailsContainer
            title="Performance details"
            showFooter={true}
            engagementItem={{ id: performanceID }}
          />
        </div>
      </div>
    </>
  );
};

export default PerformanceDetailsPage;
