export const winnerWidgetColumnDataItems = [
  {
    name: "Winner's Name",
    type: "WINNER_WIDGET",
    slug: "winners-name",
  },
  {
    name: "Winner's State",
    slug: "winners-state",
    type: "WINNER_WIDGET",
  },
  {
    name: "Reward",
    slug: "rewards",
    type: "WINNER_WIDGET",
  },
  {
    name: "Date of Winning",
    slug: "date-of-winning",
    type: "WINNER_WIDGET",
  },
];

export const dateFormatDataItemsList = [
  { displayText: "DD/MM/YYYY", id: "1" },
  { displayText: "MM/DD/YYYY", id: "2" },
  { displayText: "YYYY/MM/DD", id: "3" },
  { displayText: "YYYY/DD/MM", id: "4" },
  { displayText: "MM-DD-YYYY", id: "5" },
  { displayText: "DD-MM-YYYY", id: "6" },
  { displayText: "D MMMM YYYY", id: "7" },
];
