import { useLazyQuery, useMutation } from "@apollo/client";
import { createAjv } from "@jsonforms/core";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Button } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ToastNotification from "../../components/common/toastNotification/ToastNotification";
import JsonFormsWrapper from "../../components/jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { leaderboardEditDataMap } from "../../components/leaderboards/utils/common";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import {
  createLeaderboardMutation,
  updateLeaderboardMutation,
} from "../../graphql/mutations/createLeaderboard";
import { getLeaderboardsList } from "../../graphql/queries/getLeaderboardsList";
import { useToast } from "../../hooks/useToast";
import { paths } from "../../routes/paths";
import "./LeaderboardsPage.scss";
import {
  addKeyValueToElement,
  createLeaderboardsJsonSchema,
  createLeaderboardsUISchema,
} from "./schemas";

function CreateLeaderboardsModal({
  showModal,
  toggleCreateLeaderboardModal,
  refetch,
  selectedLeaderboard,
  setSelectedLeaderboard,
  createShowToast = true,
}) {
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalConfigContext);
  const initialState = {
    programId: globalState?.programConfig?.id,
  };
  const [getLeaderboardByTitle, { loading: getLoading }] =
    useLazyQuery(getLeaderboardsList);
  const [createLeaderboard, { loading }] = useMutation(createLeaderboardMutation);
  const [updateLeaderboard, { loading: updateLoading }] = useMutation(
    updateLeaderboardMutation
  );
  const { toast, showToast, closeToast } = useToast();
  const [createLeaderboardData, setCreateLeaderboardData] = useState(initialState);

  useEffect(() => {
    if (selectedLeaderboard?.id && showModal) {
      const mapedData = leaderboardEditDataMap(selectedLeaderboard);
      setCreateLeaderboardData({
        ...mapedData,
      });
    }
  }, [selectedLeaderboard, showModal]);
  useEffect(() => {
    if (selectedLeaderboard?.id) {
      addKeyValueToElement("#/properties/activePointMinimum", "disabled", true);
      addKeyValueToElement("#/properties/tieBreaker", "disabled", true);
      addKeyValueToElement("#/properties/rankOrder", "disabled", true);
      const startsAt = selectedLeaderboard?.startsAt;
      if (new Date(startsAt) < new Date()) {
        addKeyValueToElement("#/properties/startsAt", "disabled", true);
      } else {
        addKeyValueToElement("#/properties/startsAt", "disabled", false);
      }
    } else {
      addKeyValueToElement("#/properties/activePointMinimum", "disabled", false);
      addKeyValueToElement("#/properties/tieBreaker", "disabled", false);
      addKeyValueToElement("#/properties/rankOrder", "disabled", false);
      addKeyValueToElement("#/properties/startsAt", "disabled", false);
    }
  }, [selectedLeaderboard]);
  // Get all existing sweepstakes(With status- NONE, COMPLETED, ENDED, ARCHIVED)
  const validateLeaderboardData = (payload) => {
    const ajv = createAjv();
    const isDataValid = ajv.validate(createLeaderboardsJsonSchema, payload);
    return isDataValid;
  };
  const showErrorToast = (error) => {
    showToast(
      "error",
      `Could not create the Leaderboard.`,
      error.message || "Please try again later."
    );
  };

  const goToCampaigns = (event, reason) => {
    if (reason === "clickaway") {
      closeToast();
    } else {
      navigate(`${paths.campaign_list}/${paths.campaigns}`);
    }
  };
  const validateStartEndDates = () => {
    const { startsAt, endsAt } = createLeaderboardData;
    if (!startsAt || !endsAt) {
      showToast("error", "", "Both start and end dates must be provided.");
      return false;
    }
    const startDate = new Date(startsAt);
    const endDate = new Date(endsAt);

    if (startDate > endDate) {
      showToast("error", "", "Start date cannot be later than the end date.");
      return false;
    }
    return true;
  };

  const handleCreateLeaderboard = async () => {
    const payload = {
      title: createLeaderboardData.title,
      tieBreaker: createLeaderboardData?.tieBreaker?.[0]?.id,
      awardApproval: createLeaderboardData?.awardApproval?.[0]?.id,
      rankOrder: createLeaderboardData?.rankOrder?.[0]?.id,
      programId: createLeaderboardData.programId,
      activePointMinimum: createLeaderboardData.activePointMinimum,
      // minParticipationCount: createLeaderboardData.minParticipationCount,
      endsAt: createLeaderboardData.endsAt,
      startsAt: createLeaderboardData.startsAt,
    };
    const isValid = validateLeaderboardData(payload);
    if (!isValid) {
      showToast("error", "", "Please fill in all required fields.");
      return;
    }
    if (!validateStartEndDates()) {
      return false;
    }

    try {
      const { data: getData } = await getLeaderboardByTitle({
        variables: {
          title: payload.title.trim(),
        },
      });
      //Check whether a leaderboard with same name already exist in list
      const isAlreadyExist = getData?.listLeaderboards?.items?.some(
        (item) =>
          item.title.toUpperCase() === payload.title.trim().toUpperCase() &&
          item.id !== selectedLeaderboard?.id
      );
      //Prevent to create leaderboard with the same name and show error message
      if (isAlreadyExist) {
        showToast("error", "", "There is a Leaderboard with this name already.");
        return;
      }
      if (selectedLeaderboard?.id) {
        delete payload.programId;
        delete payload.id;
        // Update existing leaderboard
        const { data } = await updateLeaderboard({
          variables: {
            id: selectedLeaderboard.id,
            LeaderboardsUpdateInput: payload,
          },
        });
        if (data?.updateLeaderboard?.id) {
          showToast("success", "Leaderboard updated successfully");
          refetch();
          toggleCreateLeaderboardModal();
          setCreateLeaderboardData(initialState);
        } else {
          showToast(
            "error",
            "Could not update the Leaderboard.",
            "Please try again later."
          );
        }
        return;
      }
      // Create new leaderboard
      const { data } = await createLeaderboard({
        variables: {
          LeaderboardsInput: payload,
        },
      });
      if (data?.createLeaderboard?.id) {
        showToast(
          "success",
          "Leaderboard created successfully",
          "Your next step is to create a ranking campaign and add the created leaderboard(s)."
        );
        refetch();
        toggleCreateLeaderboardModal();
        setSelectedLeaderboard(null);
        setCreateLeaderboardData(initialState);
      } else {
        showToast(
          "error",
          "Could not create the Leaderboard.",
          "Please try again later."
        );
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleCloseModal = () => {
    setCreateLeaderboardData(initialState);
    toggleCreateLeaderboardModal();
  };

  return (
    <>
      <Dialog className="create-leaderboards-modal" open={showModal} scroll="paper">
        <DialogTitle>
          <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={10}>
              <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
                {selectedLeaderboard?.id
                  ? "Update Leaderboard"
                  : "Create New Leaderboard"}
              </h2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="flex-end">
              <CloseIcon onClick={handleCloseModal} className="cursor-pointer" />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ overflowX: "hidden" }}>
          <div className="my-5">
            <JsonFormsWrapper
              jsonschema={createLeaderboardsJsonSchema}
              uischema={createLeaderboardsUISchema}
              data={createLeaderboardData}
              onChange={(val) => {
                setCreateLeaderboardData(val);
              }}
            />
          </div>

          <div className="mt-5 display-flex justify-content-end justify-content-space-between">
            <Button onClick={handleCloseModal} variant="secondary">
              Cancel
            </Button>
            <Button
              isLoading={loading || updateLoading || getLoading}
              variant="primary"
              className="justify-content-space-around"
              onClick={() => {
                handleCreateLeaderboard();
              }}
              iconTrailing="arrow_forward"
              disabled={loading || updateLoading || getLoading}
            >
              {selectedLeaderboard?.id ? "Update" : "Create"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {createShowToast && (
        <ToastNotification
          {...toast}
          handleClose={
            toast.type === "success" && toast.description
              ? goToCampaigns
              : closeToast
          }
          primaryActionLabel={
            toast.type === "success" && toast.description
              ? "Go to Campaigns"
              : "Close"
          }
        />
      )}
    </>
  );
}

export default CreateLeaderboardsModal;
