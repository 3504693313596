import { Modal } from "pepsico-ds";
import { useEffect, useState } from "react";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./confirmationModal.scss";

const uiSchemaReject = {
  type: "VerticalLayout",
  elements: [
    {
      type: "SimpleGroup",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/reason",
              component: "text",
            },
            {
              type: "Control",
              scope: "#/properties/comments",
              component: "textarea",
            },
          ],
        },
      ],
    },
  ],
};

const jsonSchemaReject = {
  type: "object",
  properties: {
    reason: {
      type: "string",
      title: "Reason for rejection",
    },
    comment: {
      type: "string",
      title: "Comments",
    },
  },
};

const uiSchemaApprove = {
  type: "VerticalLayout",
  elements: [
    {
      type: "SimpleGroup",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/quantity",
              component: "text",
            },
            {
              type: "Control",
              scope: "#/properties/comments",
              component: "textarea",
            },
          ],
        },
      ],
    },
  ],
};

const jsonSchemaApprove = {
  type: "object",
  properties: {
    quantity: {
      type: "string",
      title: "Outcome quantity",
    },
    comment: {
      type: "string",
      title: "Comments",
    },
  },
};

const ConfirmationModal = ({ showModal, onClose, title, text, onConfirm }) => {
  const [primaryButtonText, setPrimaryButtonText] = useState("Confirm");
  const [uiSchema, setUiSchema] = useState(null);
  const [jsonSchema, setJsonSchema] = useState(null);

  useEffect(() => {
    if (title === "Reject") {
      setPrimaryButtonText("Reject");
      setUiSchema(uiSchemaReject);
      setJsonSchema(jsonSchemaReject);
    } else {
      setPrimaryButtonText("Approve");
      setUiSchema(uiSchemaApprove);
      setJsonSchema(jsonSchemaApprove);
    }
  }, [title]);

  return (
    <Modal
      showModal={showModal}
      onCloseModal={onClose}
      primaryButtonProps={{
        size: "medium",
        text: primaryButtonText,
        variant: "primary",
        onClick: onConfirm,
      }}
      secondaryButtonProps={{
        size: "medium",
        text: "Cancel",
        variant: "secondary",
        onClick: onClose,
      }}
      showTertiaryButton={false}
      showLink={false}
      title={title}
      text={text}
    >
      <div className="pefornace-confirmation-modal">
        <JsonFormsWrapper jsonschema={jsonSchema} uischema={uiSchema} />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
