import { gql } from "@apollo/client";

export const createLeaderboardMutation = gql`
  mutation CreateLeaderboard($LeaderboardsInput: LeaderboardsInput!) {
    createLeaderboard(input: $LeaderboardsInput) {
      id
      programId
      title
      startsAt
      endsAt
      status
      rankOrder
      tieBreaker
      activePointMinimum
      minParticipationCount
      awardApproval
      createdAt
      updatedAt
    }
  }
`;

export const updateLeaderboardMutation = gql`
  mutation UpdateLeaderboard(
    $id: ID!
    $LeaderboardsUpdateInput: LeaderboardsUpdateInput!
  ) {
    updateLeaderboard(id: $id, input: $LeaderboardsUpdateInput) {
      id
      programId
      title
      startsAt
      endsAt
      status
      rankOrder
      tieBreaker
      activePointMinimum
      minParticipationCount
      awardApproval
      createdAt
      updatedAt
    }
  }
`;

export const deleteLeaderboardMutation = gql`
  mutation DeleteLeaderboard($id: ID!) {
    deleteLeaderboard(id: $id)
  }
`;

export const archiveLeaderboardMutation = gql`
  mutation ArchiveLeaderboard($id: ID!) {
    archiveLeaderboard(id: $id) {
      id
      programId
      title
      startsAt
      endsAt
      status
      rankOrder
      tieBreaker
      activePointMinimum
      minParticipationCount
      awardApproval
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const approveLeaderboardMutation = gql`
  mutation ApproveLeaderboard($id: ID!) {
    approveLeaderboard(id: $id) {
      id
      programId
      title
      startsAt
      endsAt
      status
      rankOrder
      tieBreaker
      activePointMinimum
      minParticipationCount
      awardApproval
      metadata
      createdAt
      updatedAt
    }
  }
`;
