import { gql } from "@apollo/client";

export const updateReward = gql`
  mutation UpdateReward(
    $id: ID!
    $input: RewardUpdateInput!
    $isEditAllowed: Boolean
  ) {
    updateReward(id: $id, input: $input, isEditAllowed: $isEditAllowed) {
      id
      name
      partnerId
      programId
      isActive
    }
  }
`;
