import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import "../../common/GridCard.scss";

const BonusGridCard = ({ campaign, actionButtons }) => {
  const { title, campaignStatus, population, hasSegments, reward } = campaign;

  // Styles object for card container
  const styles = {
    cardContainer: {
      width: "100%",
      padding: "16px",
      borderRadius: "10px",
      background: "rgba(255, 255, 255, 1)",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      minHeight: "220px",
    },
  };

  // Reusable FieldPair component
  const FieldPair = ({ label, value }) => (
    <div className="field-pair">
      <span className="field-label">{label}</span>
      <span className="field-value">{value}</span>
    </div>
  );

  return (
    <div style={styles.cardContainer} className="bonus-grid-card">
      <div className="campaign-card-container">
        <div className="campaign-details">
          {/* Campaign Status */}
          <div className="campaign-status-type">
            <span
              className={`status ${campaignStatus?.toLowerCase()}`}
              aria-label={`Campaign status: ${campaignStatus || "Unknown"}`}
            >
              {campaignStatus
                ? campaignStatus.charAt(0).toUpperCase() +
                  campaignStatus.slice(1).toLowerCase()
                : "-"}
            </span>
          </div>

          {/* Campaign Title */}
          <h3
            className="campaign-name"
            aria-label={`Campaign title: ${title || "Unknown"}`}
          >
            {title || "-"}
          </h3>

          {/* Additional Fields */}
          <div className="additional-fields-container">
            <FieldPair label="Population" value={population || "-"} />
            <FieldPair label="Reward" value={reward || "N/A"} />
            <FieldPair label="Segment" value={hasSegments ? "Yes" : "No"} />
          </div>

          {/* Action Buttons */}
          <div className="button-group-container">
            <ButtonGroupDynamic
              items={actionButtons}
              spacing="1px"
              size="small"
              variant="control"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusGridCard;
