export const createLeaderboardsJsonSchema = {
  type: "object",
  title: "Create New Leaderboard",
  properties: {
    title: {
      type: "string",
      title: "Leaderboard Name",
      minLength: 1,
    },
    startsAt: {
      type: "string",
      title: "Start Date/Time",
    },
    endsAt: {
      type: "string",
      title: "End Date/Time",
    },
    activePointMinimum: {
      type: "number",
      title: "Min point get ranked",
      default: 0,
      // minLength: 1,
    },
    // minParticipationCount: {
    //   type: "number",
    //   title: "Min participation time to get ranked",
    //   default: 0,
    //   // minLength: 1,
    // },
    awardApproval: {
      title: "Approval Type",
      type: "string",
      enum: ["MANUAL", "AUTO"],
      options: [
        { id: "MANUAL", displayText: "Manual Approval" },
        { id: "AUTO", displayText: "Automatic Approval" },
      ],
      default: [{ id: "MANUAL", displayText: "Manual Approval" }],
    },
    tieBreaker: {
      title: "Tie Breaker",
      type: "string",
      options: [
        { id: "FIRST_UPDATED", displayText: "First Updated" },
        { id: "FIRST_ENTERED", displayText: "First Entered" },
        { id: "NONE", displayText: "None" },
      ],
      header: "",
      enum: ["FIRST_UPDATED", "FIRST_ENTERED", "NONE"],
      default: [{ id: "FIRST_UPDATED", displayText: "First Updated" }],
    },
    rankOrder: {
      title: "Rank Order",
      type: "string",
      enum: ["ASC", "DESC"],
      options: [
        { id: "ASC", displayText: "Ascending" },
        { id: "DESC", displayText: "Descending" },
      ],
      default: [{ id: "ASC", displayText: "Ascending" }],
    },
  },
  required: [
    "title",
    "startsAt",
    "endsAt",
    "activePointMinimum",
    // "minParticipationCount",
    "awardApproval",
    "rankOrder",
    "tieBreaker",
  ],
};

export const createLeaderboardsUISchema = {
  type: "SimpleGroup",
  elements: [
    {
      type: "VerticalLayout",
      elements: [
        { type: "Control", scope: "#/properties/title" },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/startsAt",
                  component: "dateTimePicker",
                  minimum: "currentDateTime",
                  label: "Start Date/Time",
                },

                {
                  component: "dateTimePicker",
                  label: "End Date/Time",
                  type: "Control",
                  minimum: "currentDateTime",
                  scope: "#/properties/endsAt",
                  rule: {
                    condition: {
                      schema: {
                        gt: "startsAt",
                      },
                    },
                    effect: "VALIDATE",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/activePointMinimum",
                  component: "textField",
                },
                // {
                //   type: "Control",
                //   scope: "#/properties/minParticipationCount",
                //   component: "textField",
                // },
              ],
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              options: {
                labelMapping: {
                  MANUAL: "Manual Approval",
                  AUTO: "Automatic Approval",
                },
              },
              component: "select",
              label: "Approval Type",
              type: "Control",
              scope: "#/properties/awardApproval",
            },
            {
              options: {
                labelMapping: {
                  FIRST_UPDATED: "First Updated",
                  FIRST_ENTERED: "First Entered",
                  NONE: "None",
                },
              },
              component: "select",
              label: "Tie Breaker",
              type: "Control",
              selection: "single",
              scope: "#/properties/tieBreaker",
            },
          ],
        },
        {
          type: "HorizontalLayout",
          elements: [
            {
              options: {
                labelMapping: {
                  ASC: "Ascending",
                  DESC: "Descending",
                },
              },
              component: "select",
              label: "Rank Order",
              type: "Control",
              scope: "#/properties/rankOrder",
            },
          ],
        },
      ],
    },
  ],
};

/**
 * Adds a key-value pair to an element within the createLeaderboardsUISchema based on the provided scope string.
 *
 * @param {string} scopeString - The scope string to identify the target element.
 * @param {string} key - The key to be added to the target element.
 * @param {*} value - The value to be associated with the key in the target element.
 */
export const addKeyValueToElement = (scopeString, key, value) => {
  const addKeyValue = (elements) => {
    elements.forEach((element) => {
      if (element.scope === scopeString) {
        element[key] = value;
      }
      if (element.elements) {
        addKeyValue(element.elements);
      }
    });
  };

  addKeyValue(createLeaderboardsUISchema.elements);
};
