import { useQuery } from "@apollo/client";
import { Modal } from "pepsico-ds";
import { useEffect, useState } from "react";
import { listSegments } from "../../graphql/queries/listSegments";
import { getDateWithFormat } from "../../utils/commons";
import "./survey.scss";

function SurveyActionModal({
  surveySummaryOpen,
  toggleSummaryModal,
  handleActionButtons,
  surveyData,
  currentProgramTimezone,
}) {
  const { data: segmentsData } = useQuery(listSegments);
  const [mappedSegments, setMappedSegments] = useState([]);

  useEffect(() => {
    if (segmentsData && surveyData?.segments?.length) {
      const segments = surveyData.segments.map((segmentId) => {
        const segment = segmentsData.listSegments.find(
          (seg) => seg.id === segmentId
        );
        return segment ? segment.name : segmentId;
      });
      setMappedSegments(segments);
    }
  }, [segmentsData, surveyData]);

  return (
    <Modal
      className="survey-summary"
      showModal={surveySummaryOpen}
      onCloseModal={toggleSummaryModal}
      title={`${surveyData?.contentData?.type === "QUIZ" ? "Quiz" : "Survey"} Details`}
      primaryButtonProps={{
        size: "medium",
        text: "Edit",
        variant: "primary",
        disabled: surveyData?.status === "COMPLETED",
        onClick: () => {
          console.log("surveyData", surveyData);
          toggleSummaryModal();
          handleActionButtons("edit", surveyData);
        },
      }}
      showSecondaryButton={false}
      showTertiaryButton={false}
      showLink={false}
    >
      <div className="survey-summary">
        <div className="summary-container-modal">
          <div className="section-survey">
            <h2>Basic Setup</h2>
            <div
              className="cover-image-survey-summary"
              style={{ maxHeight: "250px", overflow: "hidden" }}
            >
              <img
                style={{ /* width:"100%",  */ maxHeight: "inherit" }}
                src={surveyData?.contentData?.image_url}
                alt="Cover Basic Setup"
              />
            </div>
            <div className="content-survey">
              <div className="sub-content-survey">ID:</div>
              <span className="sub-content-survey">{surveyData?.id}</span>
            </div>
            <div className="content-survey">
              <div className="sub-content-survey">Type:</div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.type}
              </span>
              <div className="sub-content-survey">Title:</div>
              <span className="sub-content-survey">{surveyData?.title}</span>
              <div className="sub-content-survey">Campaign short name:</div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.short_title}
              </span>
            </div>
            <div className="content-survey">
              <div className="sub-content-survey">Description:</div>

              <span className="sub-content-survey-description">
                {surveyData?.description}
              </span>
            </div>
            <div className="content-survey">
              <div className="sub-content-survey">Start Date:</div>
              <span className="sub-content-survey">
                {surveyData?.activeStartDate
                  ? getDateWithFormat(
                      surveyData?.activeStartDate,
                      currentProgramTimezone
                    )
                  : "N/A"}
              </span>
              <div className="sub-content-survey">End Date:</div>
              <span className="sub-content-survey">
                {surveyData?.activeEndDate
                  ? getDateWithFormat(
                      surveyData?.activeEndDate,
                      currentProgramTimezone
                    )
                  : "N/A"}
              </span>
            </div>
          </div>
          <div className="section-survey">
            <h2>Specifications</h2>
            <div className="content-survey">
              <div className="sub-content-survey">Wallet Button Text:</div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.wallet_button_text}
              </span>
              <div className="sub-content-survey">URL:</div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.source_url}
              </span>
              <div className="sub-content-survey">Widget Title:</div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.widget_name}
              </span>
            </div>
            <div className="content-survey">
              <div className="sub-content-survey">Show in Widget:</div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.show_in_widget === true ? "On" : "Off"}
              </span>
              <div className="sub-content-survey">Show in Wallet page:</div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.show_in_wallet === true ? "On" : "Off"}
              </span>
              <div className="sub-content-survey">
                Add the badge as &apos;hediyeli&apos;:
              </div>
              <span className="sub-content-survey">
                {surveyData?.contentData?.show_heidyeli_badge === true
                  ? "On"
                  : "Off"}
              </span>
            </div>
            {surveyData?.contentData?.type === "QUIZ" && (
              <div className="content-survey">
                <div className="sub-content-survey">
                  Allow more than one participation:
                </div>
                <span className="sub-content-survey">
                  {surveyData?.contentData?.show_more_than_one_participant === true
                    ? "On"
                    : "Off"}
                </span>
              </div>
            )}
          </div>
          {/* Survey to be added in upcoming sprints, do not remove. */}
          <div className="section-survey">
            <h2>Segments</h2>
            <div className="sub-content-survey">Segments:</div>
            <span className="sub-content-survey">
              {mappedSegments && mappedSegments?.length > 0
                ? mappedSegments.join(", ")
                : "No"}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SurveyActionModal;
