export const getCampaignActionButtons = ({
  campaign,
  handleEditCampaign,
  handleSummaryClick,
  handleCopyClick,
  handleDeleteClick,
  hasAccess,
}) => {
  const actionButtons = [
    {
      icon: "visibility",
      onClick: () => {
        handleSummaryClick(campaign);
      },
    },
  ];

  hasAccess &&
    actionButtons.push({
      icon: "content_copy",
      onClick: () => handleCopyClick(campaign),
    });

  const editableStatuses = ["draft", "published", "active", "inactive"];
  const deletableStatuses = ["draft", "published"];

  editableStatuses.includes(campaign?.campaignStatus?.toLowerCase()) &&
    hasAccess &&
    actionButtons.push({
      icon: "edit",
      onClick: () => handleEditCampaign(campaign),
    });

  deletableStatuses.includes(campaign?.campaignStatus?.toLowerCase()) &&
    hasAccess &&
    actionButtons.push({
      icon: "delete",
      onClick: () => handleDeleteClick(campaign.id),
    });
  return actionButtons;
};
