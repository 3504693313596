export const leaderboardDataMap = {
  FIRST_UPDATED: "First Updated",
  FIRST_ENTERED: "First Entered",
  AUTO: "Automatic",
  MANUAL: "Manual",
  DESC: "Descending",
  ASC: "Ascending",
};

export const leaderboardEditDataMap = (leaderboard) => {
  return {
    id: leaderboard.id,
    programId: leaderboard.programId,
    title: leaderboard.title,
    startsAt: leaderboard.startsAt,
    endsAt: leaderboard.endsAt,
    status: leaderboard.status,
    rankOrder: [
      {
        id: leaderboard.rankOrder,
        displayText: leaderboard.rankOrder === "ASC" ? "Ascending" : "Descending",
      },
    ],
    tieBreaker: [
      {
        id: leaderboard.tieBreaker,
        displayText:
          leaderboard.tieBreaker === "NONE"
            ? "None"
            : leaderboard.tieBreaker === "FIRST_UPDATED"
              ? "First Updated"
              : "First Entered",
      },
    ],
    activePointMinimum: leaderboard.activePointMinimum,
    awardApproval: [
      {
        id: leaderboard.awardApproval,
        displayText:
          leaderboard.awardApproval === "MANUAL"
            ? "Manual Approval"
            : "Automatic Approval",
      },
    ],
  };
};
