import { gql } from "@apollo/client";

export const getLeaderboardsList = gql`
  query ListLeaderboards(
    $page: Int
    $size: Int
    $status: LeaderboardsStatus
    $title: String
    $startsAt: OffsetDateTime
    $endsAt: OffsetDateTime
  ) {
    listLeaderboards(
      page: $page
      size: $size
      status: $status
      title: $title
      startsAt: $startsAt
      endsAt: $endsAt
    ) {
      totalCount
      items {
        id
        programId
        title
        startsAt
        endsAt
        status
        rankOrder
        tieBreaker
        activePointMinimum
        minParticipationCount
        awardApproval
        createdAt
        updatedAt
      }
    }
  }
`;

export const getLeaderboardById = gql`
  query GetLeaderboard($id: ID!) {
    getLeaderboard(id: $id) {
      id
      programId
      title
      startsAt
      endsAt
      status
      rankOrder
      tieBreaker
      activePointMinimum
      minParticipationCount
      awardApproval
      createdAt
      updatedAt
    }
  }
`;
