import CloseIcon from "@mui/icons-material/Close";
import { Chip, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Icon,
  TextInput,
  ToastContainer,
} from "pepsico-ds";

import { useEffect, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../../management/WidgetPages/EditWidget/StrictModeDroppable";
import "./_widget-content-setup.scss";

function UserParticipationHistoryWidgetModal({
  data,
  widgetColumnDataItems,
  onChange,
  showModal,
  title,
  columnHeaderText,
  toggleCreateBannerModal,
  isEditMode,
  dateFormatList,
  defaultDateFormat = "DD/MM/YYYY",
  isWinnerWidget = false,
}) {
  const [addedColumns, setAddedColumns] = useState(data ?? []);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(-1);
  const [errorNotification, setErrorNotification] = useState([]);
  const [selectedColumnFromSelector, setSelectedColumnFromSelector] =
    useState(undefined);
  const [listOfPossibleColumns, setListOfPossibleColumns] = useState([]);
  //new state variables
  const [enableSaveButton] = useState(true);

  useEffect(() => {
    const updatedListOfPossibleColumns = widgetColumnDataItems?.map(
      (column, index) => {
        var addedColumn = addedColumns?.find(
          (addedColumn) => addedColumn?.name === column?.name
        );
        if (addedColumn) {
          return {
            ...column,
            displayText: column.name,
            remainingUsage: 0,
          };
        } else {
          return {
            ...column,
            id: index,
            displayText: column.name,
            remainingUsage: 1,
          };
        }
      }
    );
    const filterOutMaxAllowedZeros =
      updatedListOfPossibleColumns?.filter((column) => column.remainingUsage > 0) ||
      [];
    setListOfPossibleColumns(filterOutMaxAllowedZeros);
  }, [addedColumns, widgetColumnDataItems, data]);

  useEffect(() => {
    // Edit mode and all columns are added
    if (data && data?.length > 0 && isEditMode) {
      setSelectedColumnIndex(0);
    }
  }, [isEditMode, data]);

  const handleToggleModal = () => {
    toggleCreateBannerModal();
  };

  const handleAddColumnLocal = () => {
    if (
      selectedColumnFromSelector &&
      selectedColumnFromSelector.length > 0 &&
      selectedColumnFromSelector[0].name !== ""
    ) {
      const newColumnDefinition = JSON.parse(
        JSON.stringify(selectedColumnFromSelector[0])
      );
      delete newColumnDefinition.remainingUsage;
      delete newColumnDefinition.displayText;
      setAddedColumns([
        ...addedColumns,
        { ...newColumnDefinition, columnNameData: "" },
      ]);
      setSelectedColumnFromSelector([
        {
          displayText: "",
          name: "",
        },
      ]);
      const currentCountOfColumns = addedColumns.length;
      setSelectedColumnIndex(currentCountOfColumns);
    }
  };

  const onClickOfTag = (index) => {
    setSelectedColumnIndex(index);
  };

  const onRemoveColumn = (index) => {
    const newColumns = addedColumns.filter((column, i) => i !== index);
    setAddedColumns(newColumns);
    setSelectedColumnIndex(0);
  };

  const saveWidgetConfiguration = () => {
    setErrorNotification([]);

    //validate the form data before save it
    const errorMessages = [];

    addedColumns.forEach((column) => {
      if (!column.columnNameData || column.columnNameData.trim() === "") {
        errorMessages.push(`Please fill in the header text for ${column.name}.\n`);
      }
    });
    if (isWinnerWidget) {
      var winnersName = addedColumns?.find(
        (addedColumn) => addedColumn?.slug === "winners-name"
      );
      // check if the winners name column is selected
      if (!winnersName) {
        errorMessages.push(`Please select the Winners Name column.\n`);
      }

      var winningDate = addedColumns?.find(
        (addedColumn) => addedColumn?.slug === "date-of-winning"
      );
      // set default date format if not selected
      if (winningDate && !winningDate.dateFormat) {
        winningDate.dateFormat = defaultDateFormat;
      }
    }

    if (errorMessages.length > 0) {
      setErrorNotification([
        {
          type: "error",
          id: "1",
          text: errorMessages,
        },
      ]);
      return;
    }

    onChange(addedColumns);

    handleToggleModal();
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    // Update addedColumns state
    setAddedColumns((prevAddedColumns) => {
      const newColumns = JSON.parse(JSON.stringify(prevAddedColumns));
      newColumns[selectedColumnIndex].columnNameData = newValue;
      // If the column is 'winners-name' and undefined, set isNameAnonymized to false.
      if (
        newColumns[selectedColumnIndex].slug === "winners-name" &&
        newColumns[selectedColumnIndex].isNameAnonymized === undefined
      ) {
        newColumns[selectedColumnIndex].isNameAnonymized = false;
      }

      return newColumns;
    });
  };
  const handleCheckboxChange = (isNameAnonymized) => {
    // Update addedColumns state
    setAddedColumns((prevAddedColumns) => {
      const newColumns = JSON.parse(JSON.stringify(prevAddedColumns));
      newColumns[selectedColumnIndex].isNameAnonymized = isNameAnonymized;
      return newColumns;
    });
  };

  const getSeletedDateFormate = (columnData) => {
    let dateFormat = columnData["dateFormat"];
    if (dateFormat === undefined) {
      dateFormat = defaultDateFormat;
      columnData["dateFormat"] = defaultDateFormat;
    }

    return dateFormatList?.filter((item) => item.displayText === dateFormat);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(addedColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the state with the reordered items
    setAddedColumns(items);
  };
  const renderLanguageInputs = () => {
    return (
      <div className="language-input-container" key={selectedColumnIndex}>
        <h4 className="mt-3">
          {addedColumns[selectedColumnIndex]["name"]} Header Text
        </h4>

        <div className="mb-3" key={addedColumns[selectedColumnIndex]["name"]}>
          <TextInput
            required
            type="text"
            placeholderText={`Enter ${addedColumns[selectedColumnIndex]["name"]} header text here`}
            value={addedColumns[selectedColumnIndex]?.columnNameData ?? ""}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        {addedColumns[selectedColumnIndex]["slug"] === "winners-name" && (
          <div className="mt-3">
            <Checkbox
              checked={addedColumns[selectedColumnIndex]["isNameAnonymized"]}
              text="Anonymized Winner's name"
              onUpdate={(e) => {
                handleCheckboxChange(e);
              }}
              size="small"
            />
          </div>
        )}
        {addedColumns[selectedColumnIndex]["slug"] === "date-of-winning" && (
          <div className="mb-3">
            <Dropdown
              data-testid="column-selector"
              key={"date-of-winning-list"}
              style={{ position: "relative" }}
              childList={dateFormatList}
              label="Date formats"
              // helperText="default date format - DD/MM/YYYY"
              placeholder="Select the date's format"
              className="zIndexToDropDown column-selector"
              selection="single"
              selectedValue={
                getSeletedDateFormate(addedColumns[selectedColumnIndex]) || undefined
              }
              setSelectedValue={(dateFormat) => {
                setAddedColumns((prevAddedColumns) => {
                  const newColumns = JSON.parse(JSON.stringify(prevAddedColumns));
                  if (dateFormat && dateFormat.length > 0) {
                    newColumns[selectedColumnIndex].dateFormat =
                      dateFormat[0].displayText;
                  } else {
                    newColumns[selectedColumnIndex].dateFormat = "";
                  }
                  return newColumns;
                });
              }}
              size="medium"
            />
            <div className="mt-2">
              <span className="$font-xxsmall-regular">
                Default date format -{defaultDateFormat}
              </span>
            </div>

            <h6 className="mt-5 font-sm"> </h6>
          </div>
        )}
      </div>
    );
  };

  const renderItem = (column, index) => (
    <Draggable
      key={JSON.stringify(column)}
      //key={column.name + index}
      draggableId={JSON.stringify(column)}
      index={index}
    >
      {(provided) => (
        <div
          key={`column-key-${column?.name}`}
          className="mr-2 mt-2 item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Chip
            key={index}
            id={`column-${column?.name}`}
            className="font-xs"
            label={column?.name}
            icon={
              <Icon
                color="ffffff"
                icon="drag_indicator"
                size="medium"
                variant="filled"
                data-testid="icon"
              />
            }
            deleteIcon={
              <CloseIcon
                sx={{
                  color:
                    selectedColumnIndex === index
                      ? "white !important"
                      : "#3A3A3A !important",
                }}
              />
            }
            sx={{
              borderRadius: "8px",
              backgroundColor: selectedColumnIndex === index ? "#006A42" : "#B3DACB",
              color: selectedColumnIndex === index ? "white" : "",
              cursor: "pointer",
              "&:hover": {
                backgroundColor:
                  selectedColumnIndex === index ? "#006A42" : "#B3DACB",
                color: selectedColumnIndex === index ? "white" : "",
              },
            }}
            onClick={() => onClickOfTag(index)}
            onDelete={() => onRemoveColumn(index)}
          />
        </div>
      )}
    </Draggable>
  );
  return (
    <Dialog
      open={showModal}
      onClose={() => handleToggleModal()}
      scroll="paper"
      PaperProps={{ className: "activity-setup-modal-paper" }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2 style={{ marginLeft: "18%", textAlign: "center" }}>{title}</h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={() => handleToggleModal()}
              className="cursor-pointer"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent key={title}>
        <h4 className="mb-2 mt-2">{columnHeaderText} Widget Columns</h4>
        <Divider orientation="horizontal" />
        <div className="display-flex flex-direction-row">
          <div
            className="align-items-center justify-content-start"
            style={{ flex: 1, flexWrap: "wrap" }}
          >
            <div
              className="display-flex align-items-start flex-direction-column"
              style={{ flex: listOfPossibleColumns.length ? 1 : 0 }}
            >
              {listOfPossibleColumns.length ? (
                <div className="display-flex gap-2 align-items-center mb-2 mt-2">
                  <Dropdown
                    data-testid="column-selector"
                    key={"coloum-list"}
                    style={{ position: "relative" }}
                    childList={listOfPossibleColumns}
                    label="Data items"
                    className="zIndexToDropDown column-selector"
                    selection="single"
                    selectedValue={selectedColumnFromSelector}
                    setSelectedValue={(newValue) => {
                      console.log("newValue", newValue);
                      setSelectedColumnFromSelector(newValue);
                    }}
                    size="medium"
                  />
                  <Button
                    id="addColumnButton"
                    size="medium"
                    iconLeading="add"
                    style={{ marginTop: "1.25rem", background: "#117D9F" }}
                    onClick={handleAddColumnLocal}
                  >
                    Add
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <Divider orientation="horizontal" />
              <div>
                {selectedColumnIndex >= 0 && addedColumns[selectedColumnIndex] ? (
                  renderLanguageInputs()
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {addedColumns.length > 0 && (
            <div
              style={{
                backgroundColor: "#F3F7FD",
                flex: 1,
                minHeight: "75px",
                borderRadius: "4px",
              }}
              className="p-3 ml-2 display-flex"
            >
              <div className="gap-3">
                <h4 className="mb-2">Remove and Reorder Columns</h4>

                <DragDropContext onDragEnd={onDragEnd}>
                  <div className="dragabble-wrap p-0 mt-2">
                    <StrictModeDroppable
                      droppableId="droppableList"
                      direction="vertical"
                    >
                      {(provided) => (
                        <div
                          key={provided.placeholder}
                          className="dragabble-list"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            width: "100%",
                          }}
                        >
                          {addedColumns.map(renderItem)} {provided.placeholder}
                        </div>
                      )}
                    </StrictModeDroppable>
                  </div>
                </DragDropContext>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <div className="bottom-button jdisplay-flex justify-content-end justify-content-space-between">
        <Button
          text="Cancel"
          variant="secondary"
          className="mr-3"
          onClick={handleToggleModal}
        />
        <Button
          variant="primary"
          text="Save"
          disabled={!enableSaveButton}
          onClick={saveWidgetConfiguration}
        />
      </div>
      <ToastContainer
        className="modalV2-toast-notification"
        data={errorNotification}
        removeToast={() => setErrorNotification([])}
        showActionIcon
      />
    </Dialog>
  );
}

export default UserParticipationHistoryWidgetModal;
