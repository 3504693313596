import { useQuery } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getCampaignsName } from "../../../graphql/queries/getCampaignListing";

const CampaignNameSelectComponent = (props) => {
  const { handleChange, label, path, required, visible, enabled, data } = props;

  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [hasLoadedData, setHasLoadedData] = useState(false);
  const { data: campaignsData } = useQuery(getCampaignsName, {
    variables: {
      filter: {
        //campaignStatus: "ACTIVE",
      },
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (campaignsData) {
      setCampaignOptions(campaignsData.listCampaigns);
      if (data?.length && !hasLoadedData) {
        setSelected(
          formatOptions(
            data.map(
              (item) =>
                campaignsData.listCampaigns.find((option) => option.id === item) ||
                {}
            )
          )
        );
        setHasLoadedData(true);
      }
    }
  }, [campaignsData, data, hasLoadedData]);

  const formatOptions = (options) =>
    options.map((option) => ({
      id: option.id,
      displayText: option.title,
    }));
  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const handleSelectValue = (val) => {
    setSelected(val);
    handleChange(path, formatData(val));
  };

  return visible ? (
    <div className="multiple-select-wrap peacock-dropdown-show-label">
      <Dropdown
        childList={formatOptions(campaignOptions)}
        selection="multiple"
        isRequired={required}
        label={label}
        disabled={!enabled}
        selectedValue={selected}
        setSelectedValue={(val) => handleSelectValue(val)}
      />
    </div>
  ) : null;
};

CampaignNameSelectComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  path: PropTypes.string.isRequired,
  required: PropTypes.bool,
  visible: PropTypes.bool,
  enabled: PropTypes.bool,
  data: PropTypes.array,
};

export const CampaignNameSelectControl = withJsonFormsMultiEnumProps(
  CampaignNameSelectComponent
);
