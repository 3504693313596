import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import { timezoneAbbreviationHelper } from "../../../utils/commons";
import "./dateTimePickerStyles.scss";

moment.locale("en");

const addExtraProps = ({ uischema, otherData, extraProps, timezone }) => {
  // date validations with other dates as per uischema
  const currentDateTime = moment.tz(new Date(), timezone);
  if (uischema?.minimum) {
    if (uischema?.minimum === "currentDateTime") {
      extraProps.minDateTime = currentDateTime;
    } else {
      const minDateTime = moment.tz(uischema?.minimum, timezone);
      extraProps.minDateTime = minDateTime.isValid() ? minDateTime : currentDateTime;
    }
  }

  if (uischema?.rule?.effect === "VALIDATE") {
    const schema = uischema?.rule?.condition?.schema;
    const gtDate = otherData?.[schema.gt];
    const ltDate = otherData?.[schema.lt];

    if (
      schema?.gt &&
      gtDate &&
      !isNaN(new Date(gtDate)) &&
      moment.tz(new Date(gtDate), timezone) > currentDateTime
    ) {
      const gtDateObj = new Date(new Date(gtDate).getTime() + 60000);
      extraProps.minDateTime = moment.tz(gtDateObj, timezone);
    }
    if (schema?.lt && ltDate && !isNaN(new Date(ltDate))) {
      extraProps.maxDateTime = moment.tz(new Date(ltDate), timezone);
    }
  }
};

/*
  @label: label for dateTimePicker
  @dateTime: current Date Time Value that is set.
  @setDateTime: function to set the new Date Time Value. Will always return UTC format.
  @minimum : minimum date time value that can be selected.
*/

export default function DateTimePickerWithTimeZone({
  label,
  dateTime,
  setDateTime,
  timezone,
  format = "utc_string",
  disabled,
  uischema,
  className,
  otherData,
  slotProps,
}) {
  /* convert the selected date time into UTC/UNIX format  */
  const handleAccept = (date) => {
    if (date) {
      const formatDate =
        format === "utc_string" ? date.toISOString() : date.unix() * 1000;
      setDateTime(formatDate);
    } else {
      setDateTime(null);
    }
  };

  const extraProps = {
    minDateTime: undefined,
  };

  addExtraProps({ uischema, otherData, extraProps, disabled, timezone });

  return (
    <div
      data-testid="date-time-picker-container"
      className={`display-flex my-1 flex-direction-column gap-2 ${className || ""}`}
    >
      <label
        style={{ fontSize: "12px", color: "#3A3A3A" }}
        htmlFor={`datePicker-${label}`}
      >
        {label} {timezone ? <>({timezoneAbbreviationHelper(timezone)})</> : ""}
      </label>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
        adapterLocale="en"
      >
        <DateTimePicker
          timezone={timezone}
          className="customDatePickerStyles"
          value={dateTime ? moment.tz(dateTime, timezone) : null}
          onAccept={handleAccept}
          timeSteps={{ minutes: 1 }}
          disabled={disabled}
          {...extraProps}
          slotProps={slotProps}
          closeOnSelect={false}
          format="DD/MM/YYYY hh:mm A"
          desktopModeMediaQuery="@media (min-height: 640px)"
        />
      </LocalizationProvider>
    </div>
  );
}
