import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useState } from "react";
import { getCampaignListing } from "../../../graphql/queries/getCampaignListing";

const CampaignDropdownSelectComponent = (props) => {
  const { handleChange, label, path, required, visible, enabled, data } = props;

  const { data: campaignData } = useQuery(getCampaignListing);
  const formatOptions = (options) =>
    options.map((option) => ({
      id: option.id,
      displayText: option.title,
    }));

  const [selected, setSelected] = useState(
    data
      ? formatOptions(
          data.map(
            (item) =>
              campaignData.listCampaigns.find((option) => option.id === item) || {}
          )
        )
      : []
  );

  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const handleSelectValue = (val) => {
    setSelected(val);
    handleChange(path, formatData(val));
  };

  return visible ? (
    <div className="multiple-select-wrap peacock-dropdown-show-label">
      <Dropdown
        childList={formatOptions(campaignData?.listCampaigns || [])}
        selection="multiple"
        isRequired={required}
        label={label}
        disabled={!enabled}
        selectedValue={selected}
        setSelectedValue={(val) => handleSelectValue(val)}
      />
    </div>
  ) : null;
};

export const CampaignDropdownSelectControl = withJsonFormsControlProps(
  CampaignDropdownSelectComponent
);
