import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import updateRewardPartnerCodeGroup from "../graphql/mutations/updateRewardPartnerCodeGroup";

export const useUpdatePartnerCodeGroupMutation = () => {
  const [updatePartnerCodeGroupMutation, { loading }] = useMutation(
    updateRewardPartnerCodeGroup
  );

  const update = useCallback(
    async (onSuccess, onFailure, partnerCodeGroupData, id, isEditAllowed) => {
      const payload = {
        ...partnerCodeGroupData,
      };

      try {
        const response = await updatePartnerCodeGroupMutation({
          variables: {
            request: payload,
            id: id,
            isEditAllowed: isEditAllowed,
          },
        });

        if (response?.data?.updateRewardPartnerCodeGroup?.id) {
          onSuccess();
        }
      } catch (error) {
        onFailure(error);
      }
    },
    [updatePartnerCodeGroupMutation]
  );

  return [update, loading];
};
