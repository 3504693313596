import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { getCodeGroupByIds } from "../../../graphql/queries/codeGroupQueries";

const SelectedCodeGroups = ({ data }) => {
  const { data: selectedCodeGroups, loading } = useQuery(getCodeGroupByIds, {
    variables: { ids: data.code_group_ids || [] },
  });

  return (
    <div className="additional-fields-container">
      <div className="field-pair" style={{ flexBasis: "31% !important" }}>
        <span className="field-label">Code Groups: </span>
      </div>
      <div className="field-pair-two-column">
        {loading ? (
          <CircularProgress />
        ) : (
          <span className="field-value">
            {selectedCodeGroups?.loadCodeGroupsByIds?.length
              ? selectedCodeGroups?.loadCodeGroupsByIds?.map((codeGroup) => (
                  <span
                    key={codeGroup.codeGroupId}
                    style={{
                      backgroundColor:
                        codeGroup.compositeStatus === "INACTIVE"
                          ? "#dfdfdf"
                          : "inherit",
                      marginRight: "8px",
                    }}
                  >
                    {codeGroup?.basicSetup?.codeGroupName || ""}
                  </span>
                ))
              : "-"}
          </span>
        )}
      </div>
    </div>
  );
};

export default SelectedCodeGroups;
