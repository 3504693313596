import { createAjv } from "@jsonforms/core";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, Toggle } from "pepsico-ds";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GlobalConfigContext } from "../../../../context/GlobalConfigContext";
import {
  applyEditableFieldRules,
  updatePropertyConstraintInSchema,
} from "../../../../utils/commons";
import JsonFormsWrapper from "../../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import {
  partnerCodeGroupJsonSchema,
  partnerCodeGroupUISchema,
} from "../../schemas/partnerCodeGroupSchema";
import "./createPartnerCodeModal.scss";
import { usePartnerCodeGroupForm } from "./usePartnerCodeGroupForm";

function PartnerCodeGroupFormModal({
  isOpen,
  partnerCodeGroupData,
  setPartnerCodeGroupData,
  handleMutation,
  loading,
  title,
  toggleCreatePartnerCodeModal,
  isEditMode = false,
  showToggle = false,
}) {
  const ajvInstance = useMemo(() => createAjv(), []);
  const editableFields = useMemo(
    () => [
      "endDate",
      "rewardItemFileData",
      "name",
      "description",
      "codeWarningLimitPercentage",
    ],
    []
  );
  const jsonSchema = partnerCodeGroupJsonSchema;

  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleUploadFile, handleDownloadFile } = usePartnerCodeGroupForm();

  const isValidForm = ajvInstance.validate(jsonSchema, partnerCodeGroupData);
  const { programMarketConfig } = useContext(GlobalConfigContext);
  const [prevData] = useState(partnerCodeGroupData);

  const handleIsActive = useCallback(
    (isActive) => {
      setPartnerCodeGroupData((prev) => ({ ...prev, isActive }));
    },
    [setPartnerCodeGroupData]
  );

  const isReadyForActive = useMemo(() => {
    const startDate = new Date(partnerCodeGroupData?.startDate);
    const today = new Date();
    return startDate.getTime() <= today.getTime();
  }, [partnerCodeGroupData?.startDate]);

  const handleSubmitButton = useCallback(async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    let hasError = false;

    const files =
      partnerCodeGroupData?.rewardItemFileData
        ?.map((file) => file?.file)
        .filter(Boolean) || [];

    if (files.length > 0) {
      const uploadResponse = await handleUploadFile(files);

      if (!uploadResponse.status) {
        setError(uploadResponse.message);
        hasError = true;
      } else {
        uploadResponse.data.forEach((file) => {
          const fileIndex = partnerCodeGroupData.rewardItemFileData?.findIndex(
            (f) => f.name === file.name
          );
          partnerCodeGroupData.rewardItemFileData =
            partnerCodeGroupData.rewardItemFileData.filter(
              (f) => f.name !== file.name
            );
          if (fileIndex === -1) partnerCodeGroupData.rewardItemFileData.push(file);
          else partnerCodeGroupData.rewardItemFileData[fileIndex] = file;
        });
      }
    }

    if (!hasError) {
      await handleMutation(partnerCodeGroupData);
    }

    setIsSubmitting(false);
  }, [partnerCodeGroupData, handleMutation, handleUploadFile, isSubmitting]);

  useEffect(() => {
    setError(null);
  }, [partnerCodeGroupData]);
  const isAssociatedPartnerCodeGroupEditable = useMemo(
    () => programMarketConfig?.isAssociatedPartnerCodeGroupEditable || false,
    [programMarketConfig?.isAssociatedPartnerCodeGroupEditable]
  );

  const canEdit = isEditMode && isAssociatedPartnerCodeGroupEditable;

  const getUISchema = useCallback(() => {
    const updatedSchema = updatePropertyConstraintInSchema(
      partnerCodeGroupUISchema,
      "#/properties/endDate",
      "minimum",
      prevData?.endDate
    );

    return canEdit
      ? applyEditableFieldRules(updatedSchema, editableFields)
      : applyEditableFieldRules(partnerCodeGroupUISchema, []); // Pass empty array to ensure no disabled/enabled props
  }, [editableFields, canEdit, prevData?.endDate]);

  const handleFormChange = useCallback(
    (data) => {
      if (
        canEdit &&
        new Date(partnerCodeGroupData?.endDate) > new Date(data.endDate)
      ) {
        setError("End date cannot be less than the previous active end date.");
      } else {
        setPartnerCodeGroupData(data);
      }
    },
    [setPartnerCodeGroupData, canEdit, partnerCodeGroupData?.endDate]
  );

  const handleClose = () => {
    if (isSubmitting) return;
    toggleCreatePartnerCodeModal();
  };

  return (
    <Dialog
      className="partner-code-group-modal"
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="large"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs={10}>
            <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
              {title} Partner Code Group
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <div className={`code-group-mod ${isEditMode ? "edit-form" : ""}`}>
          <JsonFormsWrapper
            jsonschema={jsonSchema}
            uischema={getUISchema()}
            data={partnerCodeGroupData}
            onChange={handleFormChange}
          />
          {!isEditMode && (
            <Button
              size="small"
              variant="secondary"
              onClick={handleDownloadFile}
              className="download-sample-file"
            >
              Download sample file <DownloadIcon fontSize="small" />
            </Button>
          )}
          {error && <p className="errorMessage">{error}</p>}
        </div>
      </DialogContent>
      <DialogActions>
        {showToggle && (
          <Toggle
            label="Active"
            onUpdate={handleIsActive}
            size="medium"
            checked={partnerCodeGroupData?.isActive}
            disabled={!isReadyForActive}
          />
        )}
        <Button
          onClick={handleSubmitButton}
          disabled={isSubmitting || !isValidForm || loading || error}
        >
          {isSubmitting ? "Submitting..." : title === "Edit" ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PartnerCodeGroupFormModal;
