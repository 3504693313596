import { gql } from "@apollo/client";

export const listEngagements = gql`
  query ListEngagements($filter: EngagementFilter!) {
    listEngagements(filter: $filter) {
      total
      items {
        id
        profileId
        performanceDate
        campaignName
        profileEmail
        profilePhone
        profileLogin
        engagementType
        deviceId
        identifier
        productSkus
        programPoints
        campaignPoints
        status
        image
        vendorName
        outcomes {
          outcome
          value
        }
      }
    }
  }
`;
