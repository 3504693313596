// src/hooks/useListCampaigns.js
import { useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getCampaignKeyToTypesMap,
  getUpdatedCampaignConfiguration,
} from "../components/campaign/campaignUtils";
import { GlobalConfigContext } from "../context/GlobalConfigContext";
import { getCampaignListing } from "../graphql/queries/getCampaignListing";

export function useListCampaigns(filter, extraFilters = {}, query = null) {
  const { t } = useTranslation();
  const { campaignsConfiguration, campaignGroups } = useContext(GlobalConfigContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(20);
  const [numPages, setNumPages] = useState(0);
  const [campaignListData, setCampaignListData] = useState([]);

  const updatedCampaignConfiguration = getUpdatedCampaignConfiguration(
    campaignsConfiguration,
    campaignGroups
  );

  const campaignOptions = updatedCampaignConfiguration.map((campaign) => ({
    id: campaign.key,
    displayText: campaign.value?.label || campaign.name || campaign.key || "",
  }));

  const campaignKeyToTypesMap = getCampaignKeyToTypesMap(
    updatedCampaignConfiguration
  );

  const adjustedFilter = filter.types
    ? {
        ...filter,
        types: [
          ...new Set(
            filter.types.flatMap((type) => campaignKeyToTypesMap[type] || [type])
          ),
        ],
      }
    : filter;

  const { loading, error, data, refetch } = useQuery(
    query ? query : getCampaignListing,
    { variables: { filter: { ...adjustedFilter, ...extraFilters } } }
  );

  const filterCampaigns = (campaigns) => {
    return campaigns?.filter((e) => e?.type !== "support");
  };
  const campaignList = useMemo(
    () => filterCampaigns(data?.listCampaigns),
    [data?.listCampaigns]
  );

  const currentItemsData = useMemo(() => {
    return campaignList?.length
      ? campaignList.slice(
          (currentPage - 1) * rowsPerPage,
          currentPage * rowsPerPage
        )
      : [];
  }, [campaignList, currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    setNumPages(Math.ceil(campaignList?.length / rowsPerPage));
    setCampaignListData(currentItemsData);
  }, [campaignList?.length, currentItemsData, rowsPerPage, currentPage]);

  const handlePageChange = (newPageIndex) => {
    if (newPageIndex > 0 && newPageIndex <= numPages) {
      setCurrentPage(newPageIndex);
    }
    const currentItemsData =
      campaignList?.length &&
      campaignList?.slice(
        (newPageIndex - 1) * rowsPerPage,
        newPageIndex * rowsPerPage
      );
    setCampaignListData(currentItemsData);
  };

  const ptrFilterJsonSchema = {
    title: "PTR Campaign List Filter",
    type: "object",
    properties: {
      campaignStatus: {
        label: "Status",
        items: {
          type: "string",
          options: [
            { id: "draft", displayText: "Draft" },
            { id: "active", displayText: "Active" },
            { id: "inactive", displayText: "Inactive" },
            { id: "published", displayText: "Published" },
            { id: "completed", displayText: "Completed" },
          ],
        },
      },
      types: { label: "Campaign Type", items: { options: campaignOptions } },
      hasSegments: {
        label: "Segment-specific",
        items: {
          type: "string",
          options: [
            { id: true, displayText: "Yes" },
            { id: false, displayText: "No" },
          ],
        },
      },
      activeStartDate: { type: "string" },
      activeEndDate: { type: "string" },
    },
  };

  const filterJsonSchema = {
    title: "Campaign List Filter",
    type: "object",
    properties: {
      campaignStatus: {
        label: "Status",
        items: {
          type: "string",
          options: [
            { id: "draft", displayText: "Draft" },
            { id: "active", displayText: "Active" },
            { id: "inactive", displayText: "Inactive" },
            { id: "published", displayText: "Published" },
            { id: "completed", displayText: "Completed" },
          ],
        },
      },
      types: {
        label: "Campaign Type",
        items: { type: "string", options: campaignOptions },
      },
      hasSegments: {
        label: "Segment-specific",
        items: {
          type: "string",
          options: [
            { id: true, displayText: "Yes" },
            { id: false, displayText: "No" },
          ],
        },
      },
      activeStartDate: { type: "string" },
      activeEndDate: { type: "string" },
    },
  };
  const filterUiSchema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/campaignStatus",
        component: "multiSelect",
        placeholder: "Status",
      },
      {
        type: "Control",
        scope: "#/properties/types",
        component: "multiSelect",
        placeholder: "Type",
        selection: "multiple",
      },
      {
        type: "Control",
        scope: "#/properties/hasSegments",
        component: "multiSelect",
        placeholder: "Segments",
        selection: "single",
      },
      {
        type: "Control",
        scope: "#/properties/activeStartDate",
        component: "dateTimePicker",
        label: "Start date",
      },
      {
        type: "Control",
        scope: "#/properties/activeEndDate",
        component: "dateTimePicker",
        label: "End Date",
        rule: {
          condition: { schema: { gt: "activeStartDate" } },
          effect: "VALIDATE",
        },
      },
    ],
  };

  const filterBonusUiSchema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/campaignStatus",
        component: "multiSelect",
        placeholder: "Status",
      },
    ],
  };

  const activeCampaignFilterJsonSchema = {
    type: "object",
    properties: {
      title: { type: "string", title: "Title" },
      activeStartDate: { type: "string", title: "Start Date" },
      activeEndDate: { type: "string", title: "End Date" },
      types: { label: "Campaign Type", items: { options: campaignOptions } },
    },
  };

  const activeCampaignFilterUiSchema = {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/types",
        component: "multiSelect",
        placeholder: t("CustomerSupport:TypeLabel"),
        selection: "multiple",
      },
      {
        type: "Control",
        scope: "#/properties/activeStartDate",
        component: "dateTimePicker",
        className: "zIndex-10000",
        label: t("CustomerSupport:StartDateLabel"),
      },
      {
        type: "Control",
        scope: "#/properties/activeEndDate",
        component: "dateTimePicker",
        className: "zIndex-10000",
        label: t("CustomerSupport:EndDateLabel"),
        rule: {
          condition: { schema: { gt: "activeStartDate" } },
          effect: "VALIDATE",
        },
      },
    ],
  };

  return {
    loading,
    error,
    allCampaigns: data?.listCampaigns ? data?.listCampaigns : [],
    campaigns: data?.listCampaigns && campaignListData ? campaignListData : [],
    relatedCampaigns: data?.listCampaigns ? data?.listCampaigns : [],
    refetch,
    filterJsonSchema,
    ptrFilterJsonSchema,
    filterUiSchema,
    filterBonusUiSchema,
    activeCampaignFilterUiSchema,
    activeCampaignFilterJsonSchema,
    currentPage,
    handlePageChange,
    numPages,
  };
}
