import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { getCampaignActionButtons } from "../CampaignListing/CampaignActionButtons";
import "../campaignListView.scss";

const columnStyles = {
  status: { width: "9%" },
  title: { width: "17%" },
  reward: { width: "16%" },
  population: { width: "10%" },
  segment: { width: "6%" },
  actions: { width: "14%" },
};

const BonusListView = ({
  campaignData,
  handleEditCampaign,
  handleCopyClick,
  handleSummaryClick,
  handleDeleteClick,
  hasAccess,
}) => {
  return (
    <div className="campaign-list-table-wrapper" style={{ width: "100%" }}>
      <table>
        <thead>
          <tr>
            <th style={{ ...columnStyles.status }}>Status</th>
            <th style={{ ...columnStyles.title }}>Bonus Name</th>
            <th style={{ ...columnStyles.reward }}>Reward</th>
            <th style={{ ...columnStyles.population }}>Population</th>
            <th style={{ ...columnStyles.segment }}>Segments</th>
            <th style={{ ...columnStyles.actions }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {campaignData.map((campaign, index) => {
            const actionButtons = getCampaignActionButtons({
              status: campaign?.campaignStatus?.toLowerCase(),
              campaign,
              handleEditCampaign,
              handleCopyClick,
              handleSummaryClick,
              handleDeleteClick,
              hasAccess,
            });
            return (
              <tr key={index}>
                <td style={{ ...columnStyles.status }}>
                  <div
                    className={`status ${campaign?.campaignStatus?.toLowerCase()}`}
                  >
                    {campaign?.campaignStatus?.charAt(0).toUpperCase() +
                      campaign?.campaignStatus?.slice(1).toLowerCase()}
                  </div>
                </td>
                <td
                  className={`${/\s/.test(campaign?.title) ? "" : "no-space-title"}`}
                  style={{ ...columnStyles.title }}
                >
                  {campaign?.title || "-"}
                </td>
                <td style={{ ...columnStyles.reward }}>{campaign?.reward || "-"}</td>
                <td style={{ ...columnStyles.population }}>
                  {campaign?.population || "-"}
                </td>
                <td style={{ ...columnStyles.segment }}>
                  {campaign?.segments?.length > 0 ? "Yes" : "No"}
                </td>

                <td style={{ ...columnStyles.actions }}>
                  <ButtonGroupDynamic
                    items={actionButtons}
                    spacing="0px"
                    size="small"
                    variant="control"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BonusListView;
