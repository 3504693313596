import { gql } from "@apollo/client";

const updateRewardPartnerCodeGroup = gql`
  mutation UpdateRewardPartnerCodeGroup(
    $id: String!
    $request: PartnerCodeGroupUpdate!
    $isEditAllowed: Boolean
  ) {
    updateRewardPartnerCodeGroup(
      id: $id
      request: $request
      isEditAllowed: $isEditAllowed
    ) {
      id
      name
      description
      partnerId
      codeWarningLimitPercentage
      isActive
      startDate
      endDate
      rewardItemFileData {
        id
        name
        size
        type
        url
        metadata {
          description
          tags
          uploadedBy
          uploadedAt
          containerName
        }
      }
    }
  }
`;

export default updateRewardPartnerCodeGroup;
