import { Card, Tag } from "pepsico-ds";
import ImagePreview from "../common/ImagePreview";
import JsonViewer from "../common/JsonViewer";
import "./performanceDetails.scss";

const PerformanceDetailCard = ({ label, value }) => {
  const shouldRenderTag = ["status", "source", "tags"].includes(label.toLowerCase());
  const shouldRenderImage = label.toLowerCase().includes("image");

  const isArrayValue = Array.isArray(value);

  const getTagColor = (val) => {
    const colorMap = {
      approved: "Green-150",
      pending: "Yellow-150",
      rejected: "Red-150",
    };

    return colorMap[val] || "Gray-100";
  };

  const renderTags = () => {
    if (isArrayValue) {
      return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {value.map((val, index) => (
            <Tag
              key={`${label}-${index}`}
              text={val}
              color={getTagColor(val?.toLowerCase())}
              isCopyable={false}
            />
          ))}
        </div>
      );
    }

    return (
      <Tag
        text={value}
        color={getTagColor(value?.toLowerCase())}
        isCopyable={false}
      />
    );
  };

  const renderContent = () => {
    if (shouldRenderImage) {
      return <ImagePreview imageSrc={value} alt={label} />;
    } else if (shouldRenderTag) {
      return renderTags();
    } else if (label.toLowerCase() === "json") {
      return <JsonViewer data={value} />;
    } else {
      return (
        <h5 style={{ fontWeight: "normal" }}>
          {isArrayValue ? value.join(", ") : value}
        </h5>
      );
    }
  };

  return (
    <Card
      border
      style={{
        backgroundColor: "#EFF5FC",
        padding: shouldRenderImage ? "0px" : "15px",
      }}
    >
      {!shouldRenderImage && <h5>{label}</h5>}
      {renderContent()}
    </Card>
  );
};

export default PerformanceDetailCard;
