import { useQuery } from "@apollo/client";
import { LoadingSpinner } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/common/table/Table";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { getCampaignListing } from "../../../graphql/queries/getCampaignListing";
import { useTranslatedCampaigns } from "../../../hooks/useTranslatedCampaigns";
import { getDateWithFormat, removeEmptyValues } from "../../../utils/commons";
import CampaignFilter from "./CampaignFilter";

export const RelatedCampaign = ({ codeGroupId }) => {
  const { t } = useTranslation();
  const campaignTypeLabelMap = useTranslatedCampaigns();
  const [relatedCampaigns, setRelatedCampaigns] = useState([]);
  const [filters, setFilters] = useState({
    codeGroups: [codeGroupId],
  });
  const { programTimezone } = useContext(GlobalConfigContext);

  const { refetch: refetchCampaign, loading } = useQuery(getCampaignListing, {
    skip: !codeGroupId,
    variables: { filter: filters },
    onCompleted: (campaignData) => {
      formatData(campaignData?.listCampaigns);
    },
    onError: (err) => {
      console.error("Error fetching campaigns:", err);
    },
  });

  const formatData = (campaignList) => {
    const updatedCampaigns = campaignList.map((campaign) => ({
      ...campaign,
      type: formatCampaignType(campaign.type),
      activeStartDate: getDateWithFormat(campaign.activeStartDate, programTimezone),
      activeEndDate: getDateWithFormat(campaign.activeEndDate, programTimezone),
    }));
    setRelatedCampaigns(updatedCampaigns);
  };

  // Handle filter changes
  const handleFilterChange = (selectedFilter) => {
    const updatedFilters = removeEmptyValues(selectedFilter);
    setFilters(updatedFilters);
    refetchCampaign({ filter: updatedFilters }); // Refetch with new filters
  };

  const formatCampaignType = (str) => {
    return campaignTypeLabelMap[str] || str;
  };

  useEffect(() => {
    if (codeGroupId?.length > 0) {
      refetchCampaign();
    }
  }, [codeGroupId, refetchCampaign]);

  const headers = [
    { header: t("CustomerSupport:CampaignNameColumn"), columnKey: "title" },
    {
      header: t("CustomerSupport:CampaignTitleShortColumn"),
      columnKey: "titleShort",
    },
    {
      header: t("CustomerSupport:CampaignStartDateColumn"),
      columnKey: "activeStartDate",
    },
    {
      header: t("CustomerSupport:CampaignEndDateColumn"),
      columnKey: "activeEndDate",
    },
    { header: t("CustomerSupport:CampaignTypeColumn"), columnKey: "type" },
  ];

  return (
    <div className="list-container">
      <div>
        <CampaignFilter handleFilterChange={handleFilterChange} filters={filters} />
      </div>
      {loading ? (
        <LoadingSpinner
          size={{ height: "30px", width: "30px" }}
          style={{ margin: "auto" }}
        />
      ) : (
        <Table
          headerData={headers}
          rowData={relatedCampaigns}
          noDataLabel={t("CustomerSupport:NoRelatedCampaignsFoundLabel")}
          className="list-table"
        />
      )}
    </div>
  );
};
