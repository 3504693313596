export const CODE_GROUP_LIFE_CYCLE_STATUS = {
  DRAFT: "DRAFT",
  QUEUED: "QUEUED",
  IN_PROGRESS: "IN_PROGRESS",
  GENERATED: "GENERATED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  EXPIRED: "EXPIRED",
};

export const CODE_GROUP_ADMINISTRATION_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const CODE_GROUP_CREATION_TYPE = {
  PRE_GENERATED: "PRE_GENERATED",
};

export const PRODUCER_COMPANIES = {
  HAS: "HAS",
  BERICAP: "BERICAP",
  PROPAK: "PROPAK",
  BALL: "BALL",
  CROWN: "CROWN",
};

export const PERFORMANCES_ACTIVITY_TYPES = [
  { key: "recepit-scan", value: "RECEIPT SCAN" },
  { key: "package-scan", value: "PACKAGE SCAN" },
  { key: "code-scan", value: "CODE SCAN" },
  { key: "survey", value: "SURVEY" },
];

export const PERFORMANCES_LIFE_CYCLE_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};
