import PropTypes from "prop-types";
import { paths } from "../../routes/paths";
import { getDateWithFormat } from "../../utils/commons";
import Table from "../common/table/Table";
import "./performancesTable.scss";
import { getEngagementTypeSpecificTableData } from "./utils";

const PerformancesTable = ({
  performancesData,
  currentProgramTimezone,
  engagementType,
  onTap = () => {},
}) => {
  const handleClick = (e, item) => {
    e.preventDefault();
    onTap(item);
  };

  const headerData = [
    {
      columnKey: "id",
      header: "ID",
    },
    {
      columnKey: "user",
      header: "User",
    },
    {
      columnKey: "profileId",
      header: "Profile Id",
    },
    {
      columnKey: "startDate",
      header: "Start Date",
    },
    {
      columnKey: "campaign",
      header: "Campaign",
    },
    {
      columnKey: "outcomeName",
      header: "Outcome Name",
    },
    {
      columnKey: "outcomeQuantity",
      header: "Outcome Quantity",
    },
    {
      columnKey: "activityType",
      header: "Activity Type",
    },
    {
      columnKey: "status",
      header: "Status",
      itemClassName: "performances-widget-status-type",
    },
  ];

  const rowData = performancesData.map((item) => {
    const engagementID = item.id || "-";

    const compositeStatus = item.status || "-";
    const compositeStatusClass = compositeStatus.toLowerCase().replace(/\s/g, "-");

    return {
      id: (
        <a
          className="performances-widget-id"
          href={`${paths.performances_list}/${paths.performance_details}/${engagementID}`}
          rel="noopener noreferrer"
          onClick={(e) => handleClick(e, item)}
        >
          {engagementID}
        </a>
      ),
      user: item.profileEmail || "-",
      profileId: item.profileId || "-",
      startDate: item.performanceDate
        ? getDateWithFormat(item.performanceDate, currentProgramTimezone)
        : "-",
      campaign: item.campaignName || "-",
      outcomeName: item.outcomes.outcome || "-",
      outcomeQuantity:
        typeof item.outcomes.value === "number" ? item.outcomes.value : "-",
      activityType: item.engagementType ?? "-",
      status: (
        <span className={`performances-lifecycle-status ${compositeStatusClass}`}>
          {compositeStatus}
        </span>
      ),
    };
  });

  const typeSpecificTableData =
    engagementType &&
    getEngagementTypeSpecificTableData(
      engagementType,
      performancesData,
      handleClick,
      currentProgramTimezone
    );

  const tableData = {
    headerData: typeSpecificTableData?.headerData || headerData,
    rowData: typeSpecificTableData?.rowData || rowData,
  };

  return (
    <div className="performances-container">
      <div className="performances-list-container">
        <Table
          headerData={tableData.headerData}
          rowData={tableData.rowData}
          className="performances-list-table"
        />
      </div>
    </div>
  );
};

PerformancesTable.propTypes = {
  performancesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      profileEmail: PropTypes.string,
      performanceDate: PropTypes.string,
      campaignName: PropTypes.string,
      outcomes: PropTypes.shape({
        outcome: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      engagementType: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  currentProgramTimezone: PropTypes.string.isRequired,
  engagementType: PropTypes.string,
  onTap: PropTypes.func,
};

export default PerformancesTable;
