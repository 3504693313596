import { useQuery } from "@apollo/client";
import { Button, LoadingSpinner } from "pepsico-ds";
import { useEffect, useState } from "react";
import ConfirmationModal from "../../components/performances/ConfirmationModal";
import { loadEngagementById } from "../../graphql/queries/loadEngagementById";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import "./performanceDetails.scss";
import PerformanceDetailsSection from "./PerformanceDetailsSection";
// Adjust the path as needed

const sectionTitle = (engagementType) => {
  const type = engagementType?.toLowerCase() || "";

  if (type.includes("package")) return "Package scan details";
  if (type.includes("code")) return "Code scan details";
  if (type.includes("receipt")) return "Receipt scan details";
  if (type.includes("survey")) return "Survey details";

  return "Details";
};

//TODO remove mock data and replace with actual data
const formatDataAsSections = (data) => {
  const filterValidEntries = (entries) =>
    entries.filter((entry) => entry.value !== null && entry.value !== undefined);

  return [
    {
      title: "Basic details",
      details: filterValidEntries([
        { key: "id", label: "ID", value: data.id },
        { key: "userId", label: "User ID", value: data.profileEmail },
        { key: "profileUuid", label: "Profile UUID", value: data.profileId },
        {
          key: "performanceDate",
          label: "Performance Date (UTC-6)",
          value: data.performanceDate,
        },
        { key: "campaignName", label: "Campaign Name", value: data.campaignName },
        { key: "activityType", label: "Activity Type", value: data.engagementType },
        { key: "status", label: "Status", value: data.status },
        {
          key: "outcomeName",
          label: "Outcome Name",
          value: data.outcomes?.outcome,
        },
        {
          key: "outcomeQuantity",
          label: "Outcome Quantity",
          value: data.outcomes?.value,
        },
      ]),
      status: data.status,
    },
    {
      title: sectionTitle(data.engagementType),
      details: filterValidEntries([
        {
          key: "documentId",
          label: "Document ID",
          value: "o3du09fm-48940-409dk0l-039le",
        },
        {
          key: "scanDate",
          label: "Scan Date (UTC-6)",
          value: "2025-01-12 17:30:01",
        },
        { key: "sku", label: "SKU", value: data.productSkus },
        { key: "freshByDate", label: "Fresh-By Date", value: "2025-01-12" },
        { key: "code", label: "Code", value: "M4C89C4D30L30940" },
        { key: "image", label: "Image", value: data.image },
        {
          key: "json",
          label: "JSON",
          value:
            data.metadata?.package ??
            '{\n  "null": null,\n  "boolean": true,\n  "number_int": 1,\n  "number_float": 1,\n  "string": "string",\n  "array": [\n    1,\n    2,\n    3,\n    true,\n    "string",\n    {\n      "another_object": 2.2\n    }\n  ],\n  "object": {\n    "some_nested_object": true\n  }\n}',
        },
      ]),
    },
  ];
};

const PerformanceDetailsContainer = ({
  title,
  engagementItem,
  showFooter = false,
  showDetailViewOption = false,
  onDetailViewClick = () => {},
}) => {
  const [modalType, setModalType] = useState(null);
  const [engagementData, setEngagementData] = useState(null);

  const [sections, setSections] = useState([]);

  const { data: engagementDetails, loading } = useQuery(loadEngagementById, {
    variables: {
      id: engagementItem?.id,
    },
  });

  useEffect(() => {
    if (engagementDetails) {
      setEngagementData(engagementDetails.loadEngagementById);
    }
  }, [engagementDetails]);

  useEffect(() => {
    let _engagementData = {};
    if (engagementData) {
      _engagementData = engagementData;
    }
    const newSections = formatDataAsSections(_engagementData);
    setSections(newSections);
  }, [engagementData]);

  const handleModalOpen = (type) => setModalType(type);
  const handleModalClose = () => setModalType(null);

  if (loading) {
    return (
      <LoadingSpinner
        size={{
          height: "30px",
          width: "30px",
        }}
        style={{ margin: "16px auto" }}
        data-testid="api-call-loading"
      />
    );
  }

  return (
    <div>
      <div className="performance-details-container">
        <div className="header-container">
          {title && <h4>{title}</h4>}
          {showDetailViewOption && (
            <ButtonGroupDynamic
              className="icon-button-group"
              items={[{ icon: "launch", onClick: onDetailViewClick }]}
              size="large"
            />
          )}
        </div>
        {sections.length > 0 && (
          <div className="performance-details-container">
            {sections.map((sectionProps, index) => (
              <PerformanceDetailsSection
                key={`section-${index}`}
                {...sectionProps}
              />
            ))}
          </div>
        )}
        {showFooter && (
          <div className="performance-details-container-footer">
            <Button
              size="large"
              text="Reject"
              variant="primaryInverse"
              onClick={() => handleModalOpen("reject")}
            />
            <Button
              size="large"
              text="Approve"
              variant="primary"
              onClick={() => handleModalOpen("approve")}
            />
          </div>
        )}
      </div>

      <ConfirmationModal
        showModal={modalType === "approve"}
        onClose={handleModalClose}
        title="Approve"
        text=""
        onConfirm={() => console.log("Approved")}
      />

      <ConfirmationModal
        showModal={modalType === "reject"}
        onClose={handleModalClose}
        title="Reject"
        text="Please explain the reason of the rejectionage."
        onConfirm={() => console.log("Rejected")}
      />
    </div>
  );
};

export default PerformanceDetailsContainer;
