import { InputItem } from "pepsico-ds";
import { useState } from "react";
import { isLatamCampaign } from "../../../utils/commons";
import ToastNotification from "../../common/toastNotification/ToastNotification";

const WidgetEmbedCode = ({ campaign, widgetType }) => {
  const { id, activities } = campaign;

  // State for managing toast notifications
  const [toast, setToast] = useState({
    open: false,
    title: "",
  });

  /**
   * Checks if the "metadata_required" rule is set to true for any activity in the campaign.
   * Applicable only for LATAM campaigns.
   * @param {Object} campaign - The campaign object.
   * @returns {boolean} - True if metadata is required, false otherwise.
   */
  const isMetaDataRequired = (campaign) => {
    if (!isLatamCampaign(campaign?.type)) return false;

    return activities.some((activity) =>
      activity.activityRules.some(
        (rule) => rule.ruleData?.metadata_required === true
      )
    );
  };

  /**
   * Copies the provided embed code to the clipboard and displays a toast notification.
   * @param {string} embedCode - The embed code to copy.
   */
  const copyEmbedCode = async (embedCode) => {
    try {
      await navigator.clipboard.writeText(embedCode);
      setToast({
        open: true,
        type: "success",
        title: "Widget embed code has been copied",
      });
    } catch (error) {
      setToast({
        open: true,
        type: "error",
        title: "An error occurred while copying the widget embed code",
      });
      console.error("Clipboard copy error:", error.message);
    }
  };

  // Return null if the campaign type is not LATAM
  if (!isLatamCampaign(campaign?.type)) return null;

  // Construct the embed code dynamically based on campaign properties
  const embedCode = `
    <pep-loyalty ${isMetaDataRequired(campaign) ? " metadata-required " : ""} type="${widgetType}" campaign-id="${id}"></pep-loyalty>`.trim();

  return (
    <div className="field-pair mr-1">
      <div className="widget-code">
        <InputItem
          placeholder="Code"
          size="medium"
          className="input-code"
          value={embedCode}
          onClick={() => copyEmbedCode(embedCode)}
        />
      </div>
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />
    </div>
  );
};

export default WidgetEmbedCode;
