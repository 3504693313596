import { useEffect, useState } from "react";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { historyWidgetColumnDataItems } from "./history-widget-data-items";

import WidgetContent from "../WidgetContentComponent";
const UserParticipationHistoryWidgetComponent = ({
  handleChange,
  label,
  data,
  path,
  required,
  schema,
}) => {
  const [widgetColumnDataItems, setWidgetColumnDataItems] = useState([]);
  useEffect(() => {
    // historyWidgetColumnDataItems is the default data items
    setWidgetColumnDataItems(
      schema?.dataItems?.length > 0
        ? schema?.dataItems
        : historyWidgetColumnDataItems
    );
  }, [schema?.dataItems]);

  const handleOnSave = (updated) => {
    handleChange(path, updated);
  };

  const handleDelete = () => {
    handleChange(path, []);
  };

  const title = label ?? "User Participation History Widget";

  return (
    <WidgetContent
      title={title}
      data={data}
      required={required}
      widgetColumnDataItems={widgetColumnDataItems}
      handleDelete={handleDelete}
      handleOnSave={handleOnSave}
      columnHeaderText="History"
      isWinnerWidget={false}
    />
  );
};

export const UserParticipationHistoryWidgetControl = withJsonFormsControlProps(
  UserParticipationHistoryWidgetComponent
);
