import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { listRewardTypes } from "../../../graphql/queries/listRewardTypes";

const RewardTypeSelectComponent = ({
  handleChange,
  label,
  data,
  path,
  uischema,
  required,
}) => {
  const { data: rewardTypesData } = useQuery(listRewardTypes);
  const rewardTypesList = rewardTypesData?.listRewardType?.items || [];
  const childList = rewardTypesList.map((item) => ({
    id: item.id,
    displayText: item.name,
  }));

  const formatData = (data) => [
    childList.find((item) => item.id === data) || { id: "", displayText: "" },
  ];

  const updateValue = (val) => handleChange(path, val?.[0]?.id || "");

  return (
    <div className="multiple-select-wrap">
      <Dropdown
        isRequired={required}
        childList={childList}
        label={label}
        selection="single"
        selectedValue={formatData(data)}
        setSelectedValue={updateValue}
        placeholder={uischema?.placeholder || ""}
        disabled={
          uischema?.readonly ||
          uischema?.disabled ||
          uischema?.options?.readonly ||
          uischema?.options?.disabled
        }
      />
    </div>
  );
};

export const RewardTypeSelectControl = withJsonFormsControlProps(
  RewardTypeSelectComponent
);
