import { Pagination, ToastContainer } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonTableView from "../../components/common/CommonTable/CommonTableView";
import {
  ListingComponentContext,
  ListingComponentProvider,
} from "../../components/common/listingComponents/ListingComponentContext";
import ListingComponentWrapper from "../../components/common/listingComponents/ListingComponentWrapper";
import PerformanceDetailsDrawer from "../../components/performances/PerformanceDetailsDrawer";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { listEngagements } from "../../graphql/queries/listEngagements";
import { paths } from "../../routes/paths";
import {
  receiptScanPerformancesFilterAllJsonSchema,
  receiptScanPerformancesFilterAllUiSchema,
  receiptScanPerformancesFilterJsonSchema,
  receiptScanPerformancesFilterUiSchema,
} from "./mocks";

const contentDefinition = {
  views_config: {
    table: {
      column_config: {
        id: {
          label: "ID",
          render: (value) => (
            <a
              href="#"
              className="performances-widget-id"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {value}
            </a>
          ),
        },
        status: { type: "statusBadge", headerClass: "status" },
        performanceDate: { type: "date" },
        profileId: { label: "User ID" },
        image: { type: "image", headerClass: "user" },
        identifier: { label: "Document ID" },
        vendorName: { label: "Retailer" },
        outcomes: {
          flatten: true,
          fields: [
            { key: "outcome", label: "Outcome Type" },
            { key: "value", label: "Outcome Value" },
          ],
        },
      },
    },
  },
};

const ReceiptScanPerformance = () => {
  const ROWS_PER_PAGE = 10;
  return (
    <ListingComponentProvider
      query={listEngagements}
      fetchPolicy="cache-and-network"
      queryKey="listEngagements"
      initialFilterData={{
        engagementStatus: [],
        engagementType: [],
        limit: ROWS_PER_PAGE,
        offset: 0,
      }}
      noPagination={true}
    >
      <ReceiptScanPerformanceContent rowsPerPage={ROWS_PER_PAGE} />
    </ListingComponentProvider>
  );
};

const ReceiptScanPerformanceContent = ({ rowsPerPage }) => {
  const { viewableRecords, filterData, setFilterData } = useContext(
    ListingComponentContext
  );
  const { programTimezone } = useContext(GlobalConfigContext) || {};
  const [toastData, setToastData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [performanceID, setPerformanceID] = useState(null);
  const [engagementItem, setEngagementItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (filterData) {
      setCurrentPage(Math.ceil(filterData.offset / rowsPerPage) + 1);
    }
  }, [filterData, rowsPerPage]);

  const handleDetailsViewClick = (performanceID) => {
    navigate(`../${paths.performance_details}/${performanceID}`);
  };

  const handleOnTap = (item) => {
    setPerformanceID(item.id);
    setEngagementItem(item);
    setShowDrawer(true);
  };

  const handleChangePage = (page) => {
    setFilterData((prev) => ({
      ...prev,
      offset: (page - 1) * rowsPerPage,
    }));
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(viewableRecords?.total / rowsPerPage);

  return (
    <div className="px-3 pt-3">
      {toastData && (
        <ToastContainer
          className="performances-listing-toast"
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      <div className="container-card">
        <div className="performances-page-content">
          <div className="performances-header">
            <h2>Receipt Scan Performances</h2>
          </div>
          <div className="total-results-count">
            {Number(viewableRecords?.total || 0).toLocaleString()} Results
          </div>
          <ListingComponentWrapper
            customStyles={{ flex: 1 }}
            filterClasses="reciept-scan-performances-filter-styles"
            filterConfig={{
              withoutDebounce: {
                jsonSchema: receiptScanPerformancesFilterJsonSchema,
                uiSchema: receiptScanPerformancesFilterUiSchema,
              },
              allFilters: {
                jsonSchema: receiptScanPerformancesFilterAllJsonSchema,
                uiSchema: receiptScanPerformancesFilterAllUiSchema,
              },
            }}
            toggleViewType={false}
            withAllFilters={true}
            exportOptions={true}
            offsetBasedPagination={true}
          >
            <CommonTableView
              contentList={viewableRecords?.items || []}
              columnConfig={contentDefinition.views_config.table.column_config}
              programTimezone={programTimezone}
              excludeColumns={[
                "image",
                "profileEmail",
                "profilePhone",
                "profileLogin",
                "engagementType",
                "productSkus",
                "programPoints",
                "campaignPoints",
                "outcomes",
              ]}
              cellClickHandlers={{ id: (row) => handleOnTap(row) }}
            />
            {totalPages > 1 && (
              <div className="pagination-wrap mb-4">
                <Pagination
                  currentPage={currentPage}
                  onUpdate={handleChangePage}
                  pageCount={totalPages}
                  size="medium"
                  variant="number"
                />
              </div>
            )}
          </ListingComponentWrapper>
        </div>
      </div>

      <PerformanceDetailsDrawer
        showDrawer={showDrawer}
        engagementItem={engagementItem}
        onCloseDrawer={() => setShowDrawer(false)}
        onDetailViewClick={() => handleDetailsViewClick(performanceID)}
        onApprove={() => {
          setShowDrawer(false);
        }}
        onReject={() => {
          setShowDrawer(false);
        }}
      />
    </div>
  );
};

export default ReceiptScanPerformance;
