import { withJsonFormsControlProps } from "@jsonforms/react";
import { useEffect } from "react";
import TextInputField from "./TextInputField";

const TextInputComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
    visible,
  } = props;

  useEffect(() => {
    if (!enabled && data) {
      handleChange(path, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  const updateValue = (newVal) => {
    const arrayValue = newVal?.length ? [newVal] : [];
    handleChange(path, schema.type === "array" ? arrayValue : newVal);
  };

  return (
    <TextInputField
      value={schema.type === "array" ? data?.[0] : data}
      disabled={!enabled}
      label={uischema.showLabel === false ? "" : label}
      updateValue={updateValue}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      visible={visible}
    />
  );
};

export const TextInputControl = withJsonFormsControlProps(TextInputComponent);
