import { useQuery } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useContext, useMemo } from "react";
import defaultImage from "../../../../assets/default_img.png";
import { GlobalConfigContext } from "../../../../context/GlobalConfigContext";
import { listRewardGroups } from "../../../../graphql/queries/listRewardGroups";
import { getDateWithFormat } from "../../../../utils/commons";

const RewardGroupOutcomes = ({ outcome, campaignType }) => {
  const { programTimezone } = useContext(GlobalConfigContext);

  const { data } = useQuery(listRewardGroups, {
    variables: {
      rewardGroupId: outcome?.outcomeData?.reward_group_id,
    },
    skip: !outcome?.outcomeData?.reward_group_id,
  });
  const rewardGroupData = data?.listRewardGroups?.[0];
  const [rewards = [], extraRewards = []] = useMemo(() => {
    const rewards = [],
      extraRewards = [];
    for (const reward of rewardGroupData?.rewardGroupRewards || []) {
      if (reward.isExtra) {
        extraRewards.push(reward);
      } else {
        rewards.push(reward);
      }
    }
    rewards.sort((a, b) => (a.position > b.position ? 1 : -1));
    extraRewards.sort((a, b) => (a.position > b.position ? 1 : -1));
    return [rewards, extraRewards];
  }, [rewardGroupData]);

  return (
    <Accordion
      defaultExpanded
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className="display-flex justify-content-space-between align-items-center w-100">
          <span>
            {rewardGroupData?.type === "LIST"
              ? "List Reward Group"
              : "Chance to win Reward Group"}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: "100%" }}>
          <hr className="mb-10" color="#DFDFDF" />
          {rewardGroupData?.type !== "LIST" && (
            <div className="additional-fields-container">
              <div className="field-pair">
                <span className="field-label">Is spin the wheel enabled?</span>
              </div>
              <div className="field-pair-two-column">
                <span className="field-value">
                  {rewardGroupData?.isSpinWheelEnabled ? "Yes" : "No"}
                </span>
              </div>
            </div>
          )}
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Reward group name</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">
                {rewardGroupData?.name?.indexOf("list-rewards-group-name") !== -1
                  ? "-"
                  : rewardGroupData?.name || "-"}
              </span>
            </div>
          </div>
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Position on gift selection page</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">{rewardGroupData?.position}</span>
            </div>
          </div>
          {campaignType === "standard_campaign" &&
            rewardGroupData?.type === "LIST" && (
              <div className="additional-fields-container">
                <div className="field-pair">
                  <span className="field-label">
                    Number of code scans required to win
                  </span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    {rewardGroupData?.numberOfCodeScansRequiredToWin}
                  </span>
                </div>
              </div>
            )}
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Max Participation</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">
                {rewardGroupData?.numberOfWinnersLimit}
              </span>
            </div>
          </div>
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Reward group description</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">
                {rewardGroupData?.description?.indexOf(
                  "list-rewards-group-description"
                ) !== -1
                  ? "-"
                  : rewardGroupData?.description || "-"}
              </span>
            </div>
          </div>
          {rewardGroupData?.image !== "no_image" && (
            <div className="additional-fields-container">
              <div className="field-pair">
                <span className="field-label">Reward group image</span>
              </div>
              <div className="field-pair-two-column">
                <span className="field-value">
                  <img
                    height={89}
                    width={113}
                    src={
                      rewardGroupData?.image !== "no_image"
                        ? rewardGroupData?.image
                        : defaultImage
                    }
                    alt={"title"}
                  />
                </span>
              </div>
            </div>
          )}
          <hr className="mb-10" color="#DFDFDF" />
          <div style={{ width: "100%", paddingTop: "10px" }}>
            <div className="additional-fields-container">
              <div className="field-pair">
                <span className="field-label">Rewards</span>
              </div>
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <tbody>
              {rewards?.map((reward) => (
                <tr key={reward.id} style={{ height: "30px" }}>
                  <td>
                    <span className="field-value text-ellipsis-1">
                      {reward?.rewardInfo?.name || reward.rewardId}
                    </span>
                  </td>
                  {rewardGroupData?.type !== "LIST" && (
                    <td className="text-center">
                      <span className="field-value">
                        Chance to win: {reward.chanceToWinPercentage}%
                      </span>
                    </td>
                  )}
                  <td>
                    <span className="field-value">
                      Multiplier: {reward.quantity}
                    </span>
                  </td>
                  <td>
                    <span className="field-value">
                      Win limit: {reward.totalUserWinLimit}
                    </span>
                  </td>
                  <td>
                    <span className="field-value">
                      Wallet Withdrawal Date:{" "}
                      {reward.walletWithdrawalDate
                        ? getDateWithFormat(
                            reward?.walletWithdrawalDate,
                            programTimezone
                          )
                        : "N/A"}
                    </span>
                  </td>

                  <td>
                    <span className="field-value">
                      Display Date:{" "}
                      {reward.startDate
                        ? getDateWithFormat(
                            reward?.startDate,
                            programTimezone,
                            "MM/DD/YYYY"
                          )
                        : "N/A"}{" "}
                      -{" "}
                      {reward.endDate
                        ? getDateWithFormat(
                            reward?.endDate,
                            programTimezone,
                            "MM/DD/YYYY"
                          )
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              ))}
              {extraRewards.length > 0 && (
                <>
                  <tr style={{ height: "50px" }}>
                    <td
                      style={{
                        color: "#3A3A3A",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      colSpan={5}
                    >
                      <span className="text-ellipsis-1">Extra Rewards</span>
                    </td>
                  </tr>
                  {extraRewards?.map((reward) => (
                    <tr key={reward.id} style={{ height: "30px" }}>
                      <td>
                        <span className="field-value text-ellipsis-1">
                          {reward?.rewardInfo?.name || reward.rewardId}
                        </span>
                      </td>
                      {rewardGroupData?.type !== "LIST" && (
                        <td className="text-center">
                          <span className="field-value">Chance to win: N/A</span>
                        </td>
                      )}
                      <td>
                        <span className="field-value">Qty: {reward.quantity}</span>
                      </td>
                      <td>
                        <span className="field-value">
                          Win limit: {reward.totalUserWinLimit}
                        </span>
                      </td>
                      <td>
                        <span className="field-value">
                          Wallet Withdrawal Date:{" "}
                          {reward.walletWithdrawalDate
                            ? getDateWithFormat(
                                reward?.walletWithdrawalDate,
                                programTimezone
                              )
                            : "N/A"}
                        </span>
                      </td>

                      <td>
                        <span className="field-value">
                          Display Date:{" "}
                          {reward.startDate
                            ? getDateWithFormat(
                                reward?.startDate,
                                programTimezone,
                                "MM/DD/YYYY"
                              )
                            : "N/A"}{" "}
                          -{" "}
                          {reward.endDate
                            ? getDateWithFormat(
                                reward?.endDate,
                                programTimezone,
                                "MM/DD/YYYY"
                              )
                            : "N/A"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default RewardGroupOutcomes;
