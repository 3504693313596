import { gql } from "@apollo/client";

export const loadEngagementById = gql`
  query LoadEngagementById($id: ID!) {
    loadEngagementById(id: $id) {
      id
      profileId
      profileEmail
      profilePhone
      profileLogin
      engagementType
      deviceId
      campaignName
      performanceDate
      identifier
      productSkus
      programPoints
      campaignPoints
      status
      image
      vendorName
      outcomes {
        outcome
        value
      }
    }
  }
`;
