import { createAjv } from "@jsonforms/core";
import { LoadingSpinner, Modal } from "pepsico-ds";
import { useMemo } from "react";
import JsonFormsWrapper from "../../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./PartnerForm.scss";

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      label: "Partner subtype",
      scope: "#/properties/type",
      rule: {
        effect: "DISABLE",
        condition: {
          scope: "#",
          schema: {},
        },
      },
    },
    {
      type: "Control",
      label: "Name",
      scope: "#/properties/name",
    },
    {
      type: "Control",
      label: "Description",
      scope: "#/properties/description",
      component: "textarea",
    },
    {
      type: "Control",
      label: "Active",
      scope: "#/properties/active",
      component: "toggle",
    },
  ],
};

const jsonSchema = {
  type: "object",
  properties: {
    type: {
      default: "PARTNER_CODE_GROUP",
      type: "string",
    },
    name: {
      type: "string",
      minLength: 3,
      maxLength: 255,
      description: "Please enter name",
    },
    description: {
      type: "string",
      minLength: 3,
      maxLength: 255,
      description: "Please enter description",
    },
    active: {
      type: "boolean",
      default: true,
    },
  },
  required: ["name", "description"],
};

function PartnerFormModal({
  isOpen,
  closeModal,
  partnerData,
  setPartnerData,
  handleMutation,
  loading,
  title,
  btnTitle,
}) {
  const ajvInstance = useMemo(() => {
    return createAjv();
  }, []);

  const isValidForm = ajvInstance.validate(jsonSchema, partnerData);

  const getUIschema = () => {
    const uiSchemaCopy = JSON.parse(JSON.stringify(uiSchema));

    const activeField = uiSchemaCopy.elements.find(
      (element) => element.scope === "#/properties/active"
    );

    if (activeField && partnerData?.isAttached) {
      activeField.readOnly = true;
    }

    return uiSchemaCopy;
  };

  return (
    <Modal
      type="S"
      showModal={isOpen}
      onCloseModal={closeModal}
      showSecondaryButton={false}
      title={title}
      primaryButtonProps={{
        size: "medium",
        text: loading ? (
          <LoadingSpinner
            size={{ height: "24px", width: "24px" }}
            style={{ margin: "0 2.4rem" }}
          />
        ) : (
          <>{btnTitle || title}</>
        ),
        variant: "primary",
        disabled: !isValidForm || loading,
        onClick: handleMutation,
      }}
      secondaryButtonProps={{
        size: "medium",
        text: "Cancel",
        variant: "secondary",
      }}
      showTertiaryButton={false}
      showLink={false}
      className="custom-modal-partner-form"
    >
      <JsonFormsWrapper
        jsonschema={jsonSchema}
        uischema={getUIschema()}
        data={partnerData}
        onChange={(data) => setPartnerData(data)}
      />
    </Modal>
  );
}

export default PartnerFormModal;
