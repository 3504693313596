import { paths } from "../../routes/paths";
import { convertToTitleCase, getDateWithFormat } from "../../utils/commons";

export const getEngagementTypeSpecificTableData = (
  engagementType,
  data,
  handleClick,
  timezone
) => {
  const typeSpecificTableData = {
    "package-scan": {
      headerData: [
        {
          columnKey: "id",
          header: "ID",
        },
        {
          columnKey: "externalId",
          header: "External ID",
        },
        {
          columnKey: "userId",
          header: "User ID",
        },
        {
          columnKey: "deviceId",
          header: "Device ID",
        },
        {
          columnKey: "campaignName",
          header: "Campaign Name",
        },
        {
          columnKey: "performanceDate",
          header: "Performance Date",
        },
        {
          columnKey: "outcomeName",
          header: "Outcome Name",
        },
        {
          columnKey: "outcomeQuantity",
          header: "Outcome Quantity",
        },
        {
          columnKey: "source",
          header: "Source",
        },
        {
          columnKey: "sku",
          header: "SKU",
        },
        {
          columnKey: "freshByDate",
          header: "Fresh By Date",
        },
        {
          columnKey: "status",
          header: "Status",
        },
      ],
      rowData: data.map((item) => {
        const splitStatus = item.status?.split("_");
        const compositeStatus =
          splitStatus.length === 2
            ? splitStatus[1].toLowerCase()
            : item.status?.toLowerCase() || "-";
        const compositeStatusClass = compositeStatus
          .toLowerCase()
          .replace(/\s/g, "-");
        return {
          id: (
            <a
              className="performances-widget-id"
              href={`${paths.performances_list}/${paths.performance_details}/${item.id}`}
              rel="noopener noreferrer"
              onClick={(e) => handleClick(e, item.id)}
            >
              {item.id}
            </a>
          ),
          externalId: item.identifier || "-",
          userId: item.profileId || "-",
          deviceId: item.deviceId || "-",
          campaignName: item.campaignName || "-",
          performanceDate: item.performanceDate
            ? getDateWithFormat(item.performanceDate, timezone, "DD/MM/YYYY")
            : "-",
          outcomeName: [
            ...new Set(
              item.outcomes?.map((outcome) => convertToTitleCase(outcome.outcome))
            ),
          ].join(", "),
          outcomeQuantity:
            item.outcomes?.reduce((acc, outcome) => acc + +outcome.value, 0) || "-",
          source: "-",
          sku: item.productSkus?.join(", ") || "-",
          freshByDate: item.metadata?.package.freshness_date
            ? getDateWithFormat(
                item.metadata.package.freshness_date,
                timezone,
                "DD/MM/YYYY"
              )
            : "-",
          status: (
            <span
              className={`performances-lifecycle-status ${compositeStatusClass}`}
            >
              {convertToTitleCase(compositeStatus)}
            </span>
          ),
        };
      }),
    },
  };
  return typeSpecificTableData[engagementType];
};
