import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { LoadingSpinner } from "pepsico-ds";
import { getDateWithFormat } from "../../../utils/commons";

const UniqueCodeGroupsTable = ({
  codeGroupsData,
  selectedCodeGroupsList,
  loading,
  onSelectCodeGroup,
  programTimezone,
}) => {
  return (
    <div className="codegroups-table p-0">
      {loading ? (
        <LoadingSpinner
          size={{
            height: "30px",
            width: "30px",
          }}
          style={{ margin: "auto" }}
        />
      ) : (
        <table>
          <thead>
            <tr>
              <th className="column-brands">CODE GROUP NAME</th>
              <th className="column-keywords">STATUS</th>
              <th className="column-keywords"># OF CODES</th>
              <th className="column-keywords">USED CODES</th>
              <th className="column-keywords">ACTIVE DATES</th>
            </tr>
          </thead>
          <tbody>
            {codeGroupsData?.length ? (
              codeGroupsData?.map((codeGroup, index) => (
                <tr key={codeGroup.codeGroupId || `codeGroup-${index}`}>
                  <td className="td-checkbox">
                    <FormControlLabel
                      control={<Radio />}
                      label={codeGroup.basicSetup?.codeGroupName}
                      onChange={() => onSelectCodeGroup(codeGroup)}
                      checked={selectedCodeGroupsList?.some(
                        (row) => row.codeGroupId === codeGroup.codeGroupId
                      )}
                    />
                  </td>
                  <td className="td-brands">
                    <div className="common-status-wrapper">
                      <div
                        className={`common-status ${codeGroup.lifecycleStatus?.toLowerCase().replace(" ", "_")}`}
                      >
                        <span>{codeGroup.lifecycleStatus}</span>
                      </div>
                    </div>
                  </td>
                  <td className="td-keywords">{codeGroup.codesGeneratedCount}</td>
                  <td className="td-keywords">{codeGroup.codesUsedCount}</td>
                  {codeGroup.basicSetup?.codeActiveStartDate &&
                    codeGroup.basicSetup?.codeActiveEndDate &&
                    programTimezone && (
                      <td className="td-keywords">
                        {getDateWithFormat(
                          codeGroup.basicSetup?.codeActiveStartDate,
                          programTimezone
                        )}{" "}
                        -{" "}
                        {getDateWithFormat(
                          codeGroup.basicSetup?.codeActiveEndDate,
                          programTimezone
                        )}
                      </td>
                    )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="no-data">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UniqueCodeGroupsTable;
