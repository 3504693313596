export const programConfigDuplicateJson = {
  loadProgram: {
    id: "018a2bd5-fdc2-77ce-9367-7d700186d5ae",
    name: "Pepsico Tasty Rewards",
    description: "Program configuration for PTR",
    slug: "ptr-program",
    logo: "/logo.png",
    defaultValues: {},
    configurations: [
      {
        key: "minor_age",
        value: {
          title: "Minor Age",
          description: "",
          data: {
            minor_age: 17,
          },
        },
      },
    ],
    tags: {
      campaign: ["New testt", "tagQA", "14745611", "QA-BAM-PREPROD"],
      content: [],
    },
    localization: {
      programTimezone: "America/Chicago",
      countries: [
        {
          key: "US",
          value: {
            languages: ["en-us", "es-us"],
            currencies: ["USD"],
            addressSchema: {},
          },
        },
      ],
    },
    campaignsGroups: [],
    campaigns: [
      {
        key: "create_a_profile",
        value: {
          enabled: true,
          label: "Create a Profile",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["registration"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "22b689e8-9e1c-47ec-9412-134eba7b947f",
                name: "Registration",
                description:
                  "Awards entries when a user registers for the first time.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "registration",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "146bfcf9-5783-4ff7-bfdc-cf3181e47787",
              name: "Create Profile campaign content definition",
              slug: "create-profile-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Create Profile Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "create-profile-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "refer_a_friend",
        value: {
          enabled: true,
          label: "Refer a Friend",
          image:
            "https://pepsicotest.getbynder.com/m/12bbd722c5681cc6/original/content_4f47850d-e1a2-477b-9878-54607df8d0b0.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["refer-a-friend"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "b75d1a86-bb1f-4bb2-a1b0-86246bb4f8a0",
                name: "Refer a Friend",
                description: "Awards entries when a user refers a friend.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "refer-a-friend",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "e9fe2be9-d622-4dbe-992b-e29bb8fb0af9",
              name: "Refer a Friend campaign content definition",
              slug: "refer-a-friend-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Refer a Friend Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "refer-a-friend-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "latam_code_redemption_batch",
        value: {
          enabled: true,
          label: "Code Redemption - Batch (LATAM)",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["latam-batch-code"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "1906c096-d2b4-41aa-9994-28079aa26acc",
                name: "Batch Code (LATAM)",
                description: "Rewards user when a valid batch code is submitted.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["latam-batch-code-rule"],
                requiredOutcomes: [],
                slug: "latam-batch-code",
                maxAllowed: 1,
                rules: [
                  {
                    id: "eb8b9910-a92d-43fc-a409-a3c692aa2f2e",
                    name: "Batch Code",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code_length: {
                          title: "Batch Code Length",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["code_length"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-batch-code-rule",
                    uiSchema: {
                      type: "VerticalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/code_length",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "cba8d1ed-0512-462a-9bc3-f3526c057639",
                    name: "Require Additional Metadata (LATAM)",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        metadata_required: {
                          description: "Require additonal metadata for widget",
                          default: true,
                          title: "Additional Metadata Required",
                          type: "boolean",
                        },
                      },
                      required: ["metadata_required"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-metadata-required",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/metadata_required",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "a93be1cb-83bd-4eb5-8416-f825dc4df26d",
                    name: "Award Points",
                    slug: "latam-award-points",
                    description: "Awards points to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["latam-calculate-points"],
                    rules: [
                      {
                        id: "925debb2-41cd-4eef-b0da-f97b5141286b",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Points",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "fa008156-eb34-4db2-b360-6d6c966ed647",
                    name: "Sweepstakes Entry (LATAM)",
                    slug: "latam-award-sweepstakes-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        sweepstakes: {
                          title: "Selected Sweepstake",
                        },
                        entries: {
                          title: "Number of Entries to Award",
                          type: "number",
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["target", "entries"],
                    },
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "sweepstakesSelect",
                          scope: "#/properties/target_id",
                        },
                        {
                          child_class_name: "ml-48",
                          rule: {
                            condition: {
                              schema: {
                                type: "string",
                                not: {
                                  enum: [null, ""],
                                },
                              },
                              scope: "#/properties/target_id",
                            },
                            effect: "SHOW",
                          },
                          className: "sweepstake-custom-styles",
                          type: "Control",
                          maxLength: 5,
                          scope: "#/properties/entries",
                        },
                      ],
                    },
                    requiredRules: [],
                    rules: [],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: ["activeEndDate", "title", "activeStartDate"],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
              },
              required: ["title", "activeStartDate"],
            },
          },
          contentSteps: [
            {
              id: "0de10214-aaf4-4e2b-b629-cb478b79e397",
              name: "Code Redemption (LATAM)",
              slug: "latam-code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      cta_label: {
                        title: "CTA Label",
                        type: "string",
                        minLength: 1,
                      },
                      instructions_link: {
                        pattern:
                          "^(?:https?:\\/\\/(?:www\\.)?[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(?:\\/[^\\s]*)?)?$",
                        message: 'must match pattern "URI"',
                        title: "Instructions URL",
                        type: "string",
                      },
                      terms_and_conditions_text: {
                        title: "Terms & Conditions Text",
                        type: "string",
                      },
                      input_placeholder: {
                        title: "Input Placeholder",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Image",
                        type: "string",
                      },
                      input_label: {
                        title: "Input Label",
                        type: "string",
                        minLength: 1,
                      },
                      instructions_text: {
                        title: "Instructions Text",
                        type: "string",
                      },
                      terms_and_conditions_link: {
                        pattern:
                          "^(?:https?:\\/\\/(?:www\\.)?[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(?:\\/[^\\s]*)?)?$",
                        message: 'must match pattern "URI"',
                        title: "Terms & Conditions URL",
                        type: "string",
                      },
                    },
                    required: ["input_label", "cta_label"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["pt-br"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "pt-br"],
                    },
                    properties: {
                      "pt-br": {
                        $ref: "#/definitions/localized_content",
                      },
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content *",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_placeholder",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/cta_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_link",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "latam-code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "click_on_a_link",
        value: {
          enabled: true,
          label: "Click On Link",
          image:
            "https://pepsicotest.getbynder.com/m/7158fd8753d4f38e/original/content_b8e13709-5c34-425b-be9c-1d0fd51a2408.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["click-link"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "dd7e25eb-1771-43c7-80b0-fc0c4ef4b4ec",
                name: "Click Link",
                description: "Awards entries when a user clicks a link.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["match-url"],
                requiredOutcomes: ["award-points"],
                slug: "click-link",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e430-7c6d-a277-0a919e72abf7",
                    name: "Match URL",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        urls: {
                          minItems: 1,
                          format: "uri",
                          title: "URL",
                          type: "array",
                        },
                      },
                      required: ["urls"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-url",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "singleInputArray",
                          scope: "#/properties/urls",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "be986d3d-5925-4e68-9d49-b8fa8eda89d5",
              name: "Click on Link campaign content definition",
              slug: "click-on-link-campaign-content",
              version: 2,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Click on Link Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        format: "uri",
                        title: "Link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "link", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "click-on-link-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "bonus",
        value: {
          enabled: true,
          label: "Bonus",
          image:
            "https://pepsicotest.getbynder.com/m/42bf29a30f7678c4/original/content_524313cf-91fc-42c9-b697-7ffa06ce26c9.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["bonus"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "54c338f5-3ff1-4000-bee5-27bfa3685a35",
                name: "Bonus Campaign",
                description: "Awards entries associated to segment of users.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["user-in-segment"],
                requiredOutcomes: ["award-points"],
                slug: "bonus",
                maxAllowed: null,
                rules: [
                  {
                    id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                    name: "Qualified Segments",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        segment_ids: {
                          minItems: 1,
                          title: "ids of the segments",
                          type: "array",
                          items: {
                            type: "string",
                          },
                        },
                      },
                      required: ["segment_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "user-in-segment",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "segmentSelect",
                          scope: "#/properties/segment_ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "c9c1c3cb-2802-440a-8638-0482889f0b4c",
              name: "Bonus campaign based on segment content definition",
              slug: "bonus-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Bonus campaign based on segment content definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        format: "uri",
                        title: "Link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                    info: "To redirect member to when clicking on the activity card",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "bonus-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "unique_code_redemption",
        value: {
          enabled: true,
          label: "Unique Code Redemption",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["unique-code-group"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "9ff8c82e-5a1a-4e79-abbf-8ab12752a9bd",
                name: "Unique Code Group",
                description: "Awards entries after a user enters an unlimited code.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["select-code-group"],
                requiredOutcomes: ["award-points"],
                slug: "unique-code-group",
                maxAllowed: 1,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "b2dd2a38-bc1d-4c18-8f91-182bc77d4df9",
                    name: "Select Code Group",
                    hidden: true,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code_group_ids: {
                          minItems: 1,
                          type: "array",
                        },
                      },
                      required: ["code_group_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "select-code-group",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            initialFilter: {
                              lifecycleStatus: "ACTIVE",
                              status: "ENABLED",
                            },
                          },
                          component: "uniqueCodeGroupSelector",
                          type: "Control",
                          scope: "#/properties/code_group_ids",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "eb979194-6843-4608-bcac-c8e20ac89fef",
              name: "Code Redemption campaign content definition",
              slug: "code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Code Redemption (unlimited) Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 50,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    type: "Control",
                                    component: "textarea",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "streak",
        value: {
          enabled: true,
          label: "Streak",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["streak"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "2176304f-3ed0-4d0a-92b9-eed8b019bb88",
                name: "Streak",
                description: "Evaluates a streak.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["user-streak-limit"],
                requiredOutcomes: ["award-points-streak"],
                slug: "streak",
                maxAllowed: null,
                rules: [
                  {
                    id: "8ea30f0a-57bc-42ac-9e8d-816d888e8787",
                    name: "User Streak limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Streak Limit",
                          type: "integer",
                          minimum: 1,
                        },
                        performance_count: {
                          title: "Streak Goal Count",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-streak-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/performance_count",
                        },
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "019091a6-0304-7b31-9e77-48d9dbd585cf",
                    name: "Award Entries",
                    slug: "award-points-streak",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["validate-streak", "calculate-points"],
                    rules: [
                      {
                        id: "3b22b629-81ec-4213-a6be-4fd921acc88e",
                        name: "Validate Streak",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            performance_count: {
                              title: "Re-Enter Streak Goal Count",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                        },
                        ruleFunction: "validate",
                        maxAllowed: null,
                        slug: "validate-streak",
                        uiSchema: {
                          type: "HorizontalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/performance_count",
                            },
                          ],
                        },
                      },
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                name: "User Campaign limit",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    limit: {
                      title: "User Campaign limit",
                      type: "integer",
                      minimum: 1,
                    },
                  },
                  required: ["limit"],
                },
                ruleFunction: "validate",
                maxAllowed: 1,
                slug: "user-campaign-limit",
                uiSchema: {
                  type: "HorizontalLayout",
                  class_name: "custom-width",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 10,
                      scope: "#/properties/limit",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "1c5b0e98-36a5-4a6f-a507-258fab61f992",
              name: "Streak campaign content definition",
              slug: "streak-campaign-content",
              version: 1,
              description: "Definition of streak content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Streak Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "streak-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "generic",
        value: {
          enabled: true,
          label: "Generic Campaign",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["generic"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "10436008-2b4f-42a3-8470-f6ec15b0e854",
                name: "Generic",
                description: "Evaluates a generic campaign.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: [],
                slug: "generic",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "e1b85a7d-fd04-4457-b478-99bd13fa2a56",
                    name: "Empty Rule",
                    hidden: false,
                    inputSchema: {},
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "empty-rule",
                    uiSchema: {},
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "117586a0-b184-4141-9457-15f5015e6a82",
                    name: "Award Variable Points",
                    slug: "award-variable-points",
                    description: "Awards variable points to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-variable-points"],
                    rules: [
                      {
                        id: "b2efb515-326a-41b9-bed8-28fe7b225d46",
                        name: "Calculate Variable Points",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            maximum_points: {
                              default: 100,
                              title: "Maximum Number of Points Allowed",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                          required: ["maximum_points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-variable-points",
                        uiSchema: {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/properties/maximum_points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "d8f3e6ca-9b17-4c86-96fc-5c31f419f558",
                    name: "Check Streaks",
                    slug: "check-streaks",
                    description: "Check for related streak campaigns",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [
                      {
                        id: "07f510b8-81e5-4a9f-bbf0-96dce191442c",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              maximum: 0,
                              readOnly: true,
                              title: "Number of Entries",
                              type: "number",
                              minimum: 0,
                            },
                          },
                          required: [],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "points-disabled",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "f49d8a08-3e60-4a9f-ba99-34e42a62dad1",
              name: "Generic campaign content definition",
              slug: "generic-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Generic Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "generic-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "latam_code_redemption_unique",
        value: {
          enabled: true,
          label: "Code Redemption - Unique (LATAM)",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["latam-unique-code"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "4f5208e5-5987-4d77-90cd-f0c9a2c31f6a",
                name: "Unique Code (LATAM)",
                description: "Rewards user when a valid unique code is submitted.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["latam-unique-code-rule"],
                requiredOutcomes: [],
                slug: "latam-unique-code",
                maxAllowed: 1,
                rules: [
                  {
                    id: "b2dd2a38-bc1d-4c18-8f91-182bc66d4df9",
                    name: "Unique Code",
                    hidden: true,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code_group_ids: {
                          minItems: 1,
                          type: "array",
                        },
                      },
                      required: ["code_group_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "latam-unique-code-rule",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            initialFilter: {
                              lifecycleStatus: "ACTIVE",
                              status: "ENABLED",
                            },
                          },
                          component: "codeGroupSelector",
                          type: "Control",
                          scope: "#/properties/code_group_ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "cba8d1ed-0512-462a-9bc3-f3526c057639",
                    name: "Require Additional Metadata (LATAM)",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        metadata_required: {
                          description: "Require additonal metadata for widget",
                          default: true,
                          title: "Additional Metadata Required",
                          type: "boolean",
                        },
                      },
                      required: ["metadata_required"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-metadata-required",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/metadata_required",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "a93be1cb-83bd-4eb5-8416-f825dc4df26d",
                    name: "Award Points",
                    slug: "latam-award-points",
                    description: "Awards points to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["latam-calculate-points"],
                    rules: [
                      {
                        id: "925debb2-41cd-4eef-b0da-f97b5141286b",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Points",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "fa008156-eb34-4db2-b360-6d6c966ed647",
                    name: "Sweepstakes Entry (LATAM)",
                    slug: "latam-award-sweepstakes-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        sweepstakes: {
                          title: "Selected Sweepstake",
                        },
                        entries: {
                          title: "Number of Entries to Award",
                          type: "number",
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["target", "entries"],
                    },
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "sweepstakesSelect",
                          scope: "#/properties/target_id",
                        },
                        {
                          child_class_name: "ml-48",
                          rule: {
                            condition: {
                              schema: {
                                type: "string",
                                not: {
                                  enum: [null, ""],
                                },
                              },
                              scope: "#/properties/target_id",
                            },
                            effect: "SHOW",
                          },
                          className: "sweepstake-custom-styles",
                          type: "Control",
                          maxLength: 5,
                          scope: "#/properties/entries",
                        },
                      ],
                    },
                    requiredRules: [],
                    rules: [],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: ["activeEndDate", "title", "activeStartDate"],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
              },
              required: ["title", "activeStartDate"],
            },
          },
          contentSteps: [
            {
              id: "0de10214-aaf4-4e2b-b629-cb478b79e397",
              name: "Code Redemption (LATAM)",
              slug: "latam-code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      cta_label: {
                        title: "CTA Label",
                        type: "string",
                        minLength: 1,
                      },
                      instructions_link: {
                        pattern:
                          "^(?:https?:\\/\\/(?:www\\.)?[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(?:\\/[^\\s]*)?)?$",
                        message: 'must match pattern "URI"',
                        title: "Instructions URL",
                        type: "string",
                      },
                      terms_and_conditions_text: {
                        title: "Terms & Conditions Text",
                        type: "string",
                      },
                      input_placeholder: {
                        title: "Input Placeholder",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Image",
                        type: "string",
                      },
                      input_label: {
                        title: "Input Label",
                        type: "string",
                        minLength: 1,
                      },
                      instructions_text: {
                        title: "Instructions Text",
                        type: "string",
                      },
                      terms_and_conditions_link: {
                        pattern:
                          "^(?:https?:\\/\\/(?:www\\.)?[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(?:\\/[^\\s]*)?)?$",
                        message: 'must match pattern "URI"',
                        title: "Terms & Conditions URL",
                        type: "string",
                      },
                    },
                    required: ["input_label", "cta_label"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["pt-br"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "pt-br"],
                    },
                    properties: {
                      "pt-br": {
                        $ref: "#/definitions/localized_content",
                      },
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content *",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_placeholder",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/cta_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_link",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "latam-code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "opt_in_for_notification",
        value: {
          enabled: true,
          label: "Opt in for Notifications",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["notifications-opt-in"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "d317f309-f98f-4518-8421-3693fad8ecf6",
                name: "Notifications Opt-In",
                description:
                  "Awards entries when a user opts-in to receive notifications.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "notifications-opt-in",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "0d923c02-3055-44a5-8e98-7aa071ae6d3c",
              name: "Opt in for notification campaign content definition",
              slug: "opt-for-notifications-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Opt in for notification Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "opt-for-notifications-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "upload_receipt",
        value: {
          enabled: true,
          label: "Upload a Receipt",
          image:
            "https://pepsicotest.getbynder.com/m/6183cec2bcf98239/original/content_86cd7a15-1212-464b-854f-573ecd02d314.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["upload-receipt"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "545988e0-932b-4420-a0d1-868e4743793e",
                name: "Upload Receipt",
                description:
                  "Awards entries after a user uploads a receipt containing the selected keywords",
                inputSchema: {
                  type: "object",
                  properties: {
                    receipt_data: {
                      type: "object",
                    },
                  },
                  required: ["receipt_data"],
                },
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "upload-receipt",
                maxAllowed: 1,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e42f-75ca-884b-67d512454a6f",
                    name: "Brands",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of brand ids selected by the admin",
                          filter_by: "BRAND",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "match-brands-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907e31-8c2a-7388-b934-2ef174dc93d2",
                    name: "Retailers",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of outlet ids selected by the admin",
                          filter_by: "OUTLET",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "match-outlets-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190dcb4-54da-76bc-88a4-dd9f143ced9e",
                    name: "SKU/Products",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description:
                            "Array of SKU/product ids selected by the admin",
                          filter_by: "PRODUCT",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "match-products-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907ec4-1a19-76ac-8d44-4b3dc2c6e8ec",
                    name: "Receipt recency",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        end_timestamp: {
                          title: "End Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                        start_timestamp: {
                          title: "Start Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                      },
                      required: ["start_timestamp"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "receipt-in-date-range",
                    uiSchema: {
                      label: "Receipt date",
                      type: "HorizontalLayout",
                      label_classname: "receipt-recency-label",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          component: "dateTimePicker",
                          scope: "#/properties/start_timestamp",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                gt: "start_timestamp",
                              },
                            },
                            effect: "VALIDATE",
                          },
                          component: "dateTimePicker",
                          type: "Control",
                          scope: "#/properties/end_timestamp",
                        },
                      ],
                    },
                  },
                  {
                    id: "019073a7-39ff-7e45-b60d-c41c929f5809",
                    name: "Minimum Purchase Value",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        min_purchase_val: {
                          default: 1,
                          title: "Min Purchase Value",
                          type: "number",
                          minimum: 1,
                        },
                      },
                      required: ["min_purchase_val"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "min-purchase-val",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/min_purchase_val",
                          prefexLabel: "Per",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "dea1225e-0b0e-4607-9ab2-c274b600de28",
              name: "Upload receipt campaign content definition",
              slug: "upload-receipt-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      redirect_link: {
                        format: "uri",
                        title: "Link to redirect",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/redirect_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "upload-receipt-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "log_in",
        value: {
          enabled: true,
          label: "Log In",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["login"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "e1d93995-f4df-4799-bc1d-e4b2dae3fac6",
                name: "Login",
                description: "Tracks logs in for use in streaks.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["check-streaks"],
                slug: "login",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "d8f3e6ca-9b17-4c86-96fc-5c31f419f558",
                    name: "Check Streaks",
                    slug: "check-streaks",
                    description: "Check for related streak campaigns",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [
                      {
                        id: "07f510b8-81e5-4a9f-bbf0-96dce191442c",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              maximum: 0,
                              readOnly: true,
                              title: "Number of Entries",
                              type: "number",
                              minimum: 0,
                            },
                          },
                          required: [],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "points-disabled",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "8f94f8fe-2535-41ef-ab3d-24491c155728",
              name: "Log in campaign content definition",
              slug: "log-in-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Log in Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "log-in-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "latam_receipt_upload",
        value: {
          enabled: true,
          label: "Receipt Upload (LATAM)",
          image:
            "https://pepsicotest.getbynder.com/m/6183cec2bcf98239/original/content_86cd7a15-1212-464b-854f-573ecd02d314.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["latam-upload-receipt"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "09454917-b3c6-41c1-812a-d95487fd2d85",
                name: "Upload Receipt (LATAM)",
                description:
                  "Awards entries after a user uploads a receipt containing the selected keywords",
                inputSchema: {
                  type: "object",
                  properties: {
                    receipt_data: {
                      type: "object",
                    },
                  },
                  required: ["receipt_data"],
                },
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: [],
                slug: "latam-upload-receipt",
                maxAllowed: 1,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "3a2a07c3-1348-438e-8bbe-6255249ad129",
                    name: "User Yearly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Yearly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-yearly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e42f-75ca-884b-67d512454a6f",
                    name: "Brands",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of brand ids selected by the admin",
                          filter_by: "BRAND",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "match-brands-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907e31-8c2a-7388-b934-2ef174dc93d2",
                    name: "Retailers",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of outlet ids selected by the admin",
                          filter_by: "OUTLET",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "match-outlets-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190dcb4-54da-76bc-88a4-dd9f143ced9e",
                    name: "SKU/Products",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description:
                            "Array of SKU/product ids selected by the admin",
                          filter_by: "PRODUCT",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 100,
                    slug: "match-products-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907ec4-1a19-76ac-8d44-4b3dc2c6e8ec",
                    name: "Receipt recency",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        end_timestamp: {
                          title: "End Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                        start_timestamp: {
                          title: "Start Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                      },
                      required: ["start_timestamp"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "receipt-in-date-range",
                    uiSchema: {
                      label: "Receipt date",
                      type: "HorizontalLayout",
                      label_classname: "receipt-recency-label",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          component: "dateTimePicker",
                          scope: "#/properties/start_timestamp",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                gt: "start_timestamp",
                              },
                            },
                            effect: "VALIDATE",
                          },
                          component: "dateTimePicker",
                          type: "Control",
                          scope: "#/properties/end_timestamp",
                        },
                      ],
                    },
                  },
                  {
                    id: "019073a7-39ff-7e45-b60d-c41c929f5809",
                    name: "Minimum Purchase Value",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        min_purchase_val: {
                          default: 1,
                          title: "Min Purchase Value",
                          type: "number",
                          minimum: 1,
                        },
                      },
                      required: ["min_purchase_val"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "min-purchase-val",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/min_purchase_val",
                          prefexLabel: "Per",
                        },
                      ],
                    },
                  },
                  {
                    id: "cba8d1ed-0512-462a-9bc3-f3526c057639",
                    name: "Require Additional Metadata (LATAM)",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        metadata_required: {
                          description: "Require additonal metadata for widget",
                          default: true,
                          title: "Additional Metadata Required",
                          type: "boolean",
                        },
                      },
                      required: ["metadata_required"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "latam-metadata-required",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/metadata_required",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "a93be1cb-83bd-4eb5-8416-f825dc4df26d",
                    name: "Award Points",
                    slug: "latam-award-points",
                    description: "Awards points to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["latam-calculate-points"],
                    rules: [
                      {
                        id: "925debb2-41cd-4eef-b0da-f97b5141286b",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Points",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "latam-calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    id: "fa008156-eb34-4db2-b360-6d6c966ed647",
                    name: "Sweepstakes Entry (LATAM)",
                    slug: "latam-award-sweepstakes-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        sweepstakes: {
                          title: "Selected Sweepstake",
                        },
                        entries: {
                          title: "Number of Entries to Award",
                          type: "number",
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["target", "entries"],
                    },
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "sweepstakesSelect",
                          scope: "#/properties/target_id",
                        },
                        {
                          child_class_name: "ml-48",
                          rule: {
                            condition: {
                              schema: {
                                type: "string",
                                not: {
                                  enum: [null, ""],
                                },
                              },
                              scope: "#/properties/target_id",
                            },
                            effect: "SHOW",
                          },
                          className: "sweepstake-custom-styles",
                          type: "Control",
                          maxLength: 5,
                          scope: "#/properties/entries",
                        },
                      ],
                    },
                    requiredRules: [],
                    rules: [],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: ["activeEndDate", "title", "activeStartDate"],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
              },
              required: ["title", "activeStartDate"],
            },
          },
          contentSteps: [
            {
              id: "2e356581-3500-423a-a634-da366610a0ff",
              name: "Upload Receipt (LATAM)",
              slug: "latam-upload-receipt-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      cta_label: {
                        title: "CTA Label",
                        type: "string",
                        minLength: 1,
                      },
                      instructions_link: {
                        pattern:
                          "^(?:https?:\\/\\/(?:www\\.)?[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(?:\\/[^\\s]*)?)?$",
                        message: 'must match pattern "URI"',
                        title: "Instructions URL",
                        type: "string",
                      },
                      terms_and_conditions_text: {
                        title: "Terms & Conditions Text",
                        type: "string",
                      },
                      input_placeholder: {
                        title: "Input Placeholder",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Image",
                        type: "string",
                      },
                      input_label: {
                        title: "Input Label",
                        type: "string",
                        minLength: 1,
                      },
                      instructions_text: {
                        title: "Instructions Text",
                        type: "string",
                      },
                      terms_and_conditions_link: {
                        pattern:
                          "^(?:https?:\\/\\/(?:www\\.)?[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(?:\\/[^\\s]*)?)?$",
                        message: 'must match pattern "URI"',
                        title: "Terms & Conditions URL",
                        type: "string",
                      },
                    },
                    required: ["input_label", "cta_label"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["pt-br"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "pt-br"],
                    },
                    properties: {
                      "pt-br": {
                        $ref: "#/definitions/localized_content",
                      },
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/input_placeholder",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/cta_label",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions_link",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_text",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/instructions_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "latam-upload-receipt-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "sweepstakes",
        value: {
          enabled: true,
          label: "Sweepstakes",
          image:
            "https://pepsicotest.getbynder.com/m/56244251ed4e41d6/original/content_aa534b6a-837c-4efa-bc0c-59eee1cb4d2c.png",
          categories: [
            "Bundle",
            "Gift Cards",
            "High Value",
            "Low Value",
            "Medium Value",
            "Trips/Experiences",
          ],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["sweepstakes"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "f589279a-12f8-409f-b90c-6d35482cb9e4",
                name: "Enter Sweepstakes",
                description: "Buy sweepstakes entries",
                inputSchema: {},
                uiSchema: {},
                requiredRules: ["require-phone", "exclude-email"],
                requiredOutcomes: ["purchase-entry"],
                slug: "sweepstakes",
                maxAllowed: 1,
                rules: [
                  {
                    id: "0190c76a-8bf4-7775-bca7-93d959bcc1b4",
                    name: "Require user phone number",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        require_phone: {
                          description: "Require user profile to have phone-number",
                          default: true,
                          title: "Phone Number Required",
                          type: "boolean",
                        },
                      },
                      required: ["require_phone"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "require-phone",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/require_phone",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190c89b-22d5-71b8-b100-572dca435352",
                    name: "Exclude emails",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        domains: {
                          description:
                            "Comma separated values of domains that will be denied",
                          title: "Domains to exclude",
                          type: "array",
                          items: {
                            type: "string",
                          },
                        },
                        exclude: {
                          title: "Exclude certain domains from winners",
                          type: "boolean",
                          default: false,
                        },
                      },
                      required: ["exclude"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "exclude-email",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/exclude",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/exclude",
                            },
                            effect: "ENABLE",
                          },
                          component: "emailDomainsComponent",
                          placeholder: "@pepsico.com, @domain.com",
                          type: "Control",
                          scope: "#/properties/domains",
                          info: "Everyone can join sweeps, but the domains will be excluded only from winner selection process",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "5915a658-199f-4d2e-9b20-2e36b9e51029",
                    name: "Sweepstakes Purchase",
                    slug: "purchase-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        winners_required: {
                          description:
                            "Maximum number of winners that the sweepstakes can have",
                          default: 1,
                          title: "Maximum number of winners",
                          type: "integer",
                          minimum: 1,
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        source: {
                          const: "points",
                          hidden: true,
                          type: "string",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["winners_required", "target", "source"],
                    },
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/winners_required",
                          label: "Maximum number of winners",
                        },
                      ],
                    },
                    requiredRules: ["calculate-cost"],
                    rules: [
                      {
                        id: "0190c8aa-d861-77f9-a3f6-b4cd64e77fd9",
                        name: "Sweepstakes Entry Cost",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            cost: {
                              default: 1,
                              title: "Entry's price",
                              type: "integer",
                              minimum: 1,
                            },
                          },
                          required: ["cost"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-cost",
                        uiSchema: {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/properties/cost",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "categorySelect",
                              label: "Category",
                              type: "Control",
                              scope: "#/properties/category",
                            },
                            {
                              component: "brandSelect",
                              label: "Brand",
                              type: "Control",
                              scope: "#/properties/brands",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "category",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                category: {
                  title: "Category",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "category"],
            },
          },
          contentSteps: [
            {
              id: "52dee9da-24ba-4d23-877f-dde00733f9b8",
              name: "Sweepstakes Campaign Content Definition",
              slug: "sweepstakes-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Sweepstakes Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      terms_and_conditions: {
                        format: "uri",
                        description:
                          "URL to consumer terms and conditions to participate in the campaign",
                        title: "Terms and Conditions URL",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: [
                      "title",
                      "description",
                      "campaign_image_url",
                      "terms_and_conditions",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                l10n: {
                  className: "content-language-wrapper",
                  title: "Content*",
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "SimpleGroup",
                      className: "content-language-wrapper__content",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              options: {
                                format: "data-url",
                              },
                              component: "singleImageUpload",
                              type: "Control",
                              scope:
                                "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                            },
                            {
                              type: "VerticalLayout",
                              elements: [
                                {
                                  type: "VerticalLayout",
                                  elements: [
                                    {
                                      type: "Control",
                                      maxLength: 150,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                    },
                                    {
                                      component: "textarea",
                                      type: "Control",
                                      maxLength: 500,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                    },
                                    {
                                      type: "Control",
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "sweepstakes-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "take_a_survey",
        value: {
          enabled: true,
          label: "Take a Survey",
          image:
            "https://pepsicotest.getbynder.com/m/171682b1a76ade55/original/content_2daf6d01-bf02-48f1-ab38-e33c0fda4dbb.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["take-a-survey"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "69d737c1-59f3-4eaa-97a1-ee87f6288956",
                name: "Take a Survey",
                description: "Awards entries after a user takes a survey.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "array",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["match-survey-ids"],
                requiredOutcomes: ["award-points"],
                slug: "take-a-survey",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "eb5cc6c8-7352-48b9-9e2d-6cf040f501e7",
                    name: "Match Survey Ids",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        survey_ids: {
                          title: "Survey IDs",
                          type: "array",
                        },
                      },
                      required: ["survey_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 0,
                    slug: "match-survey-ids",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "singleInputArray",
                          scope: "#/properties/survey_ids",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "9a523e11-e739-4bca-80af-1e3b9dcfb53f",
              name: "Take a Survey campaign content definition",
              slug: "take-a-survey-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Take a Survey Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      survey_link: {
                        format: "uri",
                        title: "Survey Link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: [
                      "title",
                      "description",
                      "campaign_image_url",
                      "survey_link",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/survey_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "take-a-survey-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "code_redemption",
        value: {
          enabled: true,
          label: "Code Redemption",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["unlimited-code"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "296cc101-c581-4f65-9a6f-d5a92d32fdda",
                name: "Unlimited Code",
                description: "Awards entries after a user enters an unlimited code.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                uiSchema: null,
                requiredRules: ["match-unlimited-code"],
                requiredOutcomes: ["award-points"],
                slug: "unlimited-code",
                maxAllowed: 1,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "018fa743-e430-7b8f-9093-1e7f197e6401",
                    name: "Match Unlimited Code",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code: {
                          title: "Unlimited Code",
                          type: "string",
                        },
                      },
                      required: ["code"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-unlimited-code",
                    uiSchema: {
                      type: "VerticalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/code",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "eb979194-6843-4608-bcac-c8e20ac89fef",
              name: "Code Redemption campaign content definition",
              slug: "code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Code Redemption (unlimited) Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 50,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    type: "Control",
                                    component: "textarea",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "support",
        value: {
          enabled: false,
          label: "Support Campaign (Do not use)",
          image:
            "https://pepsicotest.getbynder.com/m/7158fd8753d4f38e/original/content_b8e13709-5c34-425b-be9c-1d0fd51a2408.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["support-activity"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "aafa08c2-a9a1-46f9-a942-4cad9f04fae2",
                name: "Support Activity",
                description: "Awards entries when a user completes their profile.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: ["support-rule"],
                requiredOutcomes: ["support-outcome"],
                slug: "support-activity",
                maxAllowed: null,
                rules: [
                  {
                    id: "27917fd1-fa20-4af3-82f8-d2626a360a4b",
                    name: "Support Rule (will always return false)",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {},
                      required: [],
                    },
                    ruleFunction: "calculate",
                    maxAllowed: null,
                    slug: "support-rule",
                    uiSchema: {},
                  },
                ],
                outcomes: [
                  {
                    id: "cbf9da41-6dcb-418b-b8ef-e517f352c836",
                    name: "Award Entries",
                    slug: "support-outcome",
                    description: "Support Campaign Outcome",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: [],
                    rules: [],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: null,
            inputSchema: null,
          },
          contentSteps: [
            {
              id: "cdd08f4d-b16e-4907-8577-d36ff0aac7fb",
              name: "Support Campaign Content Definition",
              slug: "support-campaign-content",
              version: 1,
              description: "Definition of support campaign content",
              active: true,
              inputSchema: {
                additionalProperties: false,
                $schema: "http://json-schema.org/draft-07/schema#",
                type: "object",
                properties: {
                  title: {
                    type: "string",
                  },
                },
                required: [],
              },
              uiSchema: {},
            },
          ],
          steps: [],
        },
      },
      {
        key: "complete_your_profile",
        value: {
          enabled: true,
          label: "Complete your Profile",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["profile-completion"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "504db4db-fe5e-427e-ae82-a7b20fd9904b",
                name: "Profile Completion",
                description: "Awards entries when a user completes their profile.",
                inputSchema: {},
                uiSchema: null,
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "profile-completion",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0117d7bd-e351-4e20-aac9-057a6f37c049",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                rulesConfiguration: null,
              },
            ],
            possibleRules: [
              {
                id: "e94f1c08-944a-40d1-a3e0-64534ed51f7a",
                name: "Qualified Segments",
                hidden: false,
                inputSchema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    segment_ids: {
                      minItems: 1,
                      title: "ids of the segments",
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: ["segment_ids"],
                },
                ruleFunction: "validate",
                maxAllowed: 100,
                slug: "user-in-segment",
                uiSchema: {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      component: "segmentSelect",
                      scope: "#/properties/segment_ids",
                    },
                  ],
                },
              },
            ],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/endless",
                                    },
                                    effect: "DISABLE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "0266d30c-f58e-4099-8ffc-78fda04d49b4",
              name: "Complete your Profile campaign content definition",
              slug: "complete-your-profile-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Complete your Profile Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["es-mx", "fr-ca", "en-us"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "complete-your-profile-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
    ],
    widgets: [
      {
        id: "14e73dc8-bce3-47b5-87aa-2140b3457ceb",
        embedCode:
          '<pep-loyalty widget-id="14e73dc8-bce3-47b5-87aa-2140b3457ceb" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX - Reward History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T21:06:30.023823876Z",
        updatedAt: "2024-08-16T17:07:09.584046296Z",
      },
      {
        id: "894e5731-2ed3-4282-a483-3864b5a30e52",
        embedCode:
          '<pep-loyalty widget-id="894e5731-2ed3-4282-a483-3864b5a30e52" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX_Quaker - Activity History - Copy",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-20T14:31:49.399761097Z",
        updatedAt: "2024-08-20T14:31:49.399761097Z",
      },
      {
        id: "b34c5222-ebef-40cb-82eb-97dca3a8f7ed",
        embedCode:
          '<pep-loyalty widget-id="b34c5222-ebef-40cb-82eb-97dca3a8f7ed" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX_Quaker - Reward History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-20T14:32:43.111294366Z",
        updatedAt: "2024-08-21T17:16:01.825167191Z",
      },
      {
        id: "b9ed32f7-3bd4-47d0-a82e-6b78a2d2358e",
        embedCode:
          '<pep-loyalty widget-id="b9ed32f7-3bd4-47d0-a82e-6b78a2d2358e" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Recipes_Life Front Page",
        isActive: true,
        widgetData: {
          selectedCampaigns: [],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 4,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-13T00:01:57.609247697Z",
        updatedAt: "2024-08-22T19:10:29.220428067Z",
      },
      {
        id: "5a5a4119-9b78-4ce4-8651-94dc5144b70a",
        embedCode:
          '<pep-loyalty widget-id="5a5a4119-9b78-4ce4-8651-94dc5144b70a" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "BAM_SANDBOX - Activity History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T21:07:13.676280672Z",
        updatedAt: "2024-08-23T19:53:58.285208805Z",
      },
      {
        id: "c3e5cb64-2b76-4890-9f2e-31e8bc7e3d1e",
        embedCode:
          '<pep-loyalty widget-id="c3e5cb64-2b76-4890-9f2e-31e8bc7e3d1e" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX_FLVP_BTS - Featured Activities",
        isActive: true,
        widgetData: {
          selectedCampaigns: [],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||fireSignUp()",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "method||fireSignUp()",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 24,
        createdAt: "2024-08-20T14:34:07.756838708Z",
        updatedAt: "2024-09-30T10:53:06.645758720Z",
      },
      {
        id: "9e8bd2a1-f29d-46da-a27e-becbe7e56a75",
        embedCode:
          '<pep-loyalty widget-id="9e8bd2a1-f29d-46da-a27e-becbe7e56a75" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Activity Completion Pop-up",
        isActive: true,
        widgetData: {
          selectedCampaigns: [],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-13T00:05:24.120064358Z",
        updatedAt: "2024-10-31T17:53:12.817926633Z",
      },
      {
        id: "6a4b24df-bcdc-414a-afd3-a93faa6cd495",
        embedCode:
          '<pep-loyalty widget-id="6a4b24df-bcdc-414a-afd3-a93faa6cd495" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "BAM_SANDBOX - Rewards List",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 2400,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 9,
        createdAt: "2024-08-13T00:03:33.687978750Z",
        updatedAt: "2024-11-18T19:23:41.620675692Z",
      },
      {
        id: "d2c5e913-ddfa-42f4-8d55-c73a172f2187",
        embedCode:
          '<pep-loyalty widget-id="d2c5e913-ddfa-42f4-8d55-c73a172f2187" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Featured Activities",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "b3dc3f12-274f-4aa5-80e7-656dabbc1eb0",
            "4c915686-6d10-410b-9a22-3f1fb431ec4c",
            "f50348b1-e4dc-4da9-b95e-c716f25c70a7",
            "e57d366d-9827-40ce-b26c-4efdc4521ba3",
            "78aaf434-d9f4-4943-bbeb-f983d4b5d3fd",
          ],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||fireSignUp()",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "method||fireSignUp()",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 5,
        createdAt: "2024-08-12T23:55:25.833935867Z",
        updatedAt: "2024-12-26T18:03:51.233446448Z",
      },
      {
        id: "46b459a6-1ba0-41b7-9e3e-0169a8732682",
        embedCode:
          '<pep-loyalty widget-id="46b459a6-1ba0-41b7-9e3e-0169a8732682" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - Quaker Logged Out Activity List",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "dc36c9c5-e295-41c1-b95c-f36a3b7c4b3d",
            "79776ff7-15bf-4459-abd0-59d4e83af2aa",
            "78aaf434-d9f4-4943-bbeb-f983d4b5d3fd",
            "40c4bab8-fd38-4687-b9ad-7c1ca6a1a0a7",
            "e10eb37b-2197-41d9-9d9a-2b43576816dc",
          ],
          buttonColor: "#032955",
          format: "GRID",
          descriptionColor: "#032955",
          visibleNonMembers: true,
          buttonTextHoverColor: "#FFFFFF",
          textColor: "#032955",
          buttonHoverColor: "#C5A785",
          completedColor: "#00944a",
          itemsPerRow: 4,
          titleColor: "#032955",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||fireSignUp()",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "method||fireSignUp()",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "1cb552dc-1d5c-4eb6-8bea-0583d44e1356",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#032955",
        },
        selectedCampaignsSize: 5,
        createdAt: "2024-08-29T20:28:39.774675972Z",
        updatedAt: "2025-01-08T12:12:08.082462743Z",
      },
      {
        id: "26b83940-ae62-4748-aa0f-805a47011a75",
        embedCode:
          '<pep-loyalty widget-id="26b83940-ae62-4748-aa0f-805a47011a75" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PTR Member Information Widget - Selected Components",
        isActive: false,
        widgetData: {
          periodStartDate: "2025-01-10",
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2025-01-08T06:17:10.893292926Z",
        updatedAt: "2025-01-09T11:18:46.902971851Z",
      },
      {
        id: "3508657a-a217-4a58-8d7d-e3b58af7377a",
        embedCode:
          '<pep-loyalty widget-id="3508657a-a217-4a58-8d7d-e3b58af7377a" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PTR Member Information Widget - Selected Components",
        isActive: true,
        widgetData: {
          periodStartDate: "2025-01-09",
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: false,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2025-01-09T12:19:13.118825535Z",
        updatedAt: "2025-01-09T12:19:13.118825535Z",
      },
      {
        id: "7b169b37-cf3b-4085-bbf9-42249b4f4d2c",
        embedCode:
          '<pep-loyalty widget-id="7b169b37-cf3b-4085-bbf9-42249b4f4d2c" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PTR Member Information Widget - Selected Components1",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: false,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2025-01-09T12:20:34.829032845Z",
        updatedAt: "2025-01-09T12:20:34.829032845Z",
      },
      {
        id: "58b5eed5-4e9d-4220-bb8c-81e8fe0057e3",
        embedCode:
          '<pep-loyalty widget-id="58b5eed5-4e9d-4220-bb8c-81e8fe0057e3" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PTR Member Information Widget - Selected Components",
        isActive: true,
        widgetData: {
          periodStartDate: "2025-01-08",
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2025-01-09T12:20:54.635931677Z",
        updatedAt: "2025-01-09T12:20:54.635931677Z",
      },
      {
        id: "a3e9e8cd-d1d6-4c02-8300-253d01b35f1d",
        embedCode:
          '<pep-loyalty widget-id="a3e9e8cd-d1d6-4c02-8300-253d01b35f1d" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX_Quaker - All Activity List",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "f50348b1-e4dc-4da9-b95e-c716f25c70a7",
            "4c915686-6d10-410b-9a22-3f1fb431ec4c",
            "e57d366d-9827-40ce-b26c-4efdc4521ba3",
            "78aaf434-d9f4-4943-bbeb-f983d4b5d3fd",
            "dc36c9c5-e295-41c1-b95c-f36a3b7c4b3d",
            "40c4bab8-fd38-4687-b9ad-7c1ca6a1a0a7",
            "15c99288-7147-41a4-b579-13a16f3d30b0",
            "075371e4-280e-4c08-87b3-3d00c08be388",
            "985e2d93-f6a8-4c87-a2db-538a14cb2a13",
            "0daa1a19-e908-4ddf-9454-e33b978d5520",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 4,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE YOUR ACCOUNT",
              },
            },
          },
          maxHeight: 256,
          brandId: "7e7f1cce-4797-475d-b482-8561b7f6b4cb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 10,
        createdAt: "2024-08-15T20:14:31.767191656Z",
        updatedAt: "2025-01-22T13:46:31.383281176Z",
      },
      {
        id: "273eb7f9-c8af-46b6-afa0-a7fafee36512",
        embedCode:
          '<pep-loyalty widget-id="273eb7f9-c8af-46b6-afa0-a7fafee36512" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "BAM_SANDBOX - All Activity List",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "477df58b-9b47-457e-898c-9589315ead60",
            "4c915686-6d10-410b-9a22-3f1fb431ec4c",
            "f50348b1-e4dc-4da9-b95e-c716f25c70a7",
            "e57d366d-9827-40ce-b26c-4efdc4521ba3",
            "78aaf434-d9f4-4943-bbeb-f983d4b5d3fd",
            "a28dd3f2-d66d-4a5c-a360-db147164cff4",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 2400,
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 6,
        createdAt: "2024-08-13T00:04:30.077422971Z",
        updatedAt: "2025-01-23T21:19:59.786295699Z",
      },
      {
        id: "80204727-992d-4467-a0cc-f8a60bf4b924",
        embedCode:
          '<pep-loyalty widget-id="80204727-992d-4467-a0cc-f8a60bf4b924" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Bug AA",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "1d9c8ae1-bf4d-4b42-a7bd-ce1ad69de539",
            "6e5c5577-7bd2-42a3-845a-c0a2840df223",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "Test BUG CTA",
              },
              "en-us": {
                url: "www.google.com",
                text: "Test BUG CTA",
              },
            },
          },
          brandId: "",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 2,
        createdAt: "2025-01-15T08:24:37.829405709Z",
        updatedAt: "2025-03-05T06:05:56.176336524Z",
      },
      {
        id: "d88afb06-c5b6-4027-9ec9-b6ec88305f02",
        embedCode:
          '<pep-loyalty widget-id="d88afb06-c5b6-4027-9ec9-b6ec88305f02" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "BAM_SANDBOX_Quaker - Rewards List - Copy",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["23422504-4f85-493f-b30d-faafd2b9e521"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "https://www.tastyrewards.com/es-us?signup=true",
                text: "CREA TU CUENTA",
              },
              "en-us": {
                url: "https://www.tastyrewards.com/en-us?signup=true",
                text: "CREATE MY ACCOUNT",
              },
            },
          },
          maxHeight: 2400,
          brandId: "7e7f1cce-4797-475d-b482-8561b7f6b4cb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-20T14:30:24.402356528Z",
        updatedAt: "2025-03-05T06:16:01.801992862Z",
      },
    ],
    widgetDefinitions: [
      {
        id: "2d5c26fc-76cd-416d-ad83-82f21a6207d8",
        title: "List of campaigns",
        version: 1,
        slug: "campaigns",
        inputSchema: {
          title: "List of Campaigns",
          type: "object",
          properties: {
            selectedCampaigns: {
              type: "array",
            },
            buttonColor: {
              type: "string",
            },
            format: {
              enum: ["GRID", "CAROUSEL"],
            },
            descriptionColor: {
              type: "string",
            },
            visibleNonMembers: {
              type: "boolean",
            },
            buttonTextHoverColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            buttonHoverColor: {
              type: "string",
            },
            completedColor: {
              type: "string",
            },
            itemsPerRow: {
              type: "number",
              enum: [3, 4],
            },
            titleColor: {
              type: "string",
            },
            unauthenticated: {
              type: "object",
              properties: {
                cta: {
                  type: "object",
                  properties: {
                    "es-mx": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL Spanish",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text Spanish",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                    "en-us": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL English",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text English",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                  },
                },
              },
            },
            maxHeight: {
              type: "number",
            },
            cardBgColor: {
              type: "string",
            },
            buttonTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            comingSoonColor: {
              type: "string",
            },
          },
          dependencies: {
            customWidget: {
              oneOf: [
                {
                  properties: {
                    customWidget: {
                      const: true,
                    },
                  },
                  required: ["maxHeight"],
                },
                {
                  properties: {
                    customWidget: {
                      const: false,
                    },
                  },
                },
              ],
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              tooltipDirection: "right",
              tooltipText:
                "Inform the button CTA and redirect link for non-authenticated experience",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      component: "toggle",
                      label: "Widget is visible by non members",
                      type: "Control",
                      scope: "#/properties/visibleNonMembers",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text English*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL English*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text Spanish*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL Spanish*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
              ],
              className: "setup-non-members-group",
              rules: [
                {
                  targets: [
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                  ],
                  condition: {
                    expectedValue: true,
                    scope: "#/properties/visibleNonMembers",
                  },
                  effect: "MAKE_REQUIRED",
                },
              ],
              label: "Button Setup for Non Members",
              tooltipIcon: "info",
              type: "SimpleGroup",
            },
            {
              className: "custom-preview-group",
              page: "Campaign",
              type: "simpleGroupPreviewCards",
              elements: [
                {
                  className: "horizontal-layout-3",
                  label: "Format",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          CAROUSEL: "Show in horizontal carousel",
                          GRID: "Show in a grid pattern",
                        },
                      },
                      component: "radioInput",
                      type: "Control",
                      scope: "#/properties/format",
                    },
                  ],
                },
                {
                  className: "horizontal-layout-4",
                  label: "Widget Card Styling",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Widget",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
                {
                  rule: {
                    condition: {
                      schema: {
                        const: false,
                      },
                      scope: "#/properties/customWidget",
                    },
                    effect: "HIDE",
                  },
                  className: "group-bg-gray",
                  label: "",
                  type: "SimpleGroup",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-5",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              3: "3 cards per row",
                              4: "4 cards per row",
                            },
                          },
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "radioInput",
                          type: "Control",
                          scope: "#/properties/itemsPerRow",
                        },
                        {
                          tooltipDirection: "right",
                          component: "numberInput",
                          tooltipText:
                            "Set a maximum height for this element to prevent it from growing beyond that limit.",
                          scope: "#/properties/maxHeight",
                          rule: {
                            condition: {
                              conditions: [
                                {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                {
                                  schema: {
                                    const: "GRID",
                                  },
                                  scope: "#/properties/format",
                                },
                              ],
                              type: "AND",
                            },
                            effect: "ENABLE",
                          },
                          label: "Max height",
                          placeholder: "Insert value in pixels",
                          tooltipIcon: "info",
                          type: "Control",
                          maxLength: 4,
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Card",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Card Background",
                          type: "Control",
                          scope: "#/properties/cardBgColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Campaign Title",
                          type: "Control",
                          scope: "#/properties/titleColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Coming Soon",
                          type: "Control",
                          scope: "#/properties/comingSoonColor",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-hexacolor",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Secondary Texts",
                          type: "Control",
                          scope: "#/properties/textColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Campaign Description",
                          type: "Control",
                          scope: "#/properties/descriptionColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Completed",
                          type: "Control",
                          scope: "#/properties/completedColor",
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Button",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Background",
                          type: "Control",
                          scope: "#/properties/buttonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Text",
                          type: "Control",
                          scope: "#/properties/buttonTextColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Hover",
                          type: "Control",
                          scope: "#/properties/buttonHoverColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Text Hover",
                          type: "Control",
                          scope: "#/properties/buttonTextHoverColor",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: "5ef663bf-f0d6-44f1-8a37-007fff84478f",
        title: "List of member information",
        version: 1,
        slug: "member-information",
        inputSchema: {
          title: "Member Information",
          type: "object",
          properties: {
            periodStartDate: {
              format: "date",
              title: "Period start date",
              type: "string",
            },
            textMainColor: {
              title: "Text main color",
              type: "string",
            },
            iconMainColor: {
              title: "Icon main color",
              type: "string",
            },
            showMemberSignupDate: {
              title: "Show member signup date",
              type: "boolean",
            },
            textHighlightColor: {
              title: "Text highlight color",
              type: "string",
            },
            showDateBalanceUpdated: {
              title: "Show date balance updated",
              type: "boolean",
            },
            showIcons: {
              title: "Show icons",
              type: "boolean",
            },
            showWelcomeMessage: {
              title: "Show welcome message",
              type: "boolean",
            },
            showMemberBalance: {
              title: "Show member balance",
              type: "boolean",
            },
            iconHighlightColor: {
              title: "Icon highlight color",
              type: "string",
            },
            customWidget: {
              title: "Custom widget",
              type: "boolean",
            },
            showNumberCompletedActivities: {
              title: "Show number of completed activities",
              type: "boolean",
            },
            activitiesPeriod: {
              title: "Activities period",
              type: "string",
              enum: ["ALL_ACTIVITIES", "FROM_SPECIFIC_DATE"],
            },
            showNumberActivitiesToComplete: {
              title: "Show number of activities to complete",
              type: "boolean",
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "specifications-group",
              label: "Widget Component Specifications",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showWelcomeMessage",
                    },
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showNumberCompletedActivities",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showWelcomeMessage",
                        },
                        effect: "DISABLE",
                      },
                      component: "checkbox",
                      label: "Show member signup date",
                      type: "Control",
                      scope: "#/properties/showMemberSignupDate",
                    },
                    {
                      options: {
                        labelMapping: {
                          ALL_ACTIVITIES: "All activities",
                          FROM_SPECIFIC_DATE: "From a specific date",
                        },
                      },
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showNumberCompletedActivities",
                        },
                        effect: "DISABLE",
                      },
                      component: "radioInput",
                      label: false,
                      type: "Control",
                      scope: "#/properties/activitiesPeriod",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: "FROM_SPECIFIC_DATE",
                          },
                          scope: "#/properties/activitiesPeriod",
                        },
                        effect: "SHOW",
                      },
                      component: "datePickerMaterialUI",
                      label: false,
                      type: "Control",
                      scope: "#/properties/periodStartDate",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-3",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showMemberBalance",
                    },
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showNumberActivitiesToComplete",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-4",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showMemberBalance",
                        },
                        effect: "DISABLE",
                      },
                      component: "checkbox",
                      label: "Show date when balance was last updated",
                      type: "Control",
                      scope: "#/properties/showDateBalanceUpdated",
                    },
                  ],
                },
              ],
            },
            {
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-5",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Styling",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "toggle",
                      type: "Control",
                      scope: "#/properties/showIcons",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "HIDE",
                      },
                      label: "Icons",
                      type: "SimpleGroup",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          className: "horizontal-layout-hexacolor",
                          elements: [
                            {
                              rule: {
                                condition: {
                                  conditions: [
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/customWidget",
                                    },
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/showIcons",
                                    },
                                  ],
                                  type: "AND",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Image*",
                              type: "Control",
                              scope: "#/properties/iconMainColor",
                            },
                            {
                              rule: {
                                condition: {
                                  conditions: [
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/customWidget",
                                    },
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/showIcons",
                                    },
                                  ],
                                  type: "AND",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Circle*",
                              type: "Control",
                              scope: "#/properties/iconHighlightColor",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "HIDE",
                      },
                      label: "Bar Items",
                      type: "SimpleGroup",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          className: "horizontal-layout-hexacolor",
                          elements: [
                            {
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Fixed content*",
                              type: "Control",
                              scope: "#/properties/textMainColor",
                            },
                            {
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Dynamic content*",
                              type: "Control",
                              scope: "#/properties/textHighlightColor",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Widget Styling",
            },
          ],
        },
      },
      {
        id: "cdfe074f-377b-45d9-bb73-ab185dac206e",
        title: "List of member history",
        version: 1,
        slug: "member-history",
        inputSchema: {
          title: "Member History",
          type: "object",
          properties: {
            headerTextColor: {
              type: "string",
            },
            pointsTextColor: {
              type: "string",
            },
            historyType: {
              type: "string",
              enum: ["SWEEPSTAKES_HISTORY", "ACTIVITY_HISTORY"],
            },
            maxHeight: {
              type: "number",
            },
            rowTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            headerRowColor: {
              type: "string",
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "specifications-group",
              label: "History Type",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          ACTIVITY_HISTORY: "Activity history",
                          SWEEPSTAKES_HISTORY: "Sweepstake history",
                        },
                      },
                      component: "radioInput",
                      label: false,
                      type: "Control",
                      scope: "#/properties/historyType",
                    },
                  ],
                },
              ],
            },
            {
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-custom-widget",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Styling",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
              ],
              label: "Widget Styling",
            },
            {
              type: "SimpleGroup",
              rule: {
                condition: {
                  schema: {
                    const: false,
                  },
                  scope: "#/properties/customWidget",
                },
                effect: "HIDE",
              },
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Header row*",
                      type: "Control",
                      defaultValue: "#000000",
                      scope: "#/properties/headerRowColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Header text*",
                      type: "Control",
                      scope: "#/properties/headerTextColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Row text*",
                      type: "Control",
                      scope: "#/properties/rowTextColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Entries text*",
                      type: "Control",
                      scope: "#/properties/pointsTextColor",
                    },
                    {
                      tooltipDirection: "left",
                      component: "numberInput",
                      tooltipText:
                        "Set a maximum height for this element to prevent it from growing beyond that limit.",
                      scope: "#/properties/maxHeight",
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      label: "Max height*",
                      placeholder: "Insert value in pixels",
                      tooltipIcon: "info",
                      type: "Control",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: "ec71f530-5662-4a3a-9d2a-5aa9f60a2e55",
        title: "List of sweepstakes",
        version: 1,
        slug: "sweepstakes",
        inputSchema: {
          title: "List of Sweepstakes",
          type: "object",
          properties: {
            warningColor: {
              type: "string",
            },
            selectedCampaigns: {
              type: "array",
            },
            buttonColor: {
              type: "string",
            },
            format: {
              enum: ["GRID", "CAROUSEL"],
            },
            visibleNonMembers: {
              type: "boolean",
            },
            buttonTextHoverColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            buttonHoverColor: {
              type: "string",
            },
            itemsPerRow: {
              type: "number",
              enum: [3, 4],
            },
            titleColor: {
              type: "string",
            },
            unauthenticated: {
              type: "object",
              properties: {
                cta: {
                  type: "object",
                  properties: {
                    "es-mx": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL Spanish",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text Spanish",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                    "en-us": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL English",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text English",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                  },
                },
              },
            },
            maxHeight: {
              type: "number",
            },
            cardBgColor: {
              type: "string",
            },
            buttonTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            comingSoonColor: {
              type: "string",
            },
          },
          dependencies: {
            customWidget: {
              oneOf: [
                {
                  properties: {
                    customWidget: {
                      const: true,
                    },
                  },
                  required: ["maxHeight"],
                },
                {
                  properties: {
                    customWidget: {
                      const: false,
                    },
                  },
                },
              ],
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              tooltipDirection: "right",
              tooltipText:
                "Inform the button CTA and redirect link for non-authenticated experience",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      component: "toggle",
                      label: "Widget is visible by non members",
                      type: "Control",
                      scope: "#/properties/visibleNonMembers",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text English*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL English*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text Spanish*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL Spanish*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
              ],
              className: "setup-non-members-group",
              rules: [
                {
                  targets: [
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                  ],
                  condition: {
                    expectedValue: true,
                    scope: "#/properties/visibleNonMembers",
                  },
                  effect: "MAKE_REQUIRED",
                },
              ],
              label: "Button Setup for Non Members",
              tooltipIcon: "info",
              type: "SimpleGroup",
            },
            {
              className: "custom-preview-group",
              page: "Sweepstakes",
              type: "simpleGroupPreviewCards",
              elements: [
                {
                  className: "horizontal-layout-3",
                  label: "Format",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          CAROUSEL: "Show in horizontal carousel",
                          GRID: "Show in a grid pattern",
                        },
                      },
                      component: "radioInput",
                      type: "Control",
                      scope: "#/properties/format",
                    },
                  ],
                },
                {
                  className: "horizontal-layout-4",
                  label: "Widget Card Styling",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Widget",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
                {
                  rule: {
                    condition: {
                      schema: {
                        const: false,
                      },
                      scope: "#/properties/customWidget",
                    },
                    effect: "HIDE",
                  },
                  className: "group-bg-gray",
                  label: "",
                  type: "SimpleGroup",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-5",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              3: "3 cards per row",
                              4: "4 cards per row",
                            },
                          },
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "radioInput",
                          type: "Control",
                          scope: "#/properties/itemsPerRow",
                        },
                        {
                          tooltipDirection: "right",
                          component: "numberInput",
                          tooltipText:
                            "Set a maximum height for this element to prevent it from growing beyond that limit.",
                          scope: "#/properties/maxHeight",
                          rule: {
                            condition: {
                              conditions: [
                                {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                {
                                  schema: {
                                    const: "GRID",
                                  },
                                  scope: "#/properties/format",
                                },
                              ],
                              type: "AND",
                            },
                            effect: "ENABLE",
                          },
                          label: "Max height",
                          placeholder: "Insert value in pixels",
                          tooltipIcon: "info",
                          type: "Control",
                          maxLength: 4,
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Card",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Card Background",
                          type: "Control",
                          scope: "#/properties/cardBgColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Sweepstakes Title",
                          type: "Control",
                          scope: "#/properties/titleColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Coming Soon",
                          type: "Control",
                          scope: "#/properties/comingSoonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Secondary Texts",
                          type: "Control",
                          scope: "#/properties/textColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Warning Message",
                          type: "Control",
                          scope: "#/properties/warningColor",
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Button",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Background",
                          type: "Control",
                          scope: "#/properties/buttonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Text",
                          type: "Control",
                          scope: "#/properties/buttonTextColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Hover",
                          type: "Control",
                          scope: "#/properties/buttonHoverColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Text Hover",
                          type: "Control",
                          scope: "#/properties/buttonTextHoverColor",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
