import { useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, LoadingSpinner, ToastContainer, Toggle } from "pepsico-ds";
import { useCallback, useEffect, useState } from "react";
import {
  CreateCodeGroupMutation,
  UpdateCodeGroupMutation,
} from "../../graphql/mutations/codeGroupMutations";
import {
  CODE_GROUP_ADMINISTRATION_STATUS,
  CODE_GROUP_CREATION_TYPE,
  CODE_GROUP_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { usePartnerCodeGroupForm } from "../partners/Modals/PartnerCodeGroupForm/usePartnerCodeGroupForm";
import { jsonSchemaForPtr, uiSchemaForPtr } from "./codeGroupUtils";
import "./createCodeGroupModal.scss";

/**
 * CodeGroupCommonSetupModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @params {function} props.refetchListing - Function to refetch the listing.
 * @params {boolean} props.loadCodeGroupLoader - Flag indicating whether the code group data is loading.
 * @params {Object} props.codeGroupData - The code group data.
 * @params {function} props.setCodeGroupData - Function to set the code group data
 * @returns {JSX.Element} The CodeGroupCommonSetupModal component.
 */
export default function CodeGroupCommonSetupModal({
  showModal,
  setShowModal,
  setToast,
  refetchListing,
  loadCodeGroupLoader,
  codeGroupData,
  setCodeGroupData,
}) {
  const [alreadyCreatedData, setAlreadyCreatedData] = useState({});
  const [modalToast, setModalToast] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [executeUpdateCodeGroup] = useMutation(UpdateCodeGroupMutation);
  const [executeCreateCodeGroup] = useMutation(CreateCodeGroupMutation);
  const { handleUploadFileCall, handleDownloadFile } = usePartnerCodeGroupForm();

  useEffect(() => {
    if (!alreadyCreatedData.codeGroupId && codeGroupData?.codeGroupId) {
      setAlreadyCreatedData(codeGroupData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeGroupData]);

  const onCloseModal = () => {
    setShowModal((prev) => ({ ...prev, setupModal: false }));
    setCodeGroupData({});
  };

  // TODO: Uncomment this function when needed
  // const resetForm = useCallback(() => {
  //   setCodeGroupData({});
  // }, [setCodeGroupData]);

  const setModalToastFn = useCallback(
    (text, type, id) => {
      setToast([
        {
          text: text,
          type: type,
          id: id,
        },
      ]);
    },
    [setToast]
  );
  const createCodeGroupPayload = useCallback(
    (lifecycleStatus = "QUEUED") => {
      return {
        administrationStatus: codeGroupData?.administrationStatus
          ? codeGroupData?.administrationStatus
          : CODE_GROUP_ADMINISTRATION_STATUS.ENABLED,
        lifecycleStatus: lifecycleStatus,
        creationType: CODE_GROUP_CREATION_TYPE.PRE_GENERATED,
        basicSetup: {
          codeGroupName: codeGroupData?.codeGroupName,
          codeActiveStartDate: codeGroupData?.codeActiveStartDate,
          codeActiveEndDate: codeGroupData?.codeActiveEndDate,
        },
        producerPackaging: {
          producerCompany: codeGroupData.codeGroupName,
          codesPrintedCount: 1,
        },
        dummyCodes: {
          allowDummyCodes: codeGroupData.allowDummyCodes,
          dummyCodeMessage: codeGroupData.dummyCodeMessage || "",
          dummyCodePercent: codeGroupData.dummyCodePercent || 0,
        },
        codeSpecifications: {
          codeLength: 10,
          usePrefix: codeGroupData.usePrefix,
          prefixVal: codeGroupData.prefixVal || null,
          useSuffix: codeGroupData.useSuffix,
          suffixVal: codeGroupData.suffixVal || null,
          caseSensitive: codeGroupData.caseSensitive,
          excludeCharacters: codeGroupData.excludeCharacters,
          excludeCharacterValues: codeGroupData.excludeCharacterValues || "",
        },
      };
    },
    [codeGroupData]
  );
  const fileUpload = useCallback(
    async (lifecycleStatus, codeGroupId) => {
      const files =
        codeGroupData?.rewardItemFileData
          ?.map((file) => file?.file)
          .filter(Boolean) || [];
      if (files.length > 0) {
        const uploadResponse = await handleUploadFileCall(files, codeGroupId);
        if (!uploadResponse?.status) {
          setModalToastFn(uploadResponse.message, "error", 3);
        } else {
          uploadResponse.data.forEach((file) => {
            const fileIndex = codeGroupData.rewardItemFileData?.findIndex(
              (f) => f.name === file.name
            );
            codeGroupData.rewardItemFileData =
              codeGroupData.rewardItemFileData.filter((f) => f.name !== file.name);
            if (fileIndex === -1) codeGroupData.rewardItemFileData.push(file);
            else codeGroupData.rewardItemFileData[fileIndex] = file;
          });
        }
      }
    },
    [codeGroupData, handleUploadFileCall, setModalToastFn]
  );
  const performPublishCodeGroup = useCallback(
    async (lifecycleStatus = "QUEUED") => {
      try {
        if (codeGroupData.codeGroupId) {
          const responseCodeGroup = await executeUpdateCodeGroup({
            variables: {
              codeGroup: createCodeGroupPayload(lifecycleStatus),
              id: codeGroupData.codeGroupId,
            },
          });
          fileUpload(lifecycleStatus, responseCodeGroup);
          return {
            isSuccess: true,
            id: codeGroupData.codeGroupId,
            message: "Successfully Updated Code group.",
          };
        }
        const responseCodeGroup = await executeCreateCodeGroup({
          variables: {
            codeGroup: createCodeGroupPayload(lifecycleStatus),
          },
        });
        fileUpload(
          lifecycleStatus,
          responseCodeGroup?.data?.createCodeGroup?.codeGroupId
        );
        return {
          isSuccess: true,
          id: codeGroupData.codeGroupId,
          message: "Successfully Created Code group.",
        };
      } catch (error) {
        return { isSuccess: false, message: error.message };
      }
    },
    [
      codeGroupData,
      fileUpload,
      createCodeGroupPayload,
      executeCreateCodeGroup,
      executeUpdateCodeGroup,
    ]
  );
  const callPerformPublishCodeGroup = useCallback(
    async (lifecycleStatus) => {
      const response = await performPublishCodeGroup(lifecycleStatus);
      if (response.isSuccess) {
        setShowModal({ showModal, setupModal: false });
        setModalToastFn(response.message, "success", 4);
        setCodeGroupData({});
        refetchListing();
      } else {
        setModalToastFn(response.message, "error", 5);
      }
    },
    [
      setShowModal,
      refetchListing,
      performPublishCodeGroup,
      setModalToastFn,
      showModal,
      setCodeGroupData,
    ]
  );

  const handleSubmitButton = useCallback(
    async (enableDraftFlow, lifecycleStatus) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const isComingFromDraft =
        lifecycleStatus === CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT;
      const updatedLifeCycleStatus = isComingFromDraft
        ? CODE_GROUP_LIFE_CYCLE_STATUS.QUEUED
        : lifecycleStatus;

      callPerformPublishCodeGroup(updatedLifeCycleStatus);
      setIsSubmitting(false);
    },
    [isSubmitting, callPerformPublishCodeGroup]
  );

  const checkIfAllFieldsAreUnchanged =
    new Date(alreadyCreatedData?.codeActiveStartDate).getTime() &&
    (new Date(codeGroupData?.codeActiveStartDate).getTime() ===
      new Date(codeGroupData.codeActiveEndDate).getTime()) ===
      new Date(alreadyCreatedData.codeActiveEndDate).getTime() &&
    codeGroupData.codeGroupName === alreadyCreatedData.codeGroupName;

  return (
    <Dialog
      className="createProduct-modal"
      open={showModal.setupModal}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2
              data-testid="create-code-group-modal"
              style={{ marginLeft: "18%", textAlign: "center" }}
            >
              {codeGroupData?.codeGroupId ? "Update" : "Create"} Code Group
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={onCloseModal}
              className="cursor-pointer"
              data-testid="closeIcon"
            />
          </Grid>
        </Grid>
        <ToastContainer
          data={modalToast}
          removeToast={() => setModalToast([])}
          showActionIcon
        />
      </DialogTitle>
      {!loadCodeGroupLoader ? (
        <DialogContent
          sx={{
            overflowX: "hidden",
          }}
        >
          <div className="my-5 create-modal-wrapper">
            <JsonFormsWrapper
              jsonschema={jsonSchemaForPtr}
              uischema={uiSchemaForPtr}
              data={codeGroupData}
              onChange={(val) => {
                setCodeGroupData(val);
              }}
            />
          </div>

          <Button
            size="small"
            variant="secondary"
            onClick={() => handleDownloadFile("text/csv", "sampleFile.csv")}
            className="download-sample-file"
          >
            Download sample file <DownloadIcon fontSize="small" />
          </Button>
        </DialogContent>
      ) : (
        <DialogContent className="display-flex align-items-center justify-content-center">
          <LoadingSpinner
            data-testid="loading-spinner"
            size={{ height: "50px", width: "50px" }}
          />
        </DialogContent>
      )}
      <DialogActions className="mt-5 display-flex justify-content-end align-items-center">
        {[
          CODE_GROUP_LIFE_CYCLE_STATUS.ACTIVE,
          CODE_GROUP_LIFE_CYCLE_STATUS.INACTIVE,
        ].includes(codeGroupData?.compositeStatus) && (
          <Toggle
            className="toggleStyles mr-3"
            label={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                ? "Active"
                : "In Active"
            }
            disabled={
              alreadyCreatedData.compositeStatus === "INACTIVE" &&
              !checkIfAllFieldsAreUnchanged
            }
            onUpdate={(val) => {
              setCodeGroupData({
                ...codeGroupData,
                administrationStatus: val
                  ? CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                  : CODE_GROUP_ADMINISTRATION_STATUS.DISABLED,
              });
            }}
            size="medium"
            checked={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
            }
          />
        )}
        <Button
          variant="primary"
          className="jufstify-content-space-around"
          onClick={() => handleSubmitButton(false, codeGroupData.lifecycleStatus)}
        >
          {codeGroupData?.codeGroupId ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
