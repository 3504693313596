import { gql } from "@apollo/client";

const updateRewardGroup = gql`
  mutation ($RewardGroupInput: RewardGroupInput!) {
    updateRewardGroup(rewardGroup: $RewardGroupInput) {
      id
      campaignId
      name
      type
      numberOfCodeScansRequiredToWin
      image
      position
      description
      maxParticipationPerInterval
      numberOfWinnersLimit
      completed
      isSpinWheelEnabled
      rewardGroupRewards {
        id
        rewardId
        rewardGroupId
        walletWithdrawalDate
        startDate
        endDate
        position
        quantity
        isExtra
        chanceToWinPercentage
        totalUserWinLimit
      }
    }
  }
`;

export default updateRewardGroup;
