import { useState } from "react";

export const useToast = () => {
  const [toast, setToast] = useState({
    open: false,
    type: "success",
    title: "",
    description: "",
  });

  const showToast = (type, title, description) => {
    setToast({ open: true, type, title, description });
  };

  const closeToast = () => {
    setToast({ ...toast, open: false });
  };

  return { toast, showToast, closeToast };
};
