import { useLazyQuery, useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { Button, Checkbox, InputItem, Pagination, ToastContainer } from "pepsico-ds";
import { useContext, useEffect, useRef, useState } from "react";
import { CampaignSetupContext } from "../../../context/CampaignSetupContext";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import {
  getCodeGroupByIds,
  getCodeGroups,
} from "../../../graphql/queries/codeGroupQueries";
import CodeGroupsTable from "./CodeGroupsTable";
import "./codeGroupsSelector.scss";

const isCodeGroupEndingEarly = (codeActiveEndDate, campaignActiveEndDate) => {
  if (campaignActiveEndDate) {
    return new Date(codeActiveEndDate) < new Date(campaignActiveEndDate);
  }
  return false;
};

const CodeGroupSelectorComponent = ({ data, path, handleChange, uischema }) => {
  const options = uischema.options || {};
  const initialData = useRef(data);
  const { programTimezone } = useContext(GlobalConfigContext);
  const { campaignState } = useContext(CampaignSetupContext);
  const [filter, setFilter] = useState({ searchTerm: "", selectAll: false });
  const [selectedCodeGroupList, setSelectedCodeGroupList] = useState([]);
  const [codeGroups, setCodeGroups] = useState([]);
  const [toastData, setToastData] = useState([]);
  let initialFilterData = {
    lifecycleStatus: "ACTIVE",
    limit: 1000,
    ...(campaignState?.activeEndDate
      ? { minimumEndDate: campaignState.activeEndDate }
      : {}),
    ...options.initialFilter,
  };
  const { data: apiList, loading } = useQuery(getCodeGroups, {
    variables: {
      filter: initialFilterData,
    },
    fetchPolicy: "cache-and-network",
  });
  const [getCodeGroupBasedOnIds] = useLazyQuery(getCodeGroupByIds);

  const [pagination, setPagination] = useState({
    recordsPerPage: 5,
    currentPage: 1,
  });

  useEffect(() => {
    const hasInactiveStatus = selectedCodeGroupList.some(
      (codeGroup) => codeGroup.compositeStatus === "INACTIVE"
    );

    if (hasInactiveStatus) {
      setToastData([
        {
          type: "warning",
          id: "Inactive Code Groups",
          text: "Some selected code groups are inactive.",
        },
      ]);
    }
  }, [selectedCodeGroupList]);

  useEffect(() => {
    const mapRuleDataToState = async () => {
      const formattedSelectedGroups = [];
      if (initialData?.current?.length && apiList?.listCodeGroups?.length) {
        if (initialData?.current?.length) {
          const response = await getCodeGroupBasedOnIds({
            variables: {
              ids: initialData?.current,
            },
          });
          const codeGroupsEndingEarly = [];
          if (response?.data?.loadCodeGroupsByIds?.length) {
            response?.data?.loadCodeGroupsByIds.forEach((codeGroup) => {
              if (codeGroup) {
                // function to check if any selected codegroup is ending early.
                if (
                  isCodeGroupEndingEarly(
                    codeGroup?.basicSetup?.codeActiveEndDate,
                    campaignState.activeEndDate
                  )
                ) {
                  codeGroupsEndingEarly.push(codeGroup.basicSetup.codeGroupName);
                }
                formattedSelectedGroups.push(codeGroup);
              }
            });
            if (codeGroupsEndingEarly.length) {
              setToastData([
                {
                  type: "warning",
                  id: "Code Groups Ending Early",
                  text: `The following code groups will be ending early: ${codeGroupsEndingEarly.join(", ")}`,
                },
              ]);
            }
          }
        }
        setSelectedCodeGroupList(formattedSelectedGroups);
      }
    };

    mapRuleDataToState();

    if (apiList?.listCodeGroups?.length) {
      setCodeGroups(apiList?.listCodeGroups);
    }
  }, [apiList?.listCodeGroups, getCodeGroupBasedOnIds, campaignState.activeEndDate]);

  useEffect(() => {
    const mapStateToRuleData = () => {
      let updatedCodeGroupIds = [];
      if (selectedCodeGroupList.length) {
        updatedCodeGroupIds = selectedCodeGroupList.map(
          (codeGroup) => codeGroup.codeGroupId
        );
      }
      handleChange(path, updatedCodeGroupIds);
    };

    mapStateToRuleData();
  }, [handleChange, path, selectedCodeGroupList]);

  useEffect(() => {
    if (
      codeGroups?.length > 0 &&
      codeGroups.every((record) =>
        selectedCodeGroupList.some((item) => item.codeGroupId === record.codeGroupId)
      )
    ) {
      setFilter((prev) => ({ ...prev, selectAll: true }));
    } else {
      setFilter((prev) => ({ ...prev, selectAll: false }));
    }
  }, [selectedCodeGroupList, codeGroups]);

  const onFilterChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
    const allCodeGroups = JSON.parse(JSON.stringify(apiList?.listCodeGroups));
    if (value === "") {
      setCodeGroups(allCodeGroups);
    } else {
      setCodeGroups(
        allCodeGroups.filter((codeGroup) =>
          codeGroup?.basicSetup?.codeGroupName
            ?.toLowerCase()
            .includes(value.toLowerCase())
        )
      );
    }
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const onRemoveCodeGroup = (index) => {
    const newData = selectedCodeGroupList.filter((_, i) => i !== index);
    setSelectedCodeGroupList(newData);
  };
  const onSelectCodeGroup = (codeGroup) => {
    let selectedRows = selectedCodeGroupList
      ? JSON.parse(JSON.stringify(selectedCodeGroupList))
      : [];
    const index = selectedRows?.findIndex(
      (item) => item.codeGroupId === codeGroup.codeGroupId
    );
    if (index > -1) {
      selectedRows = selectedRows?.filter(
        (item) => item.codeGroupId !== codeGroup.codeGroupId
      );
    } else {
      selectedRows = [...selectedRows, codeGroup];
    }
    setSelectedCodeGroupList(selectedRows);
  };

  const handleSelectAll = (value) => {
    if (value) {
      setSelectedCodeGroupList(codeGroups || []);
    } else {
      setSelectedCodeGroupList([]);
    }
  };

  const getPaginatedCodeGroups = () => {
    const start = (pagination.currentPage - 1) * pagination.recordsPerPage;
    const end = start + pagination.recordsPerPage;
    return codeGroups.slice(start, end);
  };

  return (
    <div>
      <ToastContainer
        data={toastData}
        position="top"
        removeToast={() => setToastData([])}
        showActionIcon
      />
      <div className="display-flex">
        <div style={{ width: "75%" }}>
          <div
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "16px",
              marginBottom: "0.85rem",
              flex: 0.2,
            }}
          >
            <InputItem
              placeholder="Type a search keyword..."
              trailingIcon="search"
              size="medium"
              value={filter.searchTerm}
              style={{ width: "50%" }}
              className="input-search"
              onChange={(e) => onFilterChange("searchTerm", e.target.value)}
            />
            <Checkbox
              id="select-all"
              text="Select All"
              checked={filter.selectAll}
              onUpdate={handleSelectAll}
            />
          </div>
          {!loading ? (
            <div>
              <CodeGroupsTable
                codeGroupsData={getPaginatedCodeGroups()}
                selectedCodeGroupsList={selectedCodeGroupList}
                loading={loading}
                programTimezone={programTimezone}
                onSelectCodeGroup={(newCodeGroup) => onSelectCodeGroup(newCodeGroup)}
              />
              {codeGroups.length > 0 && (
                <Pagination
                  currentPage={pagination.currentPage}
                  onUpdate={(page) => {
                    setPagination((prev) => ({ ...prev, currentPage: page }));
                  }}
                  pageCount={Math.ceil(
                    codeGroups.length / pagination.recordsPerPage
                  )}
                  size="medium"
                  variant="number"
                />
              )}
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div
          style={{
            width: "25%",
            padding: "0.5rem",
            backgroundColor: "#F3F7FD",
            marginLeft: "1%",
          }}
        >
          <div className="display-flex justify-content-space-between align-items-center">
            <span style={{ fontWeight: "700", fontSize: "12px" }}>
              {selectedCodeGroupList?.length || 0} selected
            </span>
            <Button
              variant="primaryInverse"
              size="small"
              onClick={() => setSelectedCodeGroupList([])}
              style={{ padding: "0px" }}
            >
              Remove all
            </Button>
          </div>
          <div className="display-flex gap-2 mt-16" style={{ flexWrap: "wrap" }}>
            {selectedCodeGroupList?.map((codeGroup, index) => (
              <Chip
                key={index}
                id={`codeGroup-${codeGroup?.codeGroupId}`}
                className={`font-xs${new Date(codeGroup?.basicSetup?.codeActiveEndDate) < new Date(campaignState.activeEndDate) ? " code-group-ending-early" : ""}`}
                label={codeGroup?.basicSetup?.codeGroupName}
                deleteIcon={
                  <CloseIcon
                    sx={{
                      color: "#3A3A3A !important",
                    }}
                  />
                }
                sx={{
                  borderRadius: "8px",
                  fontSize: "12px",
                  backgroundColor:
                    codeGroup?.compositeStatus === "INACTIVE" ? "gray" : "#B3DACB",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor:
                      codeGroup?.compositeStatus === "INACTIVE" ? "gray" : "#B3DACB",
                  },
                }}
                onDelete={() => onRemoveCodeGroup(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CodeGroupSelectorControl = withJsonFormsControlProps(
  CodeGroupSelectorComponent
);
