export const historyWidgetColumnDataItems = [
  {
    name: "Date",
    type: "HISTORY_WIDGET",
    slug: "date",
  },
  {
    name: "Activity Type",
    slug: "activity-type",
    type: "HISTORY_WIDGET",
  },
  {
    name: "Reward Title",
    slug: "reward-title",
    type: "HISTORY_WIDGET",
  },
  {
    name: "Reward Title & Description",
    slug: "reward-title-description",
    type: "HISTORY_WIDGET",
  },
  {
    name: "Reward Status",
    slug: "reward-status",
    type: "HISTORY_WIDGET",
  },
  {
    name: "Identifier",
    slug: "metadata-identifier",
    type: "HISTORY_WIDGET",
  },
  {
    name: "Points",
    slug: "points",
    type: "HISTORY_WIDGET",
  },
  {
    name: "Entries",
    slug: "entries",
    type: "HISTORY_WIDGET",
  },
  {
    name: "Lucky Number",
    slug: "lucky-number",
    type: "HISTORY_WIDGET",
  },
];
