import PropTypes from "prop-types";
import { getDateWithFormat, prettyColumnName } from "../../../utils/commons";
import "./commonTableView.scss";

const renderCellValue = (colKey, value, columnConfigEntry, programTimezone) => {
  // If a fully custom render is defined, use that
  if (typeof columnConfigEntry?.render === "function") {
    return columnConfigEntry.render(value);
  }

  // Otherwise, check type
  const { type } = columnConfigEntry || {};

  switch (type) {
    case "statusBadge": {
      let statusClass = "default";
      if (value && typeof value === "string") {
        statusClass = value.toLowerCase().replace(/\s/g, "-");
      }
      return (
        <div className="performances-widget-status-type">
          <span className={`performances-lifecycle-status ${statusClass}`}>
            {value || "N/A"}
          </span>
        </div>
      );
    }

    case "date":
      return value ? getDateWithFormat(value, programTimezone) : "N/A";

    default:
      if (Array.isArray(value) && value.length === 0) {
        return "N/A";
      }
      return value != null ? String(value) : "N/A";
  }
};
/**
 * Transforms the content list by flattening any fields defined in the columnConfig with `flatten: true`.
 * For each such field, it extracts the first object in the array and creates new keys in the format
 * `${originalKey}_${fieldKey}`.
 */
const transformContentList = (contentList, columnConfig) => {
  return contentList.map((row) => {
    let newRow = { ...row };
    Object.keys(columnConfig).forEach((key) => {
      if (
        columnConfig[key]?.flatten &&
        Array.isArray(row[key]) &&
        row[key].length > 0
      ) {
        const flattenedItem = row[key][0]; // You can extend this to handle multiple items if needed
        columnConfig[key].fields.forEach((field) => {
          newRow[`${key}_${field.key}`] = flattenedItem[field.key];
        });
        // Optionally remove the original field if it’s no longer needed:
        // delete newRow[key];
      }
    });
    return newRow;
  });
};

/**
 * Helper to get the column configuration for a given key.
 * It checks both the top-level keys and any flattened fields.
 */
const getColumnConfigEntry = (colKey, columnConfig) => {
  if (columnConfig[colKey]) {
    return columnConfig[colKey];
  } else {
    // Check if this colKey belongs to a flattened field
    for (const key in columnConfig) {
      if (columnConfig[key]?.flatten) {
        const fieldConfig = columnConfig[key].fields.find(
          (field) => `${key}_${field.key}` === colKey
        );
        if (fieldConfig) {
          return { label: fieldConfig.label };
        }
      }
    }
  }
  return null;
};

const CommonTableView = ({
  contentList = [],
  columnConfig = {},
  programTimezone,
  excludeColumns = [],
  cellClickHandlers = {},
}) => {
  if (!contentList.length) {
    return <p>No data available</p>;
  }

  const transformedContentList = transformContentList(contentList, columnConfig);

  // Get all unique column keys from the transformed data and filter out excluded columns
  const columns = Array.from(
    new Set(transformedContentList.flatMap((row) => Object.keys(row)))
  ).filter((colKey) => !excludeColumns.includes(colKey));

  return (
    <div className="performances-container">
      <div className="performances-list-container">
        <table className="performances-list-table">
          <thead>
            <tr>
              {columns.map((colKey) => {
                const configEntry = getColumnConfigEntry(colKey, columnConfig);
                const label =
                  (configEntry && configEntry.label) || prettyColumnName(colKey);
                const customClass = configEntry?.headerClass;
                return (
                  <th
                    key={colKey}
                    className={
                      customClass ? `performances-column-${customClass}` : ""
                    }
                  >
                    {label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {transformedContentList.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((colKey) => {
                  const rawValue = row[colKey];
                  const configEntry = getColumnConfigEntry(colKey, columnConfig);
                  const cellContent = renderCellValue(
                    colKey,
                    rawValue,
                    configEntry,
                    programTimezone
                  );
                  return (
                    <td key={colKey}>
                      {cellClickHandlers[colKey] ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            cellClickHandlers[colKey](row);
                          }}
                        >
                          {cellContent}
                        </a>
                      ) : (
                        cellContent
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
CommonTableView.propTypes = {
  contentList: PropTypes.array.isRequired,
  columnConfig: PropTypes.object,
  programTimezone: PropTypes.string,
  excludeColumns: PropTypes.array,
  cellClickHandlers: PropTypes.object,
};

export default CommonTableView;
