import { Dropdown, InputItem } from "pepsico-ds";
import { useContext } from "react";
import DatePickerMaterialUI from "../../../components/common/DatePickerMaterialUI/DatePickerMaterialUI";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import "./FilterLeaderboards.scss";

const statusOptions = [
  { displayText: "Active", id: "active" },
  { displayText: "Not started", id: "inactive" },
  { displayText: "Completed", id: "completed" },
  { displayText: "Approved", id: "approved" },
];
const approvalMethod = [
  { displayText: "Manual", id: "MANUAL" },
  { displayText: "Automatic", id: "AUTO" },
];

const FilterLeaderboards = ({ filterRef, onChangeFilters, showFields }) => {
  const { programTimezone } = useContext(GlobalConfigContext);
  const handleSearchValue = (e) => {
    filterRef.current.title = e;
    // filterRef.current.title = e.target.value;
    onChangeFilters();
  };

  const handleStartDateChange = (date) => {
    filterRef.current.startsAt = date;
    onChangeFilters();
  };

  const handleEndDateChange = (date) => {
    filterRef.current.endsAt = date;
    onChangeFilters();
  };

  const handleStatus = (value) => {
    filterRef.current.statusDropdown = value;
  };

  const handleApprovalMethod = (value) => {
    filterRef.current.approvalMethod = value;
  };

  const handleClearAllFilters = () => {
    filterRef.current = {
      startsAt: "",
      endsAt: "",
      statusDropdown: [],
      approvalMethod: [],
      title: "",
    };
    onChangeFilters();
  };

  return (
    <div className="container search-filter-leaderboard-container p-0">
      {showFields.search && (
        <div className="search-wrapper">
          <InputItem
            placeholder="Type a search keyword..."
            trailingIcon="search"
            size="medium"
            className="input-search"
            onUpdate={handleSearchValue}
          />
        </div>
      )}
      <div className="filter-wrapper">
        {showFields.status && (
          <Dropdown
            childList={statusOptions}
            selection="multiple"
            selectedValue={filterRef.current.statusDropdown}
            placeholder="Status"
            size="medium"
            className="filter-dropdown dropdown-segment"
            setSelectedValue={handleStatus}
          />
        )}
        {showFields.approvalType && (
          <Dropdown
            childList={approvalMethod}
            selection="multiple"
            selectedValue={filterRef.current.approvalMethod}
            placeholder="Approval method"
            size="medium"
            className="filter-dropdown dropdown-segment approval-method"
            setSelectedValue={handleApprovalMethod}
          />
        )}
        {showFields.dates && (
          <>
            <DatePickerMaterialUI
              setDate={handleStartDateChange}
              dateValue={filterRef.current.startsAt}
              placeholder="Start date"
              timezone={programTimezone || "America/Chicago"}
            />
            <DatePickerMaterialUI
              setDate={handleEndDateChange}
              dateValue={filterRef.current.endsAt}
              placeholder="End date"
              timezone={programTimezone || "America/Chicago"}
            />
          </>
        )}
        <div
          className="clear-all-btn"
          onClick={handleClearAllFilters}
          role="button"
          tabIndex="0"
        >
          Clear All
        </div>
      </div>
    </div>
  );
};

export default FilterLeaderboards;
