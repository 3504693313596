import { useLazyQuery, useMutation } from "@apollo/client";
import { Grid } from "@mui/material";
import { debounce } from "lodash";
import {
  InputItem,
  LoadingSpinner,
  Modal,
  Pagination,
  ToastContainer,
} from "pepsico-ds";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/widget/create_new_widget_empty.svg";
import BonusGridCard from "../../components/campaign/bonusCampaign/BonusGridCard";
import BonusListView from "../../components/campaign/bonusCampaign/BonusListView";
import { getCampaignActionButtons } from "../../components/campaign/CampaignListing/CampaignActionButtons";
import ButtonGroupDynamic from "../../components/common/ButtonGroupDynamic";
import JsonFormsWrapper from "../../components/jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { CampaignSetupContext } from "../../context/CampaignSetupContext";
import { GlobalConfigContext } from "../../context/GlobalConfigContext.js";
import LoaderContext from "../../context/LoaderContext.js";
import { DELETE_CAMPAIGN } from "../../graphql/mutations/DeleteMutation";
import { loadCampaignById } from "../../graphql/queries/loadCampaignById.js";
import { useHasAccessPermission } from "../../hooks/useHasAccessPermission";
import { useListCampaigns } from "../../hooks/useListCampaigns";
import useRewardGroupsHandlerHook from "../../hooks/useRewardGroupsHandlerHook";
import { paths } from "../../routes/paths.js";
import "../campaigns/campaign.scss";

const BonusListPage = () => {
  const navigate = useNavigate();
  const { campaignsConfiguration, programTimezone } =
    useContext(GlobalConfigContext);
  const { setIsLoading } = useContext(LoaderContext);
  const { copyRewardGroupOutcomes } = useRewardGroupsHandlerHook();
  const {
    resetCampaignState,
    resetCurrentCampaignTypeConfig,
    setCurrentCampaignTypeConfig,
    toastData,
    setToastData,
    setCampaignState,
  } = useContext(CampaignSetupContext);

  const [viewType, setViewType] = useState("grid");
  const [filters, setFilters] = useState({ types: ["bonus"], title: "" });
  const [searchInput, setSearchInput] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);

  const {
    loading,
    campaigns,
    refetch,
    filterJsonSchema,
    filterBonusUiSchema,
    ptrFilterJsonSchema,
    currentPage,
    handlePageChange,
    numPages,
  } = useListCampaigns(filters);

  const [loadCampaign] = useLazyQuery(loadCampaignById);
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);
  const hasAccess = useHasAccessPermission();
  const hasCreateCampaignAccess = hasAccess("permissions.campaign");
  const programSlug = window.REACT_APP_PROGRAM;

  // Debounced filter update
  const debouncedSetFilters = useMemo(() => {
    return debounce((value, key) => {
      setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    }, 1500);
  }, []);

  useEffect(() => {
    resetCurrentCampaignTypeConfig();
    resetCampaignState();
    return () => debouncedSetFilters.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    debouncedSetFilters(value, "title");
  };

  const handleSummaryClick = (campaign) => {
    if (!campaign?.id || !campaign?.type) return;

    const currentCampaignTypeConfiguration = campaignsConfiguration?.find(
      (c) => c?.key === campaign?.type
    );

    if (currentCampaignTypeConfiguration?.value) {
      setCurrentCampaignTypeConfig({
        ...currentCampaignTypeConfiguration?.value,
        key: currentCampaignTypeConfiguration.key,
      });
      navigate(`/${paths.campaigns}/${paths.campaign_summary_view}/${campaign.id}/`);
    }
  };

  const handleEditCampaign = (campaign) => {
    if (!campaign?.id || !campaign?.type) return;

    const currentCampaignTypeConfiguration = campaignsConfiguration?.find(
      (c) => c?.key === campaign?.type
    );

    if (currentCampaignTypeConfiguration?.value) {
      setCurrentCampaignTypeConfig({
        ...currentCampaignTypeConfiguration?.value,
        key: currentCampaignTypeConfiguration.key,
      });
      navigate(
        `/${paths.campaigns}/${paths.edit}/${campaign.id}/${currentCampaignTypeConfiguration?.value?.steps[0]?.slug || paths.generic_setup}`
      );
    }
  };

  const handleOpenConfirmationModal = (campaignId) => {
    setCampaignToDelete(campaignId);
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
    setCampaignToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (!campaignToDelete) return;

    try {
      setIsLoading(true);
      const { data } = await deleteCampaign({ variables: { id: campaignToDelete } });

      if (data?.deleteCampaign) {
        setToastData([
          { id: Date.now(), text: "Bonus deleted successfully", type: "success" },
        ]);
        refetch(); // Refetch the bonus list after deletion
      } else {
        setToastData([
          { id: Date.now(), text: "Error deleting bonus", type: "error" },
        ]);
      }
    } catch (error) {
      console.error("Error deleting bonus", error);
      setToastData([
        { id: Date.now(), text: "Error deleting bonus", type: "error" },
      ]);
    } finally {
      setIsLoading(false);
      handleCloseConfirmationModal();
    }
  };

  const handleCopyClick = async (campaign) => {
    if (!campaign?.id || !campaign?.type) {
      return;
    }
    const currentCampaignTypeConfiguration = campaignsConfiguration?.find(
      (c) => c?.key === campaign?.type
    );

    if (currentCampaignTypeConfiguration?.value) {
      setIsLoading(true);
      const { data, error } = await loadCampaign({ variables: { id: campaign.id } });
      if (error) {
        setToastData([
          { id: Date.now(), text: "Error copying a campaign", type: "error" },
        ]);
        setIsLoading(false);
        return;
      }
      if (data?.loadCampaignById?.id) {
        const loaddata = JSON.parse(JSON.stringify(data?.loadCampaignById));
        delete loaddata.__typename;
        delete loaddata.approvalStatus;
        delete loaddata.archivedAt;
        delete loaddata.campaignStatus;
        delete loaddata.paused;
        delete loaddata.publishingStatus;
        loaddata.campaignContent = { ...loaddata.campaignContent, id: null };
        loaddata.id = crypto.randomUUID();
        loaddata.title = `${loaddata.title} copy`;
        if (loaddata?.campaignContent?.contentData?.title) {
          loaddata.campaignContent.contentData.title = `${loaddata?.campaignContent?.contentData?.title} copy`;
        }
        if (loaddata?.campaignContent?.contentData?.title_short) {
          loaddata.campaignContent.contentData.title_short = `${loaddata?.campaignContent?.contentData?.title_short} copy`;
        }
        if (loaddata?.campaignContent?.slug) {
          delete loaddata.campaignContent.slug;
        }
        setCurrentCampaignTypeConfig({
          ...currentCampaignTypeConfiguration?.value,
          key: currentCampaignTypeConfiguration.key,
        });
        const contentDefinitionSlug = currentCampaignTypeConfiguration?.value?.steps
          .find((step) => step.slug === "generic-setup")
          .values.find((value) => value.type === "content").definition;

        if (loaddata?.campaignContent) {
          loaddata.campaignContent = {
            ...loaddata.campaignContent,
            contentDefinitionSlug,
          };
        }
        if (programSlug === "kaz" && loaddata?.outcomes?.length) {
          const newOutcomes = await copyRewardGroupOutcomes(
            loaddata.outcomes,
            loaddata?.id,
            "copy"
          );
          loaddata.outcomes = newOutcomes;
        }
        setCampaignState(loaddata);
        setIsLoading(false);
        navigate(
          `/${paths.campaigns}/${paths.create}/${currentCampaignTypeConfiguration?.value?.steps[0]?.slug || paths.generic_setup}`
        );
      }
    }
  };

  const renderCampaigns = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <LoadingSpinner size={{ height: "2rem", width: "2rem" }} />
        </div>
      );
    }

    if (!loading && campaigns?.length === 0) {
      return (
        <div className="empty-state">
          <img src={EmptyImage} alt="No campaigns created" />
          <p>No campaigns created yet</p>
        </div>
      );
    }

    return viewType === "grid" ? (
      <Grid container spacing={2}>
        {campaigns.map(
          (campaign) =>
            campaign && (
              <Grid key={campaign.id} item xs={6} md={4} lg={3}>
                <BonusGridCard
                  campaign={campaign}
                  programTimezone={programTimezone}
                  actionButtons={getCampaignActionButtons({
                    status: campaign?.campaignStatus?.toLowerCase(),
                    campaign: campaign,
                    handleEditCampaign,
                    handleSummaryClick,
                    handleDeleteClick: handleOpenConfirmationModal,
                    hasAccess: hasCreateCampaignAccess,
                  })}
                />
              </Grid>
            )
        )}
      </Grid>
    ) : (
      <BonusListView
        campaignData={campaigns}
        programSlug={programSlug}
        handleEditCampaign={handleEditCampaign}
        handleCopyClick={handleCopyClick}
        handleSummaryClick={handleSummaryClick}
        handleDeleteClick={handleOpenConfirmationModal}
        hasAccess={hasCreateCampaignAccess}
      />
    );
  };

  return (
    <div
      className="display-flex flex-direction-column campaign-list"
      style={{
        padding: "1rem",
        gap: "1rem",
        width: "100%",
        minHeight: "100%",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          background: "rgba(255, 255, 255, 1)",
          gap: "16px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 11px)",
        }}
      >
        <div className="filter-container container">
          <div className="filter-container-search">
            <InputItem
              placeholder="Campaign Name"
              trailingIcon="search"
              size="medium"
              className="input-search"
              onChange={handleSearchChange}
              value={searchInput}
            />
          </div>
          <div className="filter-container-popover">
            <JsonFormsWrapper
              jsonschema={
                programSlug === "ptr" ? ptrFilterJsonSchema : filterJsonSchema
              }
              uischema={filterBonusUiSchema}
              data={filters}
              onChange={(newFilters) => setFilters(newFilters)}
            />
          </div>
          <ButtonGroupDynamic
            items={[
              {
                icon: "view_module",
                onClick: () => setViewType("grid"),
              },
              {
                icon: "view_list",
                onClick: () => setViewType("list"),
              },
            ]}
            size="medium"
            variant="toggle"
            selectedIndex={viewType === "grid" ? 0 : 1}
          />
        </div>
        {renderCampaigns()}
        {campaigns && campaigns?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            onUpdate={handlePageChange}
            pageCount={numPages}
            size="medium"
            variant="number"
          />
        )}
      </div>
      {toastData.length > 0 && (
        <ToastContainer
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      <Modal
        className="confirmation-modal"
        showModal={confirmationModalOpen}
        onCloseModal={handleCloseConfirmationModal}
        primaryButtonProps={{
          size: "medium",
          text: "Delete",
          variant: "primary",
          onClick: handleConfirmDelete,
        }}
        secondaryButtonProps={{
          size: "medium",
          text: "Cancel",
          variant: "secondary",
          onClick: handleCloseConfirmationModal,
        }}
      >
        <p>
          Are you sure you want to delete this campaign? This action cannot be
          undone.
        </p>
      </Modal>
    </div>
  );
};

export default BonusListPage;
