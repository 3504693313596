import { Icon, SideBar } from "pepsico-ds";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { useHasAccessPermission } from "../../hooks/useHasAccessPermission";
import { FeatureToggle, isFeatureEnabled } from "../../utils/featureToggle";
import "./sidebar.scss";

import { configMap } from "../../utils/configMap";

const CustomSideBar = () => {
  const { t } = useTranslation();
  const {
    globalState: { isPanelOpen },
    updateSidebarPanelState,
  } = useContext(GlobalConfigContext);

  const hasAccess = useHasAccessPermission();

  let navigationConfig = configMap[window.REACT_APP_PROGRAM].navigation;

  const featureFlags = {
    showPerformances: FeatureToggle.showPerformancesSideMenu,
    showBonus: FeatureToggle.showBonusSideMenu,
    showRewardsCatalog: FeatureToggle.displayRewardsCatalogs,
    showContentManagmentSideMenu: FeatureToggle.showContentManagmentSideMenu,
  };

  navigationConfig = Object.entries(navigationConfig).reduce((acc, [key, value]) => {
    // Show performances menu
    if (key === "performances" && !isFeatureEnabled(featureFlags.showPerformances)) {
      return acc; //
    }

    // Show Bonus list from Campaign menu
    if (key === "campaign_list") {
      const filteredChildren = isFeatureEnabled(featureFlags.showBonus)
        ? value.children
        : value.children?.filter((child) => child.title !== "Bonus List") || [];
      acc[key] = { ...value, children: filteredChildren };
      return acc;
    }

    if (key === "content_list") {
      const filteredChildren = isFeatureEnabled(
        featureFlags.showContentManagmentSideMenu
      )
        ? value.children
        : value.children?.filter((child) => child.id !== "content_management") || [];
      acc[key] = { ...value, children: filteredChildren };
      return acc;
    }

    // Show Rewards/Catalogs from Rewards/Partners menu
    if (
      key === "rewards_product_keywords" &&
      !isFeatureEnabled(featureFlags.showRewardsCatalog)
    ) {
      const filteredChildren =
        value.children?.filter((child) => child.title !== "Rewards/Catalogs") || [];
      acc[key] = { ...value, children: filteredChildren };
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});

  return (
    <div
      className="display-flex flex-direction-column mr-1 hideScrollBar"
      style={{
        boxShadow: "4px 0px 24px -2px rgba(0, 92, 188, 0.08)",
        maxWidth: "300px",
        overflowY: "auto",
        margin: "0",
      }}
    >
      {!isPanelOpen && (
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => updateSidebarPanelState(true)}
          alt="menu"
          className="$spacing-pl05 $spacing-pr04 $spacing-py03"
          icon="menu"
        />
      )}
      <SideBar
        className={`sidebarStyles ${isPanelOpen ? "sidebarStyles-open" : "sidebarStyles-close"}`}
        items={Object.entries(navigationConfig)
          .filter(
            ([key, value]) => hasAccess(`navigation.${key}`) && !value.hideFromNav
          )
          .map(([, value]) => {
            const sublinks = value.children?.map((child) => {
              return {
                label: (
                  <NavItem
                    key={`${JSON.stringify(child)}`}
                    title={child.i18nKey ? t(child.i18nKey) : child.title}
                    navigateTo={child.navigateTo}
                  />
                ),
              };
            });

            const title = value.i18nKey ? t(value.i18nKey) : value.title;

            return {
              label: sublinks?.length ? (
                title
              ) : (
                <NavItem
                  key={`${JSON.stringify(value)}`}
                  title={title}
                  navigateTo={value.navigateTo}
                />
              ),
              icon: value.icon,
              url: value.navigateTo,
              sublinks: sublinks?.length ? sublinks : undefined,
            };
          })}
      />
    </div>
  );
};

const NavItem = ({ title, navigateTo }) => {
  return (
    <Link
      to={navigateTo}
      style={{
        height: "100%",
        textDecoration: "none",
        whiteSpace: "nowrap",
        width: "100%",
      }}
    >
      {title}
    </Link>
  );
};
export default CustomSideBar;
