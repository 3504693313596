import { useCallback, useContext, useMemo, useState } from "react";
import { GlobalConfigContext } from "../../../../context/GlobalConfigContext";
import { parseGraphQLError } from "../../../../utils/commons";
import { useUpdatePartnerCodeGroupMutation } from "../../Hooks/useEditPartnerCodeGroupMutation";
import PartnerCodeGroupFormModal from "../PartnerCodeGroupForm/PartnerCodeGroupForm";
import { usePartnerCodeGroupForm } from "../PartnerCodeGroupForm/usePartnerCodeGroupForm";

function EditPartnerCodeGroupModel({
  isOpen,
  closeCreatePartnerCodeGroupModal,
  setToast,
  partnerCodeGroup,
  refetch,
}) {
  const [prevPartnerCodeGroupData] = useState(partnerCodeGroup);
  const [partnerCodeGroupData, setPartnerCodeGroupData] = useState(partnerCodeGroup);
  const [updatePartnerCodeGroupMutation, { loading }] =
    useUpdatePartnerCodeGroupMutation();

  const { handleDeleteFile } = usePartnerCodeGroupForm();
  const { programMarketConfig } = useContext(GlobalConfigContext);
  const isEditAllowed = useMemo(
    () => programMarketConfig?.isAssociatedPartnerEditable || false,
    [programMarketConfig?.isAssociatedPartnerEditable]
  );

  const handleUpdatePartnerCodeGroup = useCallback(() => {
    const resetForm = () => {
      setPartnerCodeGroupData(partnerCodeGroup);
      closeCreatePartnerCodeGroupModal();
    };

    const onSuccess = () => {
      setToast({
        open: true,
        type: "success",
        title: `Code Group ${partnerCodeGroupData.name} Updated!`,
        description: "You have just updated a partner Group!",
      });
      resetForm();
      refetch();
    };

    // If API call fails, delete the uploaded files
    const deleteUploadedFiles = () => {
      const prevFiles = prevPartnerCodeGroupData?.rewardItemFileData || [];
      const currentFiles = partnerCodeGroupData?.rewardItemFileData || [];

      const filesToDelete = currentFiles.filter(
        (file) => !prevFiles.some((prevFile) => prevFile.id === file.id)
      );

      filesToDelete.forEach((file) => {
        handleDeleteFile(file);
      });
    };

    const onFailure = (error) => {
      deleteUploadedFiles();
      const errorMessage = parseGraphQLError(error);
      setToast({
        open: true,
        type: "error",
        title: `Could not update Partner Code Group: ${partnerCodeGroupData.name}`,
        description: errorMessage,
      });
      resetForm();
    };

    /* eslint-disable no-unused-vars */
    const {
      id,
      programId,
      codeCount,
      exhaustedCode,
      importedDate,
      isImported,
      status,
      processingStatus,
      isAttached,
      ...dataToSubmit
    } = partnerCodeGroupData;

    updatePartnerCodeGroupMutation(
      onSuccess,
      onFailure,
      dataToSubmit,
      id,
      isEditAllowed
    );
  }, [
    closeCreatePartnerCodeGroupModal,
    partnerCodeGroup,
    partnerCodeGroupData,
    refetch,
    setToast,
    updatePartnerCodeGroupMutation,
    handleDeleteFile,
    prevPartnerCodeGroupData?.rewardItemFileData,
    isEditAllowed,
  ]);

  return (
    <PartnerCodeGroupFormModal
      isOpen={isOpen}
      toggleCreatePartnerCodeModal={closeCreatePartnerCodeGroupModal}
      closeModal={closeCreatePartnerCodeGroupModal}
      partnerCodeGroupData={partnerCodeGroupData}
      setPartnerCodeGroupData={setPartnerCodeGroupData}
      handleMutation={handleUpdatePartnerCodeGroup}
      loading={loading}
      title="Edit"
      isEditMode={true}
      showToggle={true}
      readonly={partnerCodeGroup.status === "ACTIVE"}
    />
  );
}

export default EditPartnerCodeGroupModel;
