// filterJsonSchema.js

import {
  PERFORMANCES_ACTIVITY_TYPES,
  PERFORMANCES_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
export const performancesFilterJsonSchema = {
  type: "object",
  properties: {
    engagementStatus: {
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    engagementType: {
      items: {
        type: "string",
        options: PERFORMANCES_ACTIVITY_TYPES.map((activityType) => ({
          id: activityType.key,
          displayText: activityType.value,
        })),
      },
    },
  },
};

export const performancesFilterUiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/engagementType",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Activity Type",
      label: "Activity Type",
    },
    {
      type: "Control",
      scope: "#/properties/engagementStatus",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Status",
      label: "Status",
    },
  ],
};

export const engagementFilterSchemas = {
  "package-scan": {
    jsonSchema: {
      type: "object",
      properties: {
        engagementStatus: {
          items: {
            type: "string",
            options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
              id: status,
              displayText: status,
            })),
          },
        },
      },
    },
    uiSchema: {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/engagementStatus",
          component: "multiSelect",
          selection: "multiple",
          placeholder: "Status",
          label: "Status",
        },
      ],
    },
  },
};
export const receiptScanPerformancesFilterJsonSchema = {
  type: "object",
  properties: {
    ids: {
      type: "string",
    },
    engagementStatus: {
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    campaignIds: {
      type: "string",
    },
  },
};

export const receiptScanPerformancesFilterUiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/ids",
      component: "searchInput",
      placeholder: "Search by id",
      options: {
        debounce: true,
      },
    },
    {
      type: "Control",
      scope: "#/properties/engagementStatus",
      component: "multiSelect",
      placeholder: "Status",
      selection: "multiple",
    },
    {
      type: "Control",
      scope: "#/properties/campaignIds",
      component: "searchInput",
      placeholder: "Search by campaign name",
      options: {
        debounce: true,
      },
    },
  ],
};

export const receiptScanPerformancesFilterAllJsonSchema = {
  type: "object",
  properties: {
    ids: {
      type: "string",
      title: "Search",
    },
    profileIds: {
      type: "string",
      title: "Search",
    },
    campaignIds: {
      type: "string",
      title: "Search",
    },
    engagementDateFrom: {
      type: "string",
      format: "date",
      title: "From Date",
    },
    engagementDateTo: {
      type: "string",
      format: "date",
      title: "To Date",
    },
    engagementStatus: {
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
    },
    deviceIds: {
      type: "string",
      title: "Search",
    },
    recieptIds: {
      type: "string",
      title: "Search",
    },
    vendorName: {
      type: "string",
      title: "Search",
    },
  },
};
export const receiptScanPerformancesFilterAllUiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/ids",
      //component: "searchInput",
      placeholder: "Search by ID",
      label: "ID",
    },
    {
      type: "Control",
      scope: "#/properties/profileIds",
      //component: "searchInput",
      placeholder: "Search by User ID",
      label: "User ID",
    },
    {
      type: "Control",
      scope: "#/properties/campaignIds",
      //component: "searchInput",
      placeholder: "Search by Campaign Name",
      label: "Campaign Name",
    },
    {
      type: "Control",
      scope: "#/properties/engagementDateFrom",
      component: "datePickerMaterialUI",
      label: "From Date (Perform Date)",
    },
    {
      type: "Control",
      scope: "#/properties/engagementDateTo",
      component: "datePickerMaterialUI",
      label: "To Date (Perform Date)",
    },
    {
      type: "Control",
      scope: "#/properties/receiptIds",
      //component: "searchInput",
      placeholder: "Search by Document Id",
      label: "Document Id",
    },
    {
      type: "Control",
      scope: "#/properties/deviceIds",
      //component: "searchInput",
      placeholder: "Search by Device Id",
      label: "Device Id",
    },
    {
      type: "Control",
      scope: "#/properties/vendorName",
      //component: "searchInput",
      placeholder: "Search by Retailer",
      label: "Retailer Name",
    },
    {
      type: "Control",
      scope: "#/properties/engagementStatus",
      selection: "multiple",
      component: "multiSelect",
      placeholder: "Status",
      label: "Status",
    },
  ],
};
export const performancesFilterAllJsonSchema = {
  type: "object",
  properties: {
    ids: {
      type: "string",
      title: "Search",
    },
    profileIds: {
      type: "string",
      title: "Search",
    },
    campaignIds: {
      type: "array",
    },
    engagementDateFrom: {
      type: "string",
      format: "date",
      title: "From Date",
    },
    engagementDateTo: {
      type: "string",
      format: "date",
      title: "To Date",
    },
    engagementType: {
      items: {
        type: "string",
        options: PERFORMANCES_ACTIVITY_TYPES.map((activityType) => ({
          id: activityType.key,
          displayText: activityType.value,
        })),
      },
    },
    engagementStatus: {
      type: "array",
      items: {
        type: "string",
        options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
          id: status,
          displayText: status,
        })),
      },
      title: "Status",
    },
  },
};

export const performancesFilterAllUiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/ids",
      //component: "searchInput",
      placeholder: "Search by ID",
      label: "ID",
    },
    {
      type: "Control",
      scope: "#/properties/profileIds",
      //component: "searchInput",
      placeholder: "Search by profile ID",
      label: "Profile ID",
    },
    {
      type: "Control",
      scope: "#/properties/campaignIds",
      component: "campaignNameSelect",
      selection: "multiple",
      placeholder: "Campaign",
      label: "Campaign",
    },
    {
      type: "Control",
      scope: "#/properties/engagementDateFrom",
      component: "datePickerMaterialUI",
      label: "From Date",
    },
    {
      type: "Control",
      scope: "#/properties/engagementDateTo",
      component: "datePickerMaterialUI",
      label: "To Date",
    },
    {
      type: "Control",
      scope: "#/properties/engagementType",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Activity Type",
      label: "Activity Type",
    },
    {
      type: "Control",
      scope: "#/properties/engagementStatus",
      component: "multiSelect",
      selection: "multiple",
      placeholder: "Status",
      label: "Status",
    },
  ],
};

export const engagementAllFiltersSchemas = {
  "package-scan": {
    jsonSchema: {
      type: "object",
      properties: {
        ids: {
          type: "array",
          title: "ID",
        },
        engagementIdentifier: {
          type: "string",
          title: "External ID",
        },
        profileIds: {
          type: "array",
          title: "User ID",
        },
        productSkus: {
          type: "array",
          title: "SKU",
          items: {
            type: "string",
          },
        },
        campaignIds: {
          type: "string",
          title: "Campaign Name",
        },
        engagementDateFrom: {
          type: "string",
          title: "From Date",
        },
        engagementDateTo: {
          type: "string",
          title: "To Date",
        },
        deviceIds: {
          type: "array",
          title: "Device ID",
        },
        engagementStatus: {
          type: "array",
          items: {
            type: "string",
            options: Object.values(PERFORMANCES_LIFE_CYCLE_STATUS).map((status) => ({
              id: status,
              displayText: status,
            })),
          },
          title: "Status",
        },
      },
    },
    uiSchema: {
      type: "VerticalLayout",
      className: "performance-all-filters",
      elements: [
        {
          type: "Control",
          scope: "#/properties/ids",
        },
        {
          type: "Control",
          scope: "#/properties/engagementIdentifier",
        },
        {
          type: "Control",
          scope: "#/properties/profileIds",
        },
        {
          type: "Control",
          scope: "#/properties/productSkus",
          component: "productKeywordSelect",
        },
        {
          type: "Control",
          scope: "#/properties/campaignIds",
          component: "campaignDropdownSelect",
        },
        {
          type: "Control",
          scope: "#/properties/engagementDateFrom",
          component: "datePickerMaterialUI",
        },
        {
          type: "Control",
          scope: "#/properties/engagementDateTo",
          component: "datePickerMaterialUI",
          minimum: "fromDate",
        },
        {
          type: "Control",
          scope: "#/properties/deviceIds",
        },
        {
          type: "Control",
          scope: "#/properties/engagementStatus",
          component: "multiSelect",
          selection: "multiple",
          placeholder: "Status",
          label: "Status",
        },
      ],
    },
  },
};
