export const getUpdatedCampaignConfiguration = (
  campaignsConfiguration,
  campaignGroups
) => {
  let temporaryCampaignsConfig = JSON.parse(JSON.stringify(campaignsConfiguration));
  const updatedConfigs = [];

  if (campaignGroups?.length && temporaryCampaignsConfig.length) {
    campaignGroups.forEach((group) => {
      const updatedGroup = {
        key: group.key,
        image: group.imageURL,
        name: group.name,
        campaigns: [],
        value: null,
      };

      if (group.campaigns?.length) {
        group.campaigns.forEach((campaignKey) => {
          const campaign = temporaryCampaignsConfig.find(
            (config) => config.key === campaignKey
          );
          if (campaign) {
            updatedGroup.campaigns.push(campaign);
            temporaryCampaignsConfig = temporaryCampaignsConfig.filter(
              (config) => config.key !== campaignKey
            );
          }
        });
      }

      updatedConfigs.push(updatedGroup);
    });
  }

  return temporaryCampaignsConfig.concat(updatedConfigs);
};

export const getCampaignKeyToTypesMap = (campaignConfiguration) => {
  const campaignKeyToTypesMap = {};
  campaignConfiguration.forEach((campaign) => {
    if (campaign.campaigns && campaign.campaigns.length > 0) {
      campaignKeyToTypesMap[campaign.key] = campaign.campaigns.map((c) => c.key);
      return;
    }
    campaignKeyToTypesMap[campaign.key] = [campaign.key];
  });
  return campaignKeyToTypesMap;
};

export const mapCampaignActivities = (activities, params) =>
  activities.map((activity) => {
    const {
      activityDefinition,
      activityRules,
      activityData,
      outcomes,
      id,
      name,
      description,
    } = activity;

    const RULE_SLUGS = {
      MATCH_PRODUCTS_KEYWORDS: "match-package-products-keywords",
      MATCH_PRODUCTS_KEYWORD_POINTS:
        "match-package-products-keyword-points-calculator",
    };

    const IGNORED_RULE_SLUGS = [RULE_SLUGS.MATCH_PRODUCTS_KEYWORDS];
    const CUSTOM_HANDLED_RULE_SLUGS = [RULE_SLUGS.MATCH_PRODUCTS_KEYWORD_POINTS];

    const ruleStrategies = {
      [RULE_SLUGS.MATCH_PRODUCTS_KEYWORD_POINTS]: (rules) => {
        const keywordIds = [...new Set(rules.flatMap((rule) => rule.ruleData.ids))];
        activityPayload.activityRules.push({
          ruleDefinitionSlug: RULE_SLUGS.MATCH_PRODUCTS_KEYWORDS,
          ruleData: { ids: keywordIds },
        });
      },
    };

    const mapActivityRules = (rules) =>
      rules
        .filter((rule) => !IGNORED_RULE_SLUGS.includes(rule.ruleDefinition.slug))
        .map(({ ruleDefinition, ruleData }) => ({
          ruleDefinitionSlug: ruleDefinition.slug,
          ruleData,
        }));

    const mapOutcomes = (outcomes) =>
      outcomes.map(({ outcomeDefinition, outcomeData, outcomeRules }) => ({
        outcomeDefinitionSlug: outcomeDefinition?.slug,
        outcomeData: params.campaignId
          ? outcomeData
          : {
              ...outcomeData,
              target_id: outcomeData.target_id ?? null,
            },
        outcomeRules: outcomeRules?.map(({ ruleDefinition, ruleData }) => ({
          ruleDefinitionSlug: ruleDefinition?.slug,
          ruleData,
        })),
      }));

    const activityPayload = {
      activityDefinitionSlug: activityDefinition.slug,
      name,
      description,
      activityRules: mapActivityRules(activityRules),
      activityData: activityData || {},
      outcomes: mapOutcomes(outcomes),
    };

    CUSTOM_HANDLED_RULE_SLUGS.forEach((slug) => {
      const matchingRules = activityPayload.activityRules.filter(
        (rule) =>
          rule.ruleDefinitionSlug === RULE_SLUGS.MATCH_PRODUCTS_KEYWORD_POINTS
      );
      if (matchingRules.length > 0 && ruleStrategies[slug]) {
        ruleStrategies[slug](matchingRules);
      }
    });

    if (params.campaignId && id) {
      activityPayload.id = id;
    }

    return activityPayload;
  });
