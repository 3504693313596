/**
 * Custom hook to handle reward groups.
 *
 * @returns {Object} RewardGroupObject An object containing the following properties:
 * @returns {Function} RewardGroupObject.validateDirectRewardsData - Validates the direct rewards data.
 * @returns {Function} RewardGroupObject.createRewardGroup - Creates a reward group.
 */

import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext } from "react";
import LoaderContext from "../context/LoaderContext";
import createRewardGroup from "../graphql/mutations/createRewardGroup";
import updateRewardGroup from "../graphql/mutations/updateRewardGroup";
import { listRewardGroups } from "../graphql/queries/listRewardGroups";

/**
 * Validates the direct rewards data.
 *
 * @param {Object} rewardData - The reward data to validate.
 * @param {number} rewardData.quantity - The quantity of the reward.
 * @param {string} rewardData.rewardId - The ID of the reward.
 * @returns {Object} An object containing the validation result.
 * @returns {boolean} result.isValid - Indicates if the reward data is valid.
 * @returns {string} result.errMsg - The error message if the reward data is invalid.
 */
const useRewardGroupsHandlerHook = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const [listRewardGroupCall] = useLazyQuery(listRewardGroups);
  const [createRewardGroupCall] = useMutation(createRewardGroup);
  const [updateRewardGroupCall] = useMutation(updateRewardGroup);

  const validateDirectRewardsData = (rewardData) => {
    const result = { isValid: true, errMsg: "" };
    if (!rewardData?.quantity) {
      result.isValid = false;
      result.errMsg = "Quantity is required.";
    }
    if (!rewardData?.rewardId) {
      result.isValid = false;
      result.errMsg = "Please select a reward.";
    }
    return result;
  };

  /**
   * Creates a reward group.
   */
  const createDirectRewardGroup = async (
    rewardData,
    campaignId,
    campaignTitle = "Campaign"
  ) => {
    const payload = {
      campaignId: campaignId,
      type: "LIST",
      completed: true,
      name: `Reward Group of ${campaignTitle}`,
      description: `Description of Reward Group of ${campaignTitle}`,
      position: 1,
      maxParticipationPerInterval: 100,
      image: null,
      numberOfWinnersLimit: 100000,
      isSpinWheelEnabled: false,
      rewardGroupRewards: rewardData?.rewardGroupRewards?.map((reward) => ({
        rewardId: reward?.rewardId,
        quantity: reward?.quantity,
        totalUserWinLimit: 100000,
        chanceToWinPercentage: 0,
        startDate: "",
        endDate: "",
        walletWithdrawalDate: reward?.walletWithdrawalDate ?? "",
        position: 1,
        isExtra: false,
      })),
    };
    if (rewardData.reward_group_id) {
      payload.id = rewardData.reward_group_id;
    }
    try {
      setIsLoading(true);
      let resp = null;
      if (payload.id) {
        const updateResp = await updateRewardGroupCall({
          variables: { RewardGroupInput: payload },
        });
        resp = updateResp?.data?.updateRewardGroup;
      } else {
        const createResp = await createRewardGroupCall({
          variables: { RewardGroupInput: payload },
        });
        resp = createResp?.data?.createRewardGroup;
      }
      setIsLoading(false);
      if (resp?.id) {
        return {
          success: true,
          data: {
            reward_group_reward_id: resp?.rewardGroupRewards?.[0]?.id,
            reward_group_id: resp?.id,
          },
        };
      }
      return {
        success: false,
        errMsg: "Unable to save selected reward. Please try again in sometime",
      };
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return {
        success: false,
        errMsg: "Unable to save selected reward. Please try again in sometime",
      };
    }
  };

  const createCopyOfRewardGroup = async (rewardGroup, campaignId) => {
    const payload = {
      campaignId,
      type: rewardGroup?.type,
      completed: rewardGroup?.completed,
      description: rewardGroup?.description,
      image: rewardGroup?.image,
      isSpinWheelEnabled: rewardGroup?.isSpinWheelEnabled,
      maxParticipationPerInterval: rewardGroup?.maxParticipationPerInterval,
      name: rewardGroup?.name,
      numberOfCodeScansRequiredToWin: rewardGroup?.numberOfCodeScansRequiredToWin,
      numberOfWinnersLimit: rewardGroup?.numberOfWinnersLimit,
      position: rewardGroup?.position,
      rewardGroupRewards: rewardGroup?.rewardGroupRewards?.map((reward) => ({
        chanceToWinPercentage: reward?.chanceToWinPercentage,
        endDate: reward?.endDate,
        isExtra: reward?.isExtra,
        position: reward?.position,
        quantity: reward?.quantity,
        rewardId: reward?.rewardId,
        startDate: reward?.startDate,
        totalUserWinLimit: reward?.totalUserWinLimit,
        walletWithdrawalDate: null,
      })),
    };
    const createResp = await createRewardGroupCall({
      variables: { RewardGroupInput: payload },
    });
    return createResp?.data?.createRewardGroup;
  };

  const copyRewardGroupOutcomes = async (
    outcomes = [],
    campaignId,
    type = "copy"
  ) => {
    try {
      if (outcomes?.[0]?.outcomeDefinition?.slug === "direct-rewards") {
        const rewardGroupId = outcomes?.[0]?.outcomeData?.reward_group_id;
        const { data } = await listRewardGroupCall({
          variables: { rewardGroupId },
        });
        if (type === "copy") {
          if (data.listRewardGroups?.[0]?.id) {
            const rewardGroupResult = await createCopyOfRewardGroup(
              data.listRewardGroups?.[0],
              campaignId
            );
            if (rewardGroupResult?.id) {
              return [
                {
                  ...outcomes?.[0],
                  outcomeData: {
                    reward_group_id: rewardGroupResult?.id,
                    reward_group_reward_id:
                      rewardGroupResult?.rewardGroupRewards?.[0]?.id,
                  },
                },
              ];
            }
            return [{ ...outcomes?.[0], outcomeData: {} }];
          }
          return [{ ...outcomes?.[0], outcomeData: {} }];
        }
        return outcomes;
      } else {
        const allRewardGroupsResult = JSON.parse(JSON.stringify(outcomes));
        const rewardGroupIds = outcomes?.[0]?.outcomeData?.reward_group_ids || [];
        const oldNewIdMap = {};
        const idsMap = {};
        const newRewardGroupIds = await Promise.all(
          rewardGroupIds.map(async (rewardGroupId) => {
            const { data } = await listRewardGroupCall({
              variables: { rewardGroupId },
            });
            if (type === "edit") {
              const rewardGroupResult = data.listRewardGroups?.[0];
              oldNewIdMap[rewardGroupId] = {
                numberOfCodeScansRequiredToWin:
                  rewardGroupResult?.numberOfCodeScansRequiredToWin,
                oldId: rewardGroupId,
              };
              return rewardGroupId;
            }
            if (data.listRewardGroups?.[0]?.id && type === "copy") {
              const rewardGroupResult = await createCopyOfRewardGroup(
                data.listRewardGroups?.[0],
                campaignId
              );
              if (rewardGroupResult?.id) {
                oldNewIdMap[rewardGroupResult?.id] = {
                  numberOfCodeScansRequiredToWin:
                    rewardGroupResult?.numberOfCodeScansRequiredToWin,
                  oldId: rewardGroupId,
                };
                idsMap[rewardGroupId] = rewardGroupResult?.id;
                return rewardGroupResult.id;
              }
            }
            return null;
          })
        );
        if (allRewardGroupsResult?.length) {
          allRewardGroupsResult[0].outcomeData.participation_by_activity_by_reward_group =
            newRewardGroupIds.map((id) => ({
              id,
              value: oldNewIdMap[id]?.numberOfCodeScansRequiredToWin,
            }));

          allRewardGroupsResult[0].outcomeData.reward_group_ids = newRewardGroupIds;
          if (
            allRewardGroupsResult[0]?.outcomeRules?.length &&
            allRewardGroupsResult[0]?.outcomeRules?.[0]?.ruleData &&
            type === "copy"
          ) {
            const outcomeRule = allRewardGroupsResult[0]?.outcomeRules[0];
            const boosted_reward_group_ids =
              outcomeRule?.ruleData?.boosted_reward_group_ids?.map(
                (id) => idsMap[id]
              );
            const default_reward_group_ids =
              outcomeRule?.ruleData?.default_reward_group_ids?.map(
                (id) => idsMap[id]
              );

            outcomeRule.ruleData.boosted_reward_group_ids = boosted_reward_group_ids;
            outcomeRule.ruleData.default_reward_group_ids = default_reward_group_ids;
          }
        }
        return allRewardGroupsResult;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  return {
    validateDirectRewardsData,
    createDirectRewardGroup,
    copyRewardGroupOutcomes,
  };
};

export default useRewardGroupsHandlerHook;
