import { Card, Divider, Icon, Tag } from "pepsico-ds";
import { useState } from "react";
import PerformanceDetailCard from "./PerformanceDetailCard";
import "./performanceDetails.scss";

const PerformanceDetailsSection = ({ title, status, details = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card border>
      <div className="performance-details-section-card-header">
        <h5 style={{ fontWeight: 500, fontSize: 14 }}>{title}</h5>
        <div className="performance-details-section-card-header-right">
          {status && !isExpanded && (
            <Tag
              text={status}
              isCopyable={false}
              className="performance-details-status-tag"
            />
          )}
          <Icon
            icon={isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            variant="filled"
            onClick={toggleExpand}
          />
        </div>
      </div>
      {isExpanded && <Divider />}
      {isExpanded && (
        <div>
          {details && details.length > 0 && (
            <div className="performance-details-container">
              {details.map((item) => (
                <PerformanceDetailCard
                  key={item.key}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default PerformanceDetailsSection;
